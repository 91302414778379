import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Confirm from '_pages/Event/Confirm'
import EventCreate from '_pages/Event/Create'
import EventCreateItem from '_pages/Event/CreateItem'
import DeliveryDetail from '_pages/Event/DeliveryDetail'
import EventDetail from '_pages/Event/EventDetail'
import ManageDelivery from '_pages/Event/ManageDelivery'
import ManageOrder from '_pages/Event/ManageOrder'
import PurchaseDetail from '_pages/Event/PurchaseDetail'
import SwitchMobileModel from '../components/SwitchMobileModel'
import Delivery from './DeliveryList'
import HostedEvents from './HostedEvents'

function Event() {
  const { path } = useRouteMatch()
  return (
    <>
      <div className='size-width-desktop-col-1'>
        <Switch>
          <Route path={`${path}/create`} component={EventCreate} />
          <Route path={`${path}/update`} component={EventCreate} />
          <Route path={`${path}/create-items`} component={EventCreateItem} />
          <Route path={`${path}/update-items`} component={EventCreateItem} />
          <Route path={`${path}/update-confirm`} component={Confirm} />
          <Route path={`${path}/created-confirm`} component={Confirm} />
          <Route path={`${path}/delivery-detail`} component={DeliveryDetail} />
          <Route path={`${path}/detail`} component={EventDetail} />
          <Route path={`${path}/purchase-detail`} component={PurchaseDetail} />
          <Route path={`${path}/manage-orders`} component={ManageOrder} />
          <Route path={`${path}/manage-delivery`} component={ManageDelivery} />
          <Route path={`${path}/detail-shared`} component={() => <SwitchMobileModel />} />
        </Switch>
      </div>
      <Switch>
        <Route
          path={`${path}/list-hosted-by-group`}
          component={() => (
            <div className='size-width-desktop-col-2'>
              <HostedEvents />
            </div>
          )}
        />
        <Route
          path={`${path}/delivery-list`}
          component={() => (
            <div className='size-width-desktop-col-2'>
              <Delivery />
            </div>
          )}
        />
      </Switch>
    </>
  )
}

export default Event
