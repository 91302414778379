/* eslint-disable no-unused-vars */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import React, { useState, useEffect, useContext } from 'react'
import ModalPaymentService from '_components/ModalPaymentService'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import uploadApi from '_api/upload'
import serviceApi from '_api/service'
import userApi from '_api/user'
import { getUserInfo, setUserInfo, setDeviceUser, getDeviceUser } from '_utils/localData'
import {
  PAYMENT_STATUS_CANCEL_BY_USER,
  PAYMENT_STATUS_CANCEL_BY_COBEE,
  PAYMENT_STATUS_UNPAID,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_VERIFY,
  STATUS_NOT_USE,
  STATUS_PENDING,
  STATUS_PAID,
  PAYMENT_SERVICE_SCHEDULE_REF,
  PHONE,
  DESKTOP,
} from '_utils/constant'
import { DeviceUserContext } from '../../../context/DeviceUserContext'
import Pagination from '../components/Pagination'

const FilterOption = ({ filterServiceType, setFilterServiceType }) => {
  // const serviceType = ['Subdomain', 'Schedule Event', 'Premium Account']

  const serviceType = ['Subdomain', 'Premium Account']

  return (
    <div className='select-filter'>
      <select
        defaultValue={filterServiceType}
        className='form-select-sm border shadow-sm rounded pe-1'
        onChange={(e) => setFilterServiceType(e.target.value)}
      >
        <option disabled value='' hidden>
          Filter by service type
        </option>
        <option value=''>All</option>
        {serviceType.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  )
}

const ModalCancelConfirm = ({ modalCancel, toggleModalCancel, handleCancel }) => (
  <Modal isOpen={modalCancel} toggle={toggleModalCancel} className='modal-cancel' centered>
    <ModalHeader toggle={toggleModalCancel} className='pt-1 pb-1 text-left'>
      Cancel this service
    </ModalHeader>
    <ModalBody>Do you want to cancel this service ?</ModalBody>
    <ModalFooter>
      <button type='button' className='btn-no btn-secondary' onClick={toggleModalCancel}>
        No
      </button>
      <button type='button' className='btn-yes btn-primary' onClick={handleCancel}>
        Yes
      </button>
    </ModalFooter>
  </Modal>
)

export default function Invoices({
  isUpdateListPaymentOfUser,
  setIsCheckPremiumFeature,
  setRegisterPremiumAccount,
}) {
  const userInfo = getUserInfo() || null
  const { setCheckDeviceUser } = useContext(DeviceUserContext)
  const { addToast } = useToasts()

  const [userInvoice, setUserInvoice] = useState(null)

  useEffect(() => {
    if (userInfo) {
      let { domainFeatures = [], premiumFeatures = [] } = userInfo
      if (!!domainFeatures.length || !!premiumFeatures.length) {
        // scheduleFeatures = scheduleFeatures.map((item) => ({
        //   ...item,
        //   serviceType: 'Schedule Event',
        //   price: item.schedulePrice,
        //   paymentStatus: item.schedulePaymentStatus,
        //   status: item.scheduleStatus,
        //   paymentScreenshot: item.schedulePaymentScreenshot,
        // }))
        premiumFeatures = premiumFeatures.map((item) => ({
          ...item,
          serviceType: 'Premium',
          price: item.premiumPrice,
          paymentStatus: item.premiumPaymentStatus,
          status: item.premiumStatus,
          paymentScreenshot: item.premiumPaymentScreenshot,
        }))
         domainFeatures = domainFeatures.map((item) => ({
          ...item,
          serviceType: 'Subdomain',
          price: item.domainPrice,
          paymentStatus: item.domainPaymentStatus,
          status: item.domainStatus,
          paymentScreenshot: item.domainPaymentScreenshot,
        }))
        const mergeListInvoice = domainFeatures.concat(premiumFeatures)
        const sortInvoiceUser = mergeListInvoice?.sort((a, b) =>
          a.status < b.status ? 1 : -1
        )
        setUserInvoice(sortInvoiceUser)
      } else {
        setUserInvoice(null)
      }
    }
  }, [isUpdateListPaymentOfUser])

  const [filterServiceType, setFilterServiceType] = useState('')
  const [isShowModal, setIsShowModal] = useState(false)

  const [modalCancel, setModalCancel] = useState(false)

  // const [paymentFileUpload, setPaymentFileUpload] = useState('')
  // const [paymentFileUrl, setPaymentFileUrl] = useState('')
  // const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)

  // const [dataModal, setDataModal] = useState(null)

  const [page, setPage] = useState({
    currentPage: 1,
    invoicesPerPage: 10,
  })

  const { currentPage, invoicesPerPage } = page

  // Logic for displaying invoices
  const indexOfLastInvoice = currentPage * invoicesPerPage
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage

  // Logic for displaying page numbers
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(userInvoice?.length / invoicesPerPage); i++) {
    pageNumbers.push(i)
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setPage({
        ...page,
        currentPage: currentPage - 1,
      })
    }
  }

  const handleNext = () => {
    if (currentPage < pageNumbers.length) {
      setPage({
        ...page,
        currentPage: currentPage + 1,
      })
    }
  }

  const currentInvoices = userInvoice?.slice(indexOfFirstInvoice, indexOfLastInvoice)

  if (
    pageNumbers.length === 1 &&
    currentInvoices.length === 0 &&
    currentPage > pageNumbers.length
  ) {
    setPage({
      ...page,
      currentPage: 1,
    })
  }

  const toggle = () => {
    setIsShowModal(!isShowModal)
  }

  const toggleModalCancel = () => {
    setModalCancel(!modalCancel)
  }

  useEffect(() => {
    if (filterServiceType === 'Subdomain') {
      if (getUserInfo()) {
        let { domainFeatures = [] } = getUserInfo()
        if (domainFeatures.length) {
          domainFeatures = domainFeatures.map((item) => ({
            ...item,
            serviceType: 'Subdomain',
            price: item.domainPrice,
            paymentStatus: item.domainPaymentStatus,
            status: item.domainStatus,
            paymentScreenshot: item.domainPaymentScreenshot,
          }))
          setUserInvoice(domainFeatures?.sort((a, b) => (a.status < b.status ? 1 : -1)))
        } else {
          setUserInvoice(null)
        }
      }
    }
    if (filterServiceType === 'Premium Account') {
      if (getUserInfo()) {
        let { premiumFeatures = [] } = getUserInfo()
        if (premiumFeatures.length) {
          premiumFeatures = premiumFeatures.map((item) => ({
            ...item,
            serviceType: 'Premium',
            price: item.premiumPrice,
            paymentStatus: item.premiumPaymentStatus,
            status: item.premiumStatus,
            paymentScreenshot: item.premiumPaymentScreenshot,
          }))
          setUserInvoice(premiumFeatures?.sort((a, b) => (a.status < b.status ? 1 : -1)))
        } else {
          setUserInvoice(null)
        }
      }
    }
    if (filterServiceType === '') {
      // eslint-disable-next-line no-lonely-if
      if (getUserInfo()) {
        let { domainFeatures = [], premiumFeatures = [] } = getUserInfo()
        if (domainFeatures.length || premiumFeatures.length) {
          // scheduleFeatures = scheduleFeatures.map((item) => ({
          //   ...item,
          //   serviceType: 'Schedule Event',
          //   price: item.schedulePrice,
          //   paymentStatus: item.schedulePaymentStatus,
          //   status: item.scheduleStatus,
          //   paymentScreenshot: item.schedulePaymentScreenshot,
          // }))
          domainFeatures = domainFeatures.map((item) => ({
            ...item,
            serviceType: 'Subdomain',
            price: item.domainPrice,
            paymentStatus: item.domainPaymentStatus,
            status: item.domainStatus,
            paymentScreenshot: item.domainPaymentScreenshot,
          }))
          premiumFeatures = premiumFeatures.map((item) => ({
            ...item,
            serviceType: 'Premium',
            price: item.premiumPrice,
            paymentStatus: item.premiumPaymentStatus,
            status: item.premiumStatus,
            paymentScreenshot: item.premiumPaymentScreenshot,
          }))
          const mergeListInvoice = domainFeatures.concat(premiumFeatures)
          const sortInvoiceUser = mergeListInvoice?.sort((a, b) =>
            a.status < b.status ? 1 : -1
          )
          setUserInvoice(sortInvoiceUser)
        } else {
          setUserInvoice(null)
        }
      }
    }
  }, [filterServiceType])

  // const onUserImageUploaded = (_event) => {
  //   const { files } = _event.target
  //   if (files) {
  //     const img = files[0]
  //     setPaymentFileUrl(URL.createObjectURL(img))
  //     setPaymentFileUpload(files)
  //   }
  // }

  // const confirmPayment = async (e) => {
  //   e.preventDefault()
  //   setIsSubmittedPayment(true)
  //   let url = paymentFileUrl
  //   if (paymentFileUpload) {
  //     const { msgResp: res } = await uploadApi.uploadPhoto(paymentFileUpload)
  //     // eslint-disable-next-line prefer-destructuring
  //     url = res.url
  //   }
  //   if (url) {
  //     try {
  //       if (dataModal && dataModal.serviceType === 'Premium') {
  //         await serviceApi.updatePaymentScheduleEvent(dataModal.id, {
  //           schedulePaymentScreenshot: url,
  //           schedulePaymentStatus: PAYMENT_STATUS_PAID,
  //         })
  //       } else {
  //         await serviceApi.updatePaymentSubdomain(dataModal.id, {
  //           domainPaymentScreenshot: url,
  //           domainPaymentStatus: PAYMENT_STATUS_PAID
  //         })
  //       }

  //       userApi
  //         .getUser()
  //         .then(({ msgResp }) => {
  //           let { domainFeatures = [], premiumFeatures = [] } = msgResp
  //           // scheduleFeatures = scheduleFeatures.map((item) => ({
  //           //   ...item,
  //           //   serviceType: 'Schedule Event',
  //           //   price: item.schedulePrice,
  //           //   paymentStatus: item.schedulePaymentStatus,
  //           //   status: item.scheduleStatus,
  //           //   paymentScreenshot: item.schedulePaymentScreenshot,
  //           // }))
  //           domainFeatures = domainFeatures.map((item) => ({
  //             ...item,
  //             serviceType: 'Subdomain',
  //             price: item.domainPrice,
  //             paymentStatus: item.domainPaymentStatus,
  //             status: item.domainStatus,
  //             paymentScreenshot: item.domainPaymentScreenshot,
  //           }))
  //           premiumFeatures = premiumFeatures.map((item) => ({
  //             ...item,
  //             serviceType: 'Premium',
  //             price: item.premiumPrice,
  //             paymentStatus: item.premiumPaymentStatus,
  //             status: item.premiumStatus,
  //             paymentScreenshot: item.premiumPaymentScreenshot,
  //           }))
  //           const mergeListInvoice = domainFeatures.concat(premiumFeatures)
  //           const sortInvoiceUser = mergeListInvoice?.sort((a, b) =>
  //             a.status < b.status ? 1 : -1
  //           )
  //           setUserInvoice(sortInvoiceUser)
  //           setUserInfo(msgResp)
  //         })
  //         .catch(({ msgResp }) => {
  //           addToast(msgResp, { appearance: 'error', autoDismiss: true })
  //         })

  //       setIsSubmittedPayment(false)
  //       toggle()
  //     } catch (error) {
  //       setIsSubmittedPayment(false)
  //       addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
  //     }
  //   } else {
  //     setIsSubmittedPayment(false)
  //     alert('Please upload payment screenshot')
  //   }
  // }

  const mapStatus = (paymentStatus, status) => {
    let statusText = ''
    let color = ''
    if (
      (paymentStatus === PAYMENT_STATUS_CANCEL_BY_USER ||
        paymentStatus === PAYMENT_STATUS_CANCEL_BY_COBEE) &&
      status === STATUS_NOT_USE
    ) {
      statusText = 'Cancel'
    }
    if (paymentStatus === PAYMENT_STATUS_UNPAID && status === STATUS_PENDING) {
      statusText = 'Unpaid'
      color = 'rgb(255, 105, 3)'
    }
    if (paymentStatus === PAYMENT_STATUS_PAID && status === STATUS_PENDING) {
      statusText = 'Paid'
      color = '#51aaaa'
    }
    if (paymentStatus === PAYMENT_STATUS_VERIFY && status === STATUS_PAID) {
      statusText = 'Verify'
      color = '#51aaaa'
    }
    return [statusText, color]
  }

  // const showCancelAction = (paymentStatus) => {
  //   if (
  //     paymentStatus !== PAYMENT_STATUS_CANCEL_BY_USER &&
  //     paymentStatus !== PAYMENT_STATUS_CANCEL_BY_COBEE
  //   ) {
  //     return true
  //   }
  //   return false
  // }

  // const showPaymentAction = (paymentStatus) => {
  //   if (paymentStatus === PAYMENT_STATUS_UNPAID) {
  //     return true
  //   }
  //   return false
  // }

  // const handleCancel = async () => {
  //   let isCancelPremiumFeature
  //   try {
  //     if (dataModal && dataModal.serviceType === 'Schedule Event') {
  //       await serviceApi.updatePaymentScheduleEvent(dataModal.id, {
  //         scheduleStatus: STATUS_NOT_USE,
  //         schedulePaymentStatus: PAYMENT_STATUS_CANCEL_BY_USER,
  //       })
  //       // } else if (dataModal && dataModal.serviceType === 'Subdomain') {
  //       //   await serviceApi.updatePaymentSubdomain(dataModal.id, {
  //       //     domainStatus: STATUS_NOT_USE,
  //       //     domainPaymentStatus: PAYMENT_STATUS_CANCEL_BY_USER,
  //       //   })
  //     } else {
  //       await serviceApi.updatePaymentPremium(dataModal.id, {
  //         premiumStatus: STATUS_NOT_USE,
  //         premiumPaymentStatus: PAYMENT_STATUS_CANCEL_BY_USER,
  //       })
  //       if (getDeviceUser() === DESKTOP) {
  //         setDeviceUser(PHONE)
  //         setCheckDeviceUser(PHONE)
  //       } else {
  //         isCancelPremiumFeature = 1
  //       }
  //     }

  //     userApi
  //       .getUser()
  //       .then(({ msgResp }) => {
  //         let { domainFeatures = [], premiumFeatures = [] } = msgResp
  //         // scheduleFeatures = scheduleFeatures.map((item) => ({
  //         //   ...item,
  //         //   serviceType: 'Schedule Event',
  //         //   price: item.schedulePrice,
  //         //   paymentStatus: item.schedulePaymentStatus,
  //         //   status: item.scheduleStatus,
  //         //   paymentScreenshot: item.schedulePaymentScreenshot,
  //         // }))
  //         domainFeatures = domainFeatures.map((item) => ({
  //           ...item,
  //           serviceType: 'Subdomain',
  //           price: item.domainPrice,
  //           paymentStatus: item.domainPaymentStatus,
  //           status: item.domainStatus,
  //           paymentScreenshot: item.domainPaymentScreenshot,
  //         }))
  //         premiumFeatures = premiumFeatures.map((item) => ({
  //           ...item,
  //           serviceType: 'Premium',
  //           price: item.premiumPrice,
  //           paymentStatus: item.premiumPaymentStatus,
  //           status: item.premiumStatus,
  //           paymentScreenshot: item.premiumPaymentScreenshot,
  //         }))
  //         const mergeListInvoice = domainFeatures.concat(premiumFeatures)
  //         const sortInvoiceUser = mergeListInvoice?.sort((a, b) =>
  //           a.status < b.status ? 1 : -1
  //         )
  //         setUserInvoice(sortInvoiceUser)
  //         setUserInfo(msgResp)
  //         if (isCancelPremiumFeature) {
  //           setIsCheckPremiumFeature(false)
  //           setRegisterPremiumAccount(true)
  //         }
  //       })
  //       .catch(({ msgResp }) => {
  //         addToast(msgResp, { appearance: 'error', autoDismiss: true })
  //       })

  //     toggleModalCancel()
  //   } catch (error) {
  //     setIsSubmittedPayment(false)
  //     toggleModalCancel()
  //     addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
  //   }
  // }

  return (
    <>
      {!currentInvoices ? <div>No invoice</div> : null}
      {currentInvoices ? (
        <div className='user-invoice'>
          <div className='box-filter mb-3 justify-content-between flex-row-reverse'>
            <Pagination
              currentInvoices={currentInvoices}
              onHandlePrev={handlePrev}
              onHandleNext={handleNext}
            />
            <FilterOption
              filterServiceType={filterServiceType}
              setFilterServiceType={setFilterServiceType}
            />
          </div>
          <div className='shadow-sm overflow-hidden rounded-3'>
            <div className='invoice-header row text-center justify-content-between bg-light fw-bold'>
              <div className='col-2'>Date</div>
              <div className='col-3'>Service Type</div>
              <div className='col-2'>Price</div>
              <div className='col-2'>Status</div>
              {/* <div className='col-3'>Actions</div> */}
            </div>

            {currentInvoices
              ? currentInvoices?.map((item, index) => (
                  <div key={index} className='invoice-item justify-content-between row text-center align-items-center'>
                    <div className='col-2'>{item.createdDate}</div>
                    <div className='col-3'>{item.serviceType}</div>
                    <div className='col-2'>
                      {item?.price?.toLocaleString('en-SG', {
                        style: 'currency',
                        currency: 'SGD',
                      })}
                    </div>
                    <div
                      className='col-2'
                      style={{
                        color: `${mapStatus(item.paymentStatus, item.status)[1]}`,
                        fontWeight: 'bold',
                      }}
                    >
                      {mapStatus(item.paymentStatus, item.status)[0]}
                    </div>
                    {/* <div className='col-3'>
                      {showCancelAction(item.paymentStatus) ? (
                        <i
                          className='icon-cancel cancel'
                          data-toggle='tooltip'
                          data-placement='left'
                          title='Cancel'
                          onClick={() => {
                            toggleModalCancel()
                            setDataModal(item)
                          }}
                        />
                      ) : null}
                      {showPaymentAction(item.paymentStatus) ? (
                        <i
                          className='icon-payment payment'
                          data-toggle='tooltip'
                          data-placement='right'
                          title='Payment'
                          onClick={() => {
                            toggle()
                            setDataModal(item)
                            setPaymentFileUrl(item.paymentScreenshot)
                          }}
                        />
                      ) : null}
                    </div> */}
                  </div>
                ))
              : ''}
          </div>

          {/* {isShowModal && dataModal && (
            <ModalPaymentService
              isShowModal={isShowModal}
              toggle={toggle}
              isSubmittedPayment={isSubmittedPayment}
              service={{
                type: dataModal.serviceType,
                price: dataModal.schedulePrice || dataModal.domainPrice,
              }}
              onUserImageUploaded={onUserImageUploaded}
              confirmPayment={confirmPayment}
              paymentFileUrl={paymentFileUrl}
              refNumber={PAYMENT_SERVICE_SCHEDULE_REF}
            />
          )}
          {modalCancel && (
            <ModalCancelConfirm
              modalCancel={modalCancel}
              toggleModalCancel={toggleModalCancel}
              handleCancel={handleCancel}
            />
          )} */}
        </div>
      ) : null}
    </>
  )
}
