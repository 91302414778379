/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-escape */
/* eslint-disable quotes */
/* eslint-disable prefer-template */
/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button, Col, Container, Row, Spinner } from 'reactstrap'
import * as XLSX from 'xlsx'
import WithErrorBoundary from '_components/WithErrorBoundary'
import { getEventGroupIdByHost, getListEventAndDelivery } from '_redux/modules/event'
import {
  BUYER_CANCELED,
  BUYER_COLLECTED,
  BUYER_ORDER_CANCELED,
  BUYER_PAID,
  BUYER_REFUNDED,
  BUYER_UNPAID,
  HOST_ORDER_CANCELED
} from '_utils/constant'
import {
  cartesian,
  convertToCurrentGMT,
  exportToCsv,
  groupBy,
  handleDataOrder,
  normalizeName,
  sortArrayBaseOnAnotherArray,
  sum
} from '_utils/function'
import { SelectIdGroupContext } from '../../context/SelectGroupContext'
import OrderSearch from '../../pages/Event/ManageOrder/SearchOrder'
import OrderList from './components/OrderList'
import Pagination from './components/Pagination'
import SelectFilter from './components/SelectFilter/index'
import './style.scss'

const format1 = 1
const format2 = 2

function handleExport(getEventById, format) {
  const {
    title,
    description,
    closingTime,
    pickupTime,
    createdUserName,
    createdUserPhone,
    orders = [],
    adminCost,
    discount,
    deliveryCost,
  } = getEventById || {}

  const { totalAllOrder, adminCostAllOrder, paymentCollected } = handleDataOrder(
    orders,
    adminCost,
    discount,
    deliveryCost
  )

  const ordersActive = orders.filter(
    (order) =>
      order.buyerStatus !== BUYER_ORDER_CANCELED &&
      order.buyerStatus !== BUYER_REFUNDED &&
      order.status !== HOST_ORDER_CANCELED
  )

  const firstName = createdUserName ? createdUserName.split(' ').slice(0, 1) : ''
  const lastName = createdUserName ? createdUserName.split(' ').slice(1).join(' ') : ''

  const generalInfo = [
    ['Title', title],
    ['Description', description],
    ['Closing Date', convertToCurrentGMT(closingTime).trim().split(' ')[0]],
    ['Closing Time', convertToCurrentGMT(closingTime).trim().split(' ')[1]],
    ['Pick-up Date', convertToCurrentGMT(pickupTime).trim().split(' ')[0]],
    ['Pick-up Time', convertToCurrentGMT(pickupTime).trim().split(' ')[1]],
    ['Organiser First Name', firstName],
    ['Organiser Last Name', lastName],
    ['Organiser Number', createdUserPhone],
    [''],
  ]

  const total = [
    ['Total Purchase', `$${totalAllOrder}`],
    ['Admin Fee', `$${adminCostAllOrder}`],
    ['Payment Confirmed', `$${paymentCollected}`],
    [''],
  ]

  const totalOrderArr = [
    ...ordersActive.reduce((acc, curr) => {
      const order = acc.find((p) => p.pid === curr.pid)
      if (order) {
        const index = acc.indexOf(order)
        acc[index].count += curr.pQuantity
      } else {
        curr.count = curr.pQuantity
        acc.push(curr)
      }
      return acc
    }, []),
  ]

  const rowsOfTotalOrder = new Array(totalOrderArr.length)

  // function export1() { comment
  //   const rowsOfProduct = new Array(ordersActive.length) || []

  //   for (let index = 0; index < ordersActive.length; index++) {
  //     const product = ordersActive[index]
  //     const num = ordersActive.filter((o) => o.ref === product.ref).length
  //     const {
  //       eOrderNo,
  //       ref,
  //       uName,
  //       uPhone,
  //       pQuantity,
  //       pName,
  //       pPrice,
  //       buyerStatus,
  //       status: productStatus,
  //       pSubItems,
  //       comment,
  //       combinedPaymentRef,
  //     } = product

  //     const { deliveryPerOrder } = listOrder.find((x) => x.ref === ref)
  //     const sharedDiscount = Number(discount * ((pPrice * pQuantity) / totalAllOrder))
  //     const sharedDeliveryCost = Number(deliveryPerOrder / num)
  //     const adminFee = Number(adminCost / num)
  //     const amount = pPrice * pQuantity - sharedDiscount + sharedDeliveryCost + adminFee
  //     const payTogether = combinedPaymentRef ? 'Yes' : 'No'
  //     let pNameAndSub = pName

  //     if (Array.isArray(pSubItems)) {
  //       if (pSubItems.length) {
  //         pNameAndSub += ' ('
  //         for (let i = 0; i < pSubItems.length; i++) {
  //           const subItem = pSubItems[i]
  //           pNameAndSub += `${subItem.name}: +$${subItem.price}; `
  //         }
  //         pNameAndSub += ')'
  //       }
  //     }

  //     const buyerFirstName = uName ? uName.split(' ').slice(0, 1) : ''
  //     const buyerLastName = uName ? uName.split(' ').slice(1).join(' ') : ''

  //     rowsOfProduct[index] = [
  //       eOrderNo,
  //       ref,
  //       buyerFirstName,
  //       buyerLastName,
  //       uPhone,
  //       pQuantity,
  //       pNameAndSub,
  //       pPrice,
  //       sharedDiscount.toFixed(2),
  //       sharedDeliveryCost.toFixed(2),
  //       adminFee.toFixed(2),
  //       Number(amount).toFixed(2),
  //       convertStatusNumberToText(buyerStatus), // BUYER
  //       convertStatusNumberToText(productStatus), // HOST
  //       payTogether,
  //       comment,
  //     ]
  //   }

  //   for (let index = 0; index < totalOrderArr.length; index++) {
  //     const totalOrder = totalOrderArr[index]

  //     const { pName, count } = totalOrder

  //     rowsOfTotalOrder[index] = [pName, count]
  //   }

  //   const rows = [
  //     ...generalInfo,
  //     [
  //       'Order No',
  //       'Ref',
  //       'First Name',
  //       'Last Name',
  //       'Contact',
  //       'Quantity',
  //       'Item',
  //       'Price',
  //       'Shared discount',
  //       'Shared delivery cost',
  //       'Admin fee',
  //       'Amount',
  //       'Payment',
  //       'Status',
  //       'Pay together',
  //       'Comment',
  //     ],
  //     ...rowsOfProduct,
  //     [''],
  //     ...total,
  //     ['Total orders'],
  //     ...rowsOfTotalOrder,
  //   ]

  //   const fileName = normalizeName(title)
  //   exportToCsv(`${fileName}.csv`, rows)
  // }

  function export1() {
    const rowsOfProduct = new Array(ordersActive.length) || []

    for (let index = 0; index < ordersActive.length; index++) {
      const product = ordersActive[index]
      const {
        eOrderNo,
        ref,
        uName,
        uPhone,
        eTitle,
        createdAt,
        pName,
        pQuantity,
        status,
        buyerStatus,
      } = product

      let buyerStatusString = ''

      switch (buyerStatus) {
        case BUYER_UNPAID:
          buyerStatusString = 'Not Paid'
          break
        case BUYER_PAID:
          buyerStatusString = 'Paid'
          break
        case BUYER_CANCELED:
          buyerStatusString = 'Cancelled'
          break
        case BUYER_COLLECTED:
          buyerStatusString = 'Collected'
          break
        case BUYER_REFUNDED:
          buyerStatusString = 'Refunded'
          break
        default:
          break
      }

      rowsOfProduct[index] = [
        eOrderNo,
        ref,
        uName,
        uPhone,
        eTitle,
        moment.unix(createdAt).format('DD-MM-YYYY'),
        pName,
        pQuantity,
        `${status === 0 ? 'Close order' : 'Open order'}`,
        buyerStatusString,
      ]
    }

    const rows = [
      [['Event name', title]],
      [''],
      [''],
      [
        'Order No',
        'Ref',
        'Name',
        'Phone',
        'Event',
        'Date',
        'Purchase',
        'Quantity',
        'Event status',
        'Payment status',
      ],
      ...rowsOfProduct,
    ]

    const fileName = normalizeName(title)
    exportToCsv(`${fileName}.csv`, rows)
  }

  function export2() {
    const { products, productIdList } = getEventById

    const rowsOfContact = new Array(0)
    let colsOfProduct = []

    for (let i = 0; i < totalOrderArr.length; i++) {
      const order = totalOrderArr[i]
      const listTempProName = []
      const listTempProPrice = []
      const subitems = products.find((x) => x.id === order.pid).subItems || []
      subitems.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
      for (let j = 0; j < subitems.length; j++) {
        const subitem = subitems[j]
        const optionsTempName = []
        const optionsTempPrice = []
        const { list, required } = subitem
        if (required === 0) {
          optionsTempName.push('')
          optionsTempPrice.push(0)
        }
        for (let k = 0; k < list.length; k++) {
          const option = list[k]
          optionsTempName.push(option.name)
          optionsTempPrice.push(option.price)
        }
        listTempProName.push(optionsTempName)
        listTempProPrice.push(optionsTempPrice)
      }

      let cartesianProductPrice = []
      if (listTempProPrice.length) {
        cartesianProductPrice = cartesian(listTempProPrice.map((item) => item))
      }

      if (!listTempProName.length) listTempProName.push([order.pName])
      if (!listTempProPrice.length) listTempProPrice.push([order.pPrice])

      const cartesianProductName = cartesian(listTempProName.map((item) => item))

      const _products = []

      for (let l = 0; l < cartesianProductName.length; l++) {
        const productName = cartesianProductName[l].toString()
        let productPrice = 0
        if (cartesianProductPrice.length) {
          productPrice = cartesianProductPrice[l].reduce((a, b) => a + b, 0)
        }
        _products.push({ pid: order.pid, name: productName, price: productPrice })
      }

      colsOfProduct = [
        ...colsOfProduct,
        ..._products.map((product) => {
          if (subitems.length) {
            return {
              ...product,
              name: product.name ? `${order.pName} (${product.name})` : `${order.pName}`,
            }
          }
          return { ...product, name: order.pName }
        }),
      ]

      totalOrderArr[i] = { ...order, subItems: subitems }
    }

    const _totalOrderArr = sortArrayBaseOnAnotherArray(totalOrderArr, [...productIdList])

    const _sortColsOfProduct = sortArrayBaseOnAnotherArray(colsOfProduct, [...productIdList])

    for (let i = 0; i < products.length; i++) {
      const productTemp = products[i]
      for (let j = 0; j < _sortColsOfProduct.length; j++) {
        const product = _sortColsOfProduct[j]
        if (product.pid === productTemp.id) {
          _sortColsOfProduct[j] = { ...product, price: product.price + productTemp.price }
        }
      }
    }

    const groupByOrderWithBuyerName = groupBy(ordersActive, 'uName')
    const listOfBuyerName = Object.keys(groupByOrderWithBuyerName)

    const colProductName = _sortColsOfProduct.map((p) => p.name)
    const colProductNameAndId = _sortColsOfProduct.map((p) => `${p.name} ${p.pid}`)
    const colProductPrice = _sortColsOfProduct.map((p) => `$${p.price}`)

    if (Array.isArray(listOfBuyerName)) {
      for (let i = 0; i < listOfBuyerName.length; i++) {
        const colOrderContact = []
        const buyerName = listOfBuyerName[i]
        const { uPhone: buyerPhone } = groupByOrderWithBuyerName[buyerName][0]

        for (let j = 0; j < groupByOrderWithBuyerName[buyerName].length; j++) {
          const objOrder = groupByOrderWithBuyerName[buyerName][j]
          const { pName, pSubItems } = objOrder

          let productName = pName
          if (pSubItems.length) {
            productName = `${pName} (${pSubItems
              .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
              .map((s) => s.name)
              .toString()})`
          }

          groupByOrderWithBuyerName[buyerName][j] = { ...objOrder, pName: productName }
        }

        // eslint-disable-next-line no-confusing-arrow
        const _sum = sum(groupByOrderWithBuyerName[buyerName]).sort((a, b) =>
          a.pName > b.pName ? 1 : b.pName > a.pName ? -1 : 0
        )

        for (let k = 0; k < colProductNameAndId.length; k++) {
          let _quantity = 0
          const productNameAndId = colProductNameAndId[k]
          for (let j = 0; j < _sum.length; j++) {
            const _order = _sum[j]
            if (productNameAndId === _order.pNameAndPid) {
              _quantity += _order.pQuantity
            }
          }
          colOrderContact.push(_quantity)
        }

        const buyerFirstName = buyerName ? buyerName.split(' ').slice(0, 1) : ''
        const buyerLastName = buyerName ? buyerName.split(' ').slice(1).join(' ') : ''

        const comments = groupByOrderWithBuyerName[buyerName].map((order) => order.comment)
        const commentCombined = comments.filter((comment) => comment !== null).join(', ')

        rowsOfContact.push([
          '',
          buyerFirstName,
          buyerLastName,
          buyerPhone,
          commentCombined,
          ...colOrderContact,
        ])
      }
    }

    for (let index = 0; index < _totalOrderArr.length; index++) {
      const totalOrder = _totalOrderArr[index]

      const { pName, count } = totalOrder

      rowsOfTotalOrder[index] = [pName, count]
    }

    const rows = [
      ...generalInfo,
      ['', '', '', '', '', ...colProductPrice],
      ['', 'First Name', 'Last Name', 'Contact', 'Comment', ...colProductName],
      ...rowsOfContact,
      [''],
      ...total,
      ['Total orders'],
      ...rowsOfTotalOrder,
    ]

    const fileName = normalizeName(title)
    exportToCsv(`${fileName}.csv`, rows)
  }

  if (format === format1) {
    export1()
  } else {
    export2()
  }
}

function handleExportMultipleEventAndOrder(listTotalOrderAfterFilter, format) {
  // function export1(arrayEvent) {
  //   const listTitle = []
  //   const wb = XLSX.utils.book_new()
  //   for (let n = 0; n < arrayEvent?.length; n++) {
  //     const {
  //       title,
  //       description,
  //       closingTime,
  //       pickupTime,
  //       createdUserName,
  //       createdUserPhone,
  //       orders = [],
  //       adminCost,
  //       discount,
  //       deliveryCost,
  //     } = arrayEvent[n] || {}

  //     const { listOrder, totalAllOrder, adminCostAllOrder, paymentCollected } = handleDataOrder(
  //       orders,
  //       adminCost,
  //       discount,
  //       deliveryCost
  //     )

  //     const ordersActive = orders.filter(
  //       (order) =>
  //         order.buyerStatus !== BUYER_ORDER_CANCELED &&
  //         order.buyerStatus !== BUYER_REFUNDED &&
  //         order.status !== HOST_ORDER_CANCELED
  //     )

  //     const firstName = createdUserName ? createdUserName.split(' ').slice(0, 1) : ''
  //     const lastName = createdUserName ? createdUserName.split(' ').slice(1).join(' ') : ''

  //     const generalInfo = [
  //       ['Title', title],
  //       ['Description', description],
  //       ['Closing Date', convertToCurrentGMT(closingTime).trim().split(' ')[0]],
  //       ['Closing Time', convertToCurrentGMT(closingTime).trim().split(' ')[1]],
  //       ['Pick-up Date', convertToCurrentGMT(pickupTime).trim().split(' ')[0]],
  //       ['Pick-up Time', convertToCurrentGMT(pickupTime).trim().split(' ')[1]],
  //       ['Organiser First Name', firstName],
  //       ['Organiser Last Name', lastName],
  //       ['Organiser Number', createdUserPhone],
  //       [''],
  //     ]

  //     const total = [
  //       ['Total Purchase', `$${totalAllOrder}`],
  //       ['Admin Fee', `$${adminCostAllOrder}`],
  //       ['Payment Confirmed', `$${paymentCollected}`],
  //       [''],
  //     ]

  //     const totalOrderArr = [
  //       ...ordersActive.reduce((acc, curr) => {
  //         const order = acc.find((p) => p.pid === curr.pid)
  //         if (order) {
  //           const index = acc.indexOf(order)
  //           acc[index].count += curr.pQuantity
  //         } else {
  //           curr.count = curr.pQuantity
  //           acc.push(curr)
  //         }
  //         return acc
  //       }, []),
  //     ]

  //     const rowsOfTotalOrder = new Array(totalOrderArr.length)

  //     const rowsOfProduct = new Array(ordersActive.length) || []

  //     for (let index = 0; index < ordersActive.length; index++) {
  //       const product = ordersActive[index]
  //       const num = ordersActive.filter((o) => o.ref === product.ref).length
  //       const {
  //         eOrderNo,
  //         ref,
  //         uName,
  //         uPhone,
  //         pQuantity,
  //         pName,
  //         pPrice,
  //         buyerStatus,
  //         status: productStatus,
  //         pSubItems,
  //         comment,
  //         combinedPaymentRef,
  //       } = product

  //       const { deliveryPerOrder } = listOrder.find((x) => x.ref === ref)
  //       const sharedDiscount = Number(discount * ((pPrice * pQuantity) / totalAllOrder))
  //       const sharedDeliveryCost = Number(deliveryPerOrder / num)
  //       const adminFee = Number(adminCost / num)
  //       const amount = pPrice * pQuantity - sharedDiscount + sharedDeliveryCost + adminFee
  //       const payTogether = combinedPaymentRef ? 'Yes' : 'No'
  //       let pNameAndSub = pName

  //       if (Array.isArray(pSubItems)) {
  //         if (pSubItems.length) {
  //           pNameAndSub += ' ('
  //           for (let i = 0; i < pSubItems.length; i++) {
  //             const subItem = pSubItems[i]
  //             pNameAndSub += `${subItem.name}: +$${subItem.price}; `
  //           }
  //           pNameAndSub += ')'
  //         }
  //       }

  //       const buyerFirstName = uName ? uName.split(' ').slice(0, 1) : ''
  //       const buyerLastName = uName ? uName.split(' ').slice(1).join(' ') : ''

  //       rowsOfProduct[index] = [
  //         eOrderNo,
  //         ref,
  //         buyerFirstName,
  //         buyerLastName,
  //         uPhone,
  //         pQuantity,
  //         pNameAndSub,
  //         pPrice,
  //         sharedDiscount.toFixed(2),
  //         sharedDeliveryCost.toFixed(2),
  //         adminFee.toFixed(2),
  //         Number(amount).toFixed(2),
  //         convertStatusNumberToText(buyerStatus), // BUYER
  //         convertStatusNumberToText(productStatus), // HOST
  //         payTogether,
  //         comment,
  //       ]
  //     }

  //     for (let index = 0; index < totalOrderArr.length; index++) {
  //       const totalOrder = totalOrderArr[index]

  //       const { pName, count } = totalOrder

  //       rowsOfTotalOrder[index] = [pName, count]
  //     }

  //     const rows = [
  //       ...generalInfo,
  //       [
  //         'Order No',
  //         'Ref',
  //         'First Name',
  //         'Last Name',
  //         'Contact',
  //         'Quantity',
  //         'Item',
  //         'Price',
  //         'Shared discount',
  //         'Shared delivery cost',
  //         'Admin fee',
  //         'Amount',
  //         'Payment',
  //         'Status',
  //         'Pay together',
  //         'Comment',
  //       ],
  //       ...rowsOfProduct,
  //       [''],
  //       ...total,
  //       ['Total orders'],
  //       ...rowsOfTotalOrder,
  //     ]
  //     const formatSheetName = title.slice(0, 28).trim().replace(/[/ ]/g, "-")
  //     const CheckSheetNameIsExit = listTitle.includes(formatSheetName)
  //     let sheetAfterFormat
  //     if (CheckSheetNameIsExit) {
  //       sheetAfterFormat = `(1)${formatSheetName}`
  //       const checkSheetAfterFormatIsExit = listTitle.includes(sheetAfterFormat)
  //       if (checkSheetAfterFormatIsExit) {
  //         const countItemIsDuplicate = listTitle.filter((item) => item === sheetAfterFormat).length
  //         sheetAfterFormat = `(${countItemIsDuplicate + 1})${formatSheetName}`
  //       }
  //     } else {
  //       sheetAfterFormat = formatSheetName
  //     }
  //     // const removeSpecialCharacters = sheetAfterFormat
  //     // console.log(removeSpecialCharacters)
  //     const data = rows
  //     const ws = XLSX.utils.aoa_to_sheet(data)
  //     XLSX.utils.book_append_sheet(wb, ws, sheetAfterFormat)
  //     listTitle.push(sheetAfterFormat)
  //   }
  //   XLSX.writeFile(wb, 'Total order.xlsx')
  // }

  function export1(arrayOrder, arrayEventName) {
    const rowsOfProduct = new Array(arrayOrder.length) || []

    for (let index = 0; index < arrayOrder.length; index++) {
      const product = arrayOrder[index]
      const { ref, uName, uPhone, eTitle, createdAt, pName, pQuantity, status, buyerStatus } =
        product

      let buyerStatusString = ''

      switch (buyerStatus) {
        case BUYER_UNPAID:
          buyerStatusString = 'Not Paid'
          break
        case BUYER_PAID:
          buyerStatusString = 'Paid'
          break
        case BUYER_CANCELED:
          buyerStatusString = 'Cancelled'
          break
        case BUYER_COLLECTED:
          buyerStatusString = 'Collected'
          break
        case BUYER_REFUNDED:
          buyerStatusString = 'Refunded'
          break
        default:
          break
      }

      rowsOfProduct[index] = [
        index,
        ref,
        uName,
        uPhone,
        eTitle,
        moment.unix(createdAt).format('DD-MM-YYYY'),
        pName,
        pQuantity,
        `${status === 0 ? 'Close order' : 'Open order'}`,
        buyerStatusString,
      ]
    }

    const rows = [
      [''],
      [''],
      [''],
      ['', ['List event is filtered:', arrayEventName?.join(',')]],
      [''],
      [''],
      [
        'Order No',
        'Ref',
        'Name',
        'Phone',
        'Event',
        'Date',
        'Purchase',
        'Quantity',
        'Event status',
        'Payment status',
      ],
      ...rowsOfProduct,
    ]

    const fileName = normalizeName(`manage orders by ${moment().format('DD-MM-YYYY')}`)
    exportToCsv(`${fileName}.csv`, rows)
  }

  function export2(arrayEvent) {
    const listTitle = []
    const wb = XLSX.utils.book_new()
    for (let n = 0; n < arrayEvent?.length; n++) {
      const {
        title,
        description,
        closingTime,
        pickupTime,
        createdUserName,
        createdUserPhone,
        orders = [],
        adminCost,
        discount,
        deliveryCost,
      } = arrayEvent[n] || {}

      const { totalAllOrder, adminCostAllOrder, paymentCollected } = handleDataOrder(
        orders,
        adminCost,
        discount,
        deliveryCost
      )
      const ordersActive = orders.filter(
        (order) =>
          order.buyerStatus !== BUYER_ORDER_CANCELED &&
          order.buyerStatus !== BUYER_REFUNDED &&
          order.status !== HOST_ORDER_CANCELED
      )
      const firstName = createdUserName ? createdUserName.split(' ').slice(0, 1) : ''
      const lastName = createdUserName ? createdUserName.split(' ').slice(1).join(' ') : ''
      const generalInfo = [
        ['Title', title],
        ['Description', description],
        ['Closing Date', convertToCurrentGMT(closingTime).trim().split(' ')[0]],
        ['Closing Time', convertToCurrentGMT(closingTime).trim().split(' ')[1]],
        ['Pick-up Date', convertToCurrentGMT(pickupTime).trim().split(' ')[0]],
        ['Pick-up Time', convertToCurrentGMT(pickupTime).trim().split(' ')[1]],
        ['Organiser First Name', firstName],
        ['Organiser Last Name', lastName],
        ['Organiser Number', createdUserPhone],
        [''],
      ]
      const total = [
        ['Total Purchase', `$${totalAllOrder}`],
        ['Admin Fee', `$${adminCostAllOrder}`],
        ['Payment Confirmed', `$${paymentCollected}`],
        [''],
      ]
      const totalOrderArr = [
        ...ordersActive.reduce((acc, curr) => {
          const order = acc.find((p) => p.pid === curr.pid)
          if (order) {
            const index = acc.indexOf(order)
            acc[index].count += curr.pQuantity
          } else {
            curr.count = curr.pQuantity
            acc.push(curr)
          }
          return acc
        }, []),
      ]
      const rowsOfTotalOrder = new Array(totalOrderArr.length)

      const { products, productIdList } = arrayEvent[n]

      const rowsOfContact = new Array(0)
      let colsOfProduct = []

      for (let i = 0; i < totalOrderArr.length; i++) {
        const order = totalOrderArr[i]
        const listTempProName = []
        const listTempProPrice = []
        const subitems = products.find((x) => x.id === order.pid).subItems || []
        subitems.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
        for (let j = 0; j < subitems.length; j++) {
          const subitem = subitems[j]
          const optionsTempName = []
          const optionsTempPrice = []
          const { list, required } = subitem
          if (required === 0) {
            optionsTempName.push('')
            optionsTempPrice.push(0)
          }
          for (let k = 0; k < list.length; k++) {
            const option = list[k]
            optionsTempName.push(option.name)
            optionsTempPrice.push(option.price)
          }
          listTempProName.push(optionsTempName)
          listTempProPrice.push(optionsTempPrice)
        }

        let cartesianProductPrice = []
        if (listTempProPrice.length) {
          cartesianProductPrice = cartesian(listTempProPrice.map((item) => item))
        }

        if (!listTempProName.length) listTempProName.push([order.pName])
        if (!listTempProPrice.length) listTempProPrice.push([order.pPrice])

        const cartesianProductName = cartesian(listTempProName.map((item) => item))

        const _products = []

        for (let l = 0; l < cartesianProductName.length; l++) {
          const productName = cartesianProductName[l].toString()
          let productPrice = 0
          if (cartesianProductPrice.length) {
            productPrice = cartesianProductPrice[l].reduce((a, b) => a + b, 0)
          }
          _products.push({ pid: order.pid, name: productName, price: productPrice })
        }

        colsOfProduct = [
          ...colsOfProduct,
          ..._products.map((product) => {
            if (subitems.length) {
              return {
                ...product,
                name: product.name ? `${order.pName} (${product.name})` : `${order.pName}`,
              }
            }
            return { ...product, name: order.pName }
          }),
        ]

        totalOrderArr[i] = { ...order, subItems: subitems }
      }

      const _totalOrderArr = sortArrayBaseOnAnotherArray(totalOrderArr, [...productIdList])

      const _sortColsOfProduct = sortArrayBaseOnAnotherArray(colsOfProduct, [...productIdList])

      for (let i = 0; i < products.length; i++) {
        const productTemp = products[i]
        for (let j = 0; j < _sortColsOfProduct.length; j++) {
          const product = _sortColsOfProduct[j]
          if (product.pid === productTemp.id) {
            _sortColsOfProduct[j] = { ...product, price: product.price + productTemp.price }
          }
        }
      }

      const groupByOrderWithBuyerName = groupBy(ordersActive, 'uName')
      const listOfBuyerName = Object.keys(groupByOrderWithBuyerName)

      const colProductName = _sortColsOfProduct.map((p) => p.name)
      const colProductNameAndId = _sortColsOfProduct.map((p) => `${p.name} ${p.pid}`)
      const colProductPrice = _sortColsOfProduct.map((p) => `$${p.price}`)

      if (Array.isArray(listOfBuyerName)) {
        for (let i = 0; i < listOfBuyerName.length; i++) {
          const colOrderContact = []
          const buyerName = listOfBuyerName[i]
          const { uPhone: buyerPhone } = groupByOrderWithBuyerName[buyerName][0]

          for (let j = 0; j < groupByOrderWithBuyerName[buyerName].length; j++) {
            const objOrder = groupByOrderWithBuyerName[buyerName][j]
            const { pName, pSubItems } = objOrder

            let productName = pName
            if (pSubItems.length) {
              productName = `${pName} (${pSubItems
                .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
                .map((s) => s.name)
                .toString()})`
            }

            groupByOrderWithBuyerName[buyerName][j] = { ...objOrder, pName: productName }
          }

          const _sum = sum(groupByOrderWithBuyerName[buyerName]).sort((a, b) =>
            a.pName > b.pName ? 1 : b.pName > a.pName ? -1 : 0
          )

          for (let k = 0; k < colProductNameAndId.length; k++) {
            let _quantity = 0
            const productNameAndId = colProductNameAndId[k]
            for (let j = 0; j < _sum.length; j++) {
              const _order = _sum[j]
              if (productNameAndId === _order.pNameAndPid) {
                _quantity += _order.pQuantity
              }
            }
            colOrderContact.push(_quantity)
          }

          const buyerFirstName = buyerName ? buyerName.split(' ').slice(0, 1) : ''
          const buyerLastName = buyerName ? buyerName.split(' ').slice(1).join(' ') : ''

          const comments = groupByOrderWithBuyerName[buyerName].map((order) => order.comment)
          const commentCombined = comments.filter((comment) => comment !== null).join(', ')

          rowsOfContact.push([
            '',
            buyerFirstName,
            buyerLastName,
            buyerPhone,
            commentCombined,
            ...colOrderContact,
          ])
        }
      }

      for (let index = 0; index < _totalOrderArr.length; index++) {
        const totalOrder = _totalOrderArr[index]

        const { pName, count } = totalOrder

        rowsOfTotalOrder[index] = [pName, count]
      }

      const rows = [
        ...generalInfo,
        ['', '', '', '', '', ...colProductPrice],
        ['', 'First Name', 'Last Name', 'Contact', 'Comment', ...colProductName],
        ...rowsOfContact,
        [''],
        ...total,
        ['Total orders'],
        ...rowsOfTotalOrder,
      ]
      const formatSheetName = title.slice(0, 28)
      const CheckSheetNameIsExit = listTitle.includes(formatSheetName)
      let sheetAfterFormat
      if (CheckSheetNameIsExit) {
        sheetAfterFormat = `(1)${formatSheetName}`
        const checkSheetAfterFormatIsExit = listTitle.includes(sheetAfterFormat)
        if (checkSheetAfterFormatIsExit) {
          const countItemIsDuplicate = listTitle.filter((item) => item === sheetAfterFormat).length
          sheetAfterFormat = `$ ${countItemIsDuplicate + 1}{formatSheetName}`
        }
      } else {
        sheetAfterFormat = formatSheetName
      }
      const data = rows
      const ws = XLSX.utils.aoa_to_sheet(data)
      XLSX.utils.book_append_sheet(wb, ws, sheetAfterFormat)
      listTitle.push(sheetAfterFormat)
    }
    XLSX.writeFile(wb, 'Total order.xlsx')
  }

  if (format === format1) {
    // export1(listTotalOrderAfterFilter)
    const orderOfMultipleEvent = []
    const nameOfMultipleEvent = []
    listTotalOrderAfterFilter?.forEach((item) => {
      orderOfMultipleEvent.push(item.orders)
      nameOfMultipleEvent.push(item.title)
    })
    export1(orderOfMultipleEvent.flat(), nameOfMultipleEvent)
  } else {
    export2(listTotalOrderAfterFilter)
  }
}

function ManageOrder() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const context = useContext(SelectIdGroupContext)
  const { groupId } = context.infoGroupSelected

  const { eventAndOrderById, errorGetEventAndOrder, listEventGroupIdByHost, listEventAndDelivery } =
    useSelector((state) => state.event)

  const [filter, setFilter] = useState({
    listEvent: [],
    rangeDate: [
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ],
    eventStatus: 'default',
    paymentStatus: [],
  })

  const { listEvent, eventStatus, paymentStatus, rangeDate } = filter
  const { startDate, endDate } = rangeDate[0]
  const eventId = listEvent.map((event) => event.id)
  const paymentStatusValue = paymentStatus.map((status) => status.value)

  const eStatus = listEventAndDelivery?.eventAndOrders.filter(
    (event) => event.status === Number(eventStatus)
  )

  const eventStatusId = eStatus?.map((event) => event.id)

  const { adminCost, discount, deliveryCost, id, orders, productIdList, totalOrderCount } =
    eventAndOrderById.find((item) => item.id === eventId) || {}

  const { listOrder = [] } =
    productIdList && productIdList.length > 0
      ? handleDataOrder(orders, adminCost, discount, deliveryCost, [...productIdList])
      : {}

  const orderList = listEventAndDelivery?.eventAndOrders
    .map((event) => handleDataOrder(event.orders, null, null, null, null).listOrder)
    .flat()

  const [timeRender, setTimeRender] = useState(0)

  const [sorting, setSorting] = useState({ field: 'eOrderNo', order: 'asc', type: 'number' })

  const [, setTotalOrderCount] = useState(totalOrderCount)
  const [searchString, setSearchString] = useState('')
  const [page, setPage] = useState({
    currentPage: 1,
    ordersPerPage: 10,
  })

  const { currentPage, ordersPerPage } = page

  // Logic for displaying orders
  const indexOfLastOrder = currentPage * ordersPerPage
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  const handleClearFilter = () => {
    setFilter({
      ...filter,
      listEvent: [],
      eventStatus: 'default',
      rangeDate: [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ],
      paymentStatus: [],
    })
    setSearchString('')
  }

  const handleChangeEvent = (options) => {
    const optionSelected = options.selectedOption.map((option) => option)
    setFilter({
      ...filter,
      listEvent: [...optionSelected],
    })
  }

  const handleChangeDate = (data) => {
    const formatStartDate = new Date(data[0].startDate.setHours(0, 0, 0, 0))
    const formatEndDate = new Date(data[0].endDate.setHours(23, 59, 59, 999))

    setFilter({
      ...filter,
      rangeDate: [
        {
          startDate: formatStartDate,
          endDate: formatEndDate,
          key: 'selection',
        },
      ],
    })
  }

  const handleChangeEventStatus = (event) => {
    const { value } = event.target
    setFilter({
      ...filter,
      eventStatus: value,
    })
  }

  const handlePaymentStatus = (options) => {
    const optionSelected = options.selectedOption.map((option) => option)
    setFilter({
      ...filter,
      paymentStatus: [...optionSelected],
    })
  }

  const [loading, setLoading] = useState(false)

  const initialOrder = async () => {
    setLoading(true)
    await dispatch(getListEventAndDelivery(groupId))
    setLoading(false)
  }

  useEffect(() => {
    if (groupId !== undefined) {
      dispatch(getEventGroupIdByHost(groupId))
      initialOrder()
      handleClearFilter()
    }
  }, [groupId, timeRender])

  useEffect(() => {
    setTotalOrderCount(totalOrderCount)
  }, [totalOrderCount])

  const listOrderFiltered = useMemo(() => {
    if (eventId.length > 0) {
      if (paymentStatus.length > 0) {
        if (startDate !== null && endDate !== null) {
          if (eventStatus !== 'default') {
            return orderList.filter(
              (order) =>
                paymentStatusValue.includes(order.buyerStatus) &&
                moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
                eventStatusId.includes(order.eid) &&
                eventId.includes(order.eid)
            )
          }
          return orderList.filter(
            (order) =>
              paymentStatusValue.includes(order.buyerStatus) &&
              moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
              eventId.includes(order.eid)
          )
        }
        if (eventStatus !== 'default') {
          return orderList.filter(
            (order) =>
              paymentStatusValue.includes(order.buyerStatus) &&
              eventStatusId.includes(order.eid) &&
              eventId.includes(order.eid)
          )
        }
        return orderList.filter(
          (order) => paymentStatusValue.includes(order.buyerStatus) && eventId.includes(order.eid)
        )
      }

      if (startDate !== null && endDate !== null) {
        if (eventStatus !== 'default') {
          return orderList.filter(
            (order) =>
              moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
              eventStatusId.includes(order.eid) &&
              eventId.includes(order.eid)
          )
        }
        return orderList.filter(
          (order) =>
            moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
            eventId.includes(order.eid)
        )
      }

      if (eventStatus !== 'default') {
        return orderList.filter(
          (order) => eventStatusId.includes(order.eid) && eventId.includes(order.eid)
        )
      }

      return orderList.filter((order) => eventId.includes(order.eid))
    }

    if (eventId.length === 0) {
      if (paymentStatus.length > 0) {
        if (startDate !== null && endDate !== null) {
          if (eventStatus !== 'default') {
            return orderList.filter(
              (order) =>
                paymentStatusValue.includes(order.buyerStatus) &&
                moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
                eventStatusId.includes(order.eid)
            )
          }
          return orderList.filter(
            (order) =>
              paymentStatusValue.includes(order.buyerStatus) &&
              moment(order.createdAt * 1000).isBetween(startDate, endDate)
          )
        }
        if (eventStatus !== 'default') {
          return orderList.filter(
            (order) =>
              paymentStatusValue.includes(order.buyerStatus) && eventStatusId.includes(order.eid)
          )
        }
        return orderList.filter((order) => paymentStatusValue.includes(order.buyerStatus))
      }
    }

    if (eventId.length === 0) {
      if (eventStatus !== 'default') {
        if (startDate && endDate) {
          return orderList.filter(
            (order) =>
              moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
              eventStatusId.includes(order.eid)
          )
        }
        return orderList.filter((order) => eventStatusId.includes(order.eid))
      }

      if (startDate === null && endDate === null) {
        return orderList
      }

      if (startDate && endDate) {
        if (eventStatus !== 'default') {
          return orderList.filter(
            (order) =>
              moment(order.createdAt * 1000).isBetween(startDate, endDate) &&
              eventStatusId.includes(order.eid)
          )
        }
        return orderList.filter((order) =>
          moment(order.createdAt * 1000).isBetween(startDate, endDate)
        )
      }

      return orderList
    }
  }, [eventId, eventStatus, paymentStatus, startDate, endDate, orderList])

  // Logic for displaying page numbers
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(listOrderFiltered?.length / ordersPerPage); i++) {
    pageNumbers.push(i)
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setPage({
        ...page,
        currentPage: currentPage - 1,
      })
    }
  }

  const handleNext = () => {
    if (currentPage < pageNumbers.length) {
      setPage({
        ...page,
        currentPage: currentPage + 1,
      })
    }
  }

  const listOrders = useMemo(() => {
    if ((listOrder && listOrder.length > 0) || listOrderFiltered !== undefined) {
      let listOrderSort = [...listOrderFiltered]
      if (searchString) {
        listOrderSort = listOrderSort.filter(
          (item) =>
            item.uPhone.toString().includes(searchString) ||
            item.uName.toLowerCase().includes(searchString.toLowerCase())
        )
      }
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
          )
        } else {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * (a[sorting.field] - b[sorting.field])
          )
        }
      }
      return listOrderSort
    }
  }, [sorting, searchString, listOrder])

  const currentOrders = listOrders?.slice(indexOfFirstOrder, indexOfLastOrder)

  if (listEventGroupIdByHost?.length > 0) {
    if (
      eventId.length > 0 &&
      pageNumbers.length === 1 &&
      currentOrders?.length === 0 &&
      currentPage > pageNumbers.length
    ) {
      setPage({
        ...page,
        currentPage: 1,
      })
    }
  }

  if (listEventGroupIdByHost?.length > 0) {
    if (
      eventId.length === 0 &&
      pageNumbers.length === 1 &&
      currentOrders.length === 0 &&
      currentPage > pageNumbers.length
    ) {
      setPage({
        ...page,
        currentPage: 1,
      })
    }
  }

  const filterBeforeExport = (format) => {
    function handleFilterListOrder() {
      if (eventId?.length === 1) {
        const orderOfEvent = []
        const getEventById = listEventAndDelivery?.eventAndOrders?.filter(
          (item) => item.id === eventId[0]
        )[0]
        const getRefOfOder = listOrderFiltered?.map((item) => item.ref)
        for (let i = 0; i < getRefOfOder?.length; i++) {
          orderOfEvent.push(getEventById?.orders?.filter((item) => item.ref === getRefOfOder[i])[0])
        }
        if (getEventById && orderOfEvent?.length > 0) {
          const eventAndOrderByFilter = {
            ...getEventById,
            orders: orderOfEvent,
          }
          handleExport(eventAndOrderByFilter, format)
        }
      } else {
        const listEventSortById = []
        const listIdEvent = listOrderFiltered?.map((item) => item.eid)
        const listRefOfOder = listOrderFiltered?.map((item) => item.ref)
        const uniqueIDate = []
        const uniqueEventList = listIdEvent.filter((element) => {
          const isDuplicate = uniqueIDate.includes(element)

          if (!isDuplicate) {
            uniqueIDate.push(element)
            return true
          }

          return false
        })
        for (let i = 0; i < uniqueEventList?.length; i++) {
          listEventSortById.push(
            listEventAndDelivery?.eventAndOrders?.filter(
              (item) => item.id === uniqueEventList[i]
            )[0]
          )
        }
        const listTotalOrderAfterFilter = []
        for (let i = 0; i < listEventSortById?.length; i++) {
          const arrOderAfter = []
          for (let k = 0; k < listRefOfOder.length; k++) {
            arrOderAfter.push(
              listEventSortById[i]?.orders.filter((item) => item.ref === listRefOfOder[k])[0]
            )
          }
          listTotalOrderAfterFilter.push({
            ...listEventSortById[i],
            orders: arrOderAfter.filter((el) => el !== undefined),
          })
        }
        handleExportMultipleEventAndOrder(listTotalOrderAfterFilter, format)
      }
    }

    if (listOrderFiltered?.length) {
      handleFilterListOrder()
    } else {
      addToast('No orders to export', { appearance: 'warning', autoDismiss: true })
    }
  }
  return (
    <Container
      fluid
      id='manage-order-page'
      style={{
        padding: '0 2rem',
      }}
    >
      <Row className='my-3 px-3 align-items-center'>
        <Col>
          <span className='ps-3 fw-bold text-orange fs-2'>Manage Order</span>
        </Col>
        <Col className='d-flex align-items-center text-uppercase justify-content-end'>
          <Button
            role='button'
            className='btn-export rounded-0 ms-3 shadow-none'
            onClick={() => filterBeforeExport(format1)}
            outline
          >
            Export List
          </Button>
          <Button
            role='button'
            className='btn-export rounded-0 ms-3 shadow-none'
            onClick={() => filterBeforeExport(format2)}
            outline
          >
            Export Matrix
          </Button>
        </Col>
      </Row>

      <Row className='align-items-center px-3 mb-3'>
        <Col>
          <OrderSearch
            searchString={searchString}
            onChange={onChangeSearch}
            styles={{ marginTop: 0 }}
          />
        </Col>
        <Col className='d-flex align-items-center'>
          <Pagination
            filter={filter}
            onHandlePrev={handlePrev}
            onHandleNext={handleNext}
            currentOrders={currentOrders}
          />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col>
          <SelectFilter
            filter={filter}
            listEventGroupIdByHost={listEventGroupIdByHost}
            onChangeEvent={handleChangeEvent}
            onChangeEventStatus={handleChangeEventStatus}
            onChangePaymentStatus={handlePaymentStatus}
            onChangeRangeDate={handleChangeDate}
            handleClearFilter={handleClearFilter}
          />
        </Col>
      </Row>
      {currentOrders && !errorGetEventAndOrder ? (
        <>
          <div className='row-top'>
            <div className='d-flex list-event-note align-items-center justify-content-between px-3'>
              <div>
                Click on Names for Order Details and on <i className='icon-link'></i> for Payment
                Details.
              </div>
              {rangeDate[0].startDate && rangeDate[0].endDate && (
                <div className='text-center'>
                  Listing orders from{' '}
                  <strong className='text-orange'>
                    {moment(rangeDate[0].startDate).format('DD/MM/YYYY')}
                  </strong>{' '}
                  to{' '}
                  <strong className='text-orange'>
                    {moment(rangeDate[0].endDate).format('DD/MM/YYYY')}
                  </strong>
                </div>
              )}
            </div>

            {loading ? (
              <div className='text-center mt-5'>
                <Spinner className='mb-2' />
                <p>Loading...</p>
              </div>
            ) : currentOrders && currentOrders.length ? (
              <div className='px-3'>
                <OrderList
                  listOrder={currentOrders}
                  setTimeRender={setTimeRender}
                  setSorting={setSorting}
                  id={id}
                />
              </div>
            ) : null}

            {(() => {
              if (eventId.length > 0) {
                if (eventStatus !== 'default' && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
                if (startDate && endDate && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
                if (paymentStatus.length > 0 && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
                if (!loading && orderList !== undefined && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
              }
            })()}

            {(() => {
              if (eventId.length === 0) {
                if (eventStatus !== 'default' && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
                if (startDate && endDate && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
                if (paymentStatus.length > 0 && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
                if (!loading && orderList !== undefined && listOrderFiltered?.length === 0) {
                  return <p className='fs-6 fw-bold text-center my-5'>Not Found Orders</p>
                }
              }
            })()}
          </div>
        </>
      ) : null}

      {currentOrders === undefined && (
        <div className='text-center mt-5'>
          <Spinner />
        </div>
      )}
    </Container>
  )
}

export default WithErrorBoundary(ManageOrder)
