import React from 'react'
import ListMyPurchases from '_pages/Event/ListMyPurchases'

function ListMyPurchasesPage() {
  return (
    <div className='w-50 mx-auto'>
      <ListMyPurchases />
    </div>
  )
}

export default ListMyPurchasesPage
