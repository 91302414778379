/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react'
import { findNearestDateOfEventCollection } from '_utils/function'
import moment from 'moment'

function ListEventCollection({ listEventCollection }) {
  const [checkIsData, setCheckIsData] = useState(false)
  const [listEventCollectionSorted, setListEventCollectionSorted] = useState([])
  const [eventCollectionNearest, setEventCollectionNeatest] = useState([])
  const [seeMoreEvent, setSeeMoreEvent] = useState()
  const [isShowTextSeeMore, setIsShowTextSeeMore] = useState(false)
  const [countSeeMore, setCountSeeMore] = useState(0)

  useEffect(() => {
    const getCurrentMonth = moment().format('MM')
    if (listEventCollection?.length > 0) {
      const getMonthOfEventClose = moment.unix(listEventCollection[0].pickupTimestamp).format('MM')
      if (getCurrentMonth === getMonthOfEventClose) {
        const getCurrentDate = moment().format('DD-MM-YYYY HH:mm')
        const dateOfEventCollection = []
        listEventCollection?.map((date) => dateOfEventCollection.push(date.pickupTime))
        const listEventCollectionNeatest = findNearestDateOfEventCollection(
          dateOfEventCollection,
          getCurrentDate,
          listEventCollection
        )
        setEventCollectionNeatest([listEventCollectionNeatest[0]])
        setListEventCollectionSorted(listEventCollectionNeatest)
        const convertFinalDateInListEventCollection = moment(
          listEventCollectionNeatest[listEventCollectionNeatest?.length - 1]?.pickupTime,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD')
        const convertCurrentDate = moment().format('YYYY-MM-DD')
        setIsShowTextSeeMore(
          moment(convertFinalDateInListEventCollection).isAfter(convertCurrentDate)
        )
        setCheckIsData(false)
      } else {
        // THIS LOGIC TO CHECK GREATER THAN EVENT CLOSE DATE
        const currentDate = moment().format('YYYY-MM-DD')
        const EventCloseDate = moment
          .unix(listEventCollection[0].pickupTimestamp)
          .format('YYYY-MM-DD')
        const checkCurrentGreaterThanEventCloseDate = moment(currentDate).isAfter(EventCloseDate)
        setEventCollectionNeatest(
          checkCurrentGreaterThanEventCloseDate
            ? [listEventCollection[listEventCollection.length - 1]]
            : [listEventCollection[0]]
        )
        setIsShowTextSeeMore(false)
        setCheckIsData(false)
      }
    } else {
      setCheckIsData(true)
    }
  }, [listEventCollection])

  const handleShowMoreEventCollection = () => {
    const count = countSeeMore + 5
    if (!seeMoreEvent) {
      if (listEventCollectionSorted?.length > count) {
        listEventCollectionSorted?.slice(0, count)
        setEventCollectionNeatest(listEventCollectionSorted?.slice(0, count))
        setCountSeeMore(count)
        setSeeMoreEvent(false)
      } else {
        setEventCollectionNeatest(listEventCollectionSorted)
        setSeeMoreEvent(true)
      }
    } else {
      setEventCollectionNeatest([listEventCollectionSorted[0]])
      setSeeMoreEvent(false)
      setCountSeeMore(0)
    }
  }

  if (checkIsData) {
    return <div style={{ fontSize: '14px' }}> No event collection </div>
  }

  return (
    <div className='row'>
      {eventCollectionNearest?.map((item, i) => (
        <div className='row event__collection-info' key={i}>
          <div className='col-4 '>
            <div className='event__collection-info-day'>
              <div className='event__collection-info-day-text'>
                {moment.unix(item?.pickupTimestamp).format('ddd')}
              </div>
              <div className='event__collection-info-day-number'>
                {moment.unix(item?.pickupTimestamp).format('DD-MM-YYYY').slice(0, 2)}
              </div>
            </div>
          </div>
          <div className='col-8 event__collection-info-detail'>
            <p>Event Collection</p>
            <div className='event__collection-info-detail-name'>
              <span>{item?.title}</span>
            </div>
            <span>Number purchase: {item?.totalOrderCount}</span>
            <span>Total members: {item?.totalMember}</span>
            <span>Total sales: {item?.totalSales}</span>
          </div>
        </div>
      ))}
      <div className='mt-1 mb-1'>{isShowTextSeeMore ? <span className='event-info-see-more' onClick={handleShowMoreEventCollection}>{seeMoreEvent ? 'see less' : 'see more'}</span> : ''}</div>
    </div>
  )
}

export default ListEventCollection
