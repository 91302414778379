import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'
import { getGroupById, updateGroup } from '_redux/modules/group'

import uploadApi from '_api/upload'
import useQuery from '_hooks/useQuery'
import GroupDescription from '../components/GroupDescription'
import GroupName from '../components/GroupName'
import GroupQuestion from '../components/GroupQuestion'
import GroupSetting from '../components/GroupSetting'

import './style.scss'

const UpdateGroup = () => {
  const history = useHistory()
  const params = useQuery()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const idGroupInParam = params.get('id')
  const { myGroups } = useSelector((state) => state.group)
  const [groupInfo, setGroupInfo] = useState(
    myGroups?.filter((group) => group.gid === idGroupInParam || group.id === idGroupInParam)[0]
  )

  const validationSchema = Yup.object().shape({
    groupName: Yup.string('Invalid name').required('Invalid name'),
    groupDescription: Yup.string('Invalid description').required('Invalid description'),
    groupQuestion: Yup.string().nullable().notRequired(),
    passCode: Yup.string().nullable().notRequired(),
  })
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      groupName: groupInfo?.name,
      groupDescription: groupInfo?.description,
      groupQuestion: groupInfo?.question,
      passCode: groupInfo?.passcode,
    },
  })
  const [groupImg, setGroupImg] = useState('')
  const [groupImgUrl, setGroupUrl] = useState(groupInfo?.photoUrl)
  const [groupType, setGroupType] = useState(groupInfo?.public)
  const [defaultRole, setDefaultRole] = useState(groupInfo?.defaultRole)
  const [autoApproval, setAutoApproval] = useState(groupInfo?.autoApproval)
  const [accessMemberList, setAccessMemberList] = useState(groupInfo?.accessMemberList)

  const onGroupImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setGroupUrl(URL.createObjectURL(img))
      setGroupImg(files)
    }
  }

  const onChangeRadio = (state, value) => {
    if (state === 'groupType') setGroupType(value)
    else if (state === 'defaultRole') setDefaultRole(value)
    else if (state === 'accessMemberList') setAccessMemberList(value)
    else setAutoApproval(value)
  }

  const onSubmit = async (data) => {
    const postData = {
      name: data.groupName,
      description: data.groupDescription,
      photoUrl: groupImgUrl,
      passcode: data.passCode,
      question: data.groupQuestion,
      public: groupType,
      defaultRole,
      autoApproval,
      accessMemberList,
    }
    if (groupImg) {
      const { msgResp: res } = await uploadApi.uploadPhoto(groupImg)
      postData.photoUrl = res.url
    }
    try {
      await dispatch(updateGroup({ id: idGroupInParam, postData }))
      addToast('Update group successfully', { appearance: 'success', autoDismiss: true })
      history.push(`/group/list-members?groupId=${idGroupInParam}`)
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    if (!myGroups || myGroups?.length === 0) {
      dispatch(getGroupById(idGroupInParam))
    }

    const groupNeed = myGroups?.filter(
      (group) => group.gid === idGroupInParam || group.id === idGroupInParam
    )[0]

    setGroupInfo(groupNeed)
    setValue('groupName', groupNeed?.name)
    setValue('groupDescription', groupNeed?.description)
    setValue('groupQuestion', groupNeed?.question)
    setValue('passCode', groupNeed?.passcode)
    setGroupUrl(groupNeed?.photoUrl)
    setGroupType(groupNeed?.public)
    setDefaultRole(groupNeed?.defaultRole)
    setAutoApproval(groupNeed?.autoApproval)
    setAccessMemberList(groupNeed?.accessMemberList)
  }, [myGroups])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='update-group-page'>
      <div className='c-form c-form--group mt-2'>
        <div className='row'>
          <div className='col-4'>
            <div className='upload-image'>
              <img
                src={groupImgUrl?.url || groupImgUrl}
                alt=''
                className='w-100 h-100'
                style={{ zIndex: 3, objectFit: 'cover' }}
              />
              <div className='upload-image-txt'>
                <i className='icon-capture'></i>
                <span>
                  Add Profile
                  <br />
                  Picture
                </span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      type='file'
                      accept='image/*'
                      name='file_source_01'
                      size='40'
                      className='inputFile'
                      data-error='#error-file_source_01'
                      onChange={onGroupImageUploaded}
                      style={{ zIndex: '999' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <GroupName register={register('groupName')} error={errors?.groupName} />
            <GroupDescription
              register={register('groupDescription')}
              error={errors?.groupDescription}
            />
          </div>
        </div>
        <div className='box-question'>
          <GroupQuestion register={register('groupQuestion')} error={errors?.groupQuestion} />
          <GroupSetting
            groupType={groupType}
            defaultRole={defaultRole}
            autoApproval={autoApproval}
            accessMemberList={accessMemberList}
            onChangeRadio={onChangeRadio}
            register={register('passCode')}
          />
        </div>
      </div>
      <div className='row row--bot align-self-end mt-3'>
        <div className='col-6 m-auto'>
          <button className='btn btn--main' type='submit' disabled={isSubmitting}>
            {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save'}
          </button>
        </div>
      </div>
    </Form>
  )
}

export default UpdateGroup
