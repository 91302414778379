import axiosClient from './axiosClient'

const deliveryApi = {
  getRememberedDeliveryZones: () => {
    const url = '/get-remembered-delivery-zones'
    return axiosClient.get(url)
  },
  createDeliveryBooking: (data) => {
    const url = '/create-delivery-booking'
    return axiosClient.post(url, data)
  },
  getListDeliveryBookingsByEventId: (eid) => {
    const url = `/list-delivery-bookings-by-event?eid=${eid}`
    return axiosClient.get(url)
  },
  getDeliveryBooking: (ref) => {
    const url = `/get-delivery-booking?ref=${ref}`
    return axiosClient.get(url)
  },
  updateDeliveryBooking: (ref, data) => {
    const url = `/update-delivery-booking?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  // update bookerStatus of Delivery booking
  updateDeliveryBookerStatus: (ref, data) => {
    const url = `/update-delivery-booker-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  listDeliveryByEvent: (eid) => {
    const url = `/list-delivery-bookings-by-event?eid=${eid}`
    return axiosClient.get(url)
  },
  updateDeliveryBookingStatus: (ref, data) => {
    const url = `update-delivery-booking-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  getRememberedDeliveryAddress: () => {
    const url = '/get-remembered-delivery-address'
    return axiosClient.get(url)
  },
  updateRememberedDeliveryZones: (userId, data) => {
    const url = `/update-remembered-delivery-zones?id=${userId}`
    return axiosClient.put(url, data)
  },
  updateRememberedDeliveryAddress: (data) => {
    const url = '/update-remembered-delivery-address'
    return axiosClient.put(url, data)
  },
  listDeliveryByBuyer: (buyerId, groupId) => {
    const url = `list-delivery-bookings-by-buyer?buyerId=${buyerId}`
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  listDeliveryByHost: (hostId, deliveryTime = '') => {
    let url = ''
    if (deliveryTime) {
      url = `/list-delivery-bookings-by-host?hostId=${hostId}&deliveryTime=${deliveryTime}`
    } else {
      url = `/list-delivery-bookings-by-host?hostId=${hostId}`
    }
    return axiosClient.get(url)
  },
  createDeliveryByHost: (data) => {
    const url = '/create-delivery'
    return axiosClient.post(url, data)
  },
  listDeliveryCreatedByHost: (hostId, groupId) => {
    const url = `list-deliveries-by-host-id?hostId=${hostId}`
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getDelivery: (deliveryId, getDeliveryBooking = false) => {
    const url = `/get-delivery?id=${deliveryId}${getDeliveryBooking ? '&getDeliveryBooking=1' : ''}`
    return axiosClient.get(url)
  },
  updateDeliveryStatus: (deliveryId, data) => {
    const url = `/update-delivery?id=${deliveryId}`
    return axiosClient.patch(url, data)
  },
  deleteDelivery: (deliveryId) => {
    const url = `/delete-delivery?id=${deliveryId}`
    return axiosClient.delete(url)
  },
  updateDeliveryByHost: (deliveryId, data) => {
    const url = `/update-delivery?id=${deliveryId}`
    return axiosClient.patch(url, data)
  },
  listScheduledDeliveries: (hostId) => {
    const url = `list-scheduled-deliveries-by-host-id?hostId=${hostId}`
    return axiosClient.get(url)
  },
  deleteScheduledDelivery: (id) => {
    const url = `/delete-scheduled-delivery?id=${id}`
    return axiosClient.delete(url)
  },
  updateScheduledDelivery: (id, data) => {
    const url = `/update-scheduled-delivery?id=${id}`
    return axiosClient.patch(url, data)
  },
}

export default deliveryApi
