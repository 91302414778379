import React from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'

import './style.scss'

function ModalGeneralPayment({
  isShow,
  toggle,
  service,
  onUserImageUploaded,
  paymentFileUrl,
  isSubmittedPayment,
  confirmPayment,
}) {
  return (
    <Modal isOpen={isShow} toggle={toggle} centered>
      <ModalHeader className='modal-header-created border-bottom-0' style={{ margin: '0 auto' }}>
        <span className='c-txt-gra'>{service?.type}</span>
      </ModalHeader>
      <ModalBody className='border-top' style={{ fontSize: 16 }}>
        <h3 className='c-ttl-blue' style={{ fontSize: '18px' }}>
          <strong>Step 1:</strong> Please transfer to payment
          <span style={{ color: '#51aaaa' }}></span>
        </h3>
        <div className='info-account-bank mb-3'>
          <p>
            Bank Name: <b>Singapore Bank</b>
          </p>
          <p>
            Account Number: <b>102868402852</b>
          </p>
          <p>
            Price: <b> ${service?.price?.toFixed(2)}</b>
          </p>
        </div>
        <h3 className='c-ttl-blue '>
          <strong>Step 2: </strong>
          Attach PayNow screenshot (Do not attach SMS)
        </h3>
        <div className='c-form'>
          <div className='upload-image upload-screenshot'>
            <img
              style={
                paymentFileUrl
                  ? { height: '100%', width: '100%', objectFit: 'contain' }
                  : { display: 'none' }
              }
              id='eventImageImg'
              src={paymentFileUrl}
              alt=''
            />
            <div className='upload-image-txt' style={paymentFileUrl ? { opacity: 0 } : {}}>
              <i className='icon-capture'></i>
              <span>Screenshot</span>
            </div>
            <div className='u-file-upload__item'>
              <div className='u-file-upload__wrap'>
                <a className='c-upload preview-images-zone'>
                  <input
                    id='eventImageInput'
                    type='file'
                    accept='image/*'
                    name='file_source_01'
                    size='40'
                    className='inputFile'
                    data-error='#error-file_source_01'
                    onChange={onUserImageUploaded}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <button className='btn btn--main' onClick={confirmPayment} disabled={isSubmittedPayment}>
          {isSubmittedPayment ? <Spinner color='light' size='sm' /> : 'CONFIRM PAYMENT'}
        </button>
      </ModalBody>
    </Modal>
  )
}

export default ModalGeneralPayment
