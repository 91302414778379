import { isNull } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function Summary() {
  const { addToast } = useToasts()
  const { summaryData, errorGetSummary } = useSelector((state) => state.dashBoard)

  const summary = useMemo(() => summaryData?.summary, [summaryData])

  if (errorGetSummary) {
    addToast(errorGetSummary, { appearance: 'error', autoDismiss: true })
  }

  const convertDate = (date) => {
    if (date) {
      const newDate = date.split('-')
      return `${newDate[1]}-${newDate[0]}-${newDate[2]}`
    }
  }

  return (
    <div className='summary row text-center'>
      <div className='col-6'>
        <div className='rounded-3 border-custom p-2'>
          <p className='mb-0 text-show'>{summary?.members || 0}</p>
          <span>Members</span>
        </div>
      </div>
      <div className='col-6'>
        <div className='rounded-3 border-custom p-2'>
          <p className='mb-0 text-show'>{summary?.totalTransactionValue || 0}</p>
          <span>Transactions</span>
        </div>
      </div>
      <div className='w-100 mb-2'></div>
      <div className='col-6'>
        <div className='rounded-3 border-custom p-2'>
          <p className='mb-0 text-show'>{summary?.onGoingEvents || 0}</p>
          <span>On-going Event</span>
        </div>
      </div>
      <div className='col-6'>
        <div className='rounded-3 border-custom p-2'>
          <p className='mb-0 text-show'>{summary?.closedEvents || 0}</p>
          <span>Closed Events</span>
        </div>
      </div>
      <div className='w-100 mb-2'></div>
      <div className='col-6'>
        <div className='rounded-3 border-custom p-2'>
          <p className='mb-0 text-show'>{summary?.unPaidOrder || 0}</p>
          <span>Unpaid</span>
        </div>
      </div>
      <div className='col-6'>
        <div className='rounded-3 border-custom p-2'>
          <p className='mb-0 text-show'>
            {isNull(summary?.nextCollection) && '-'}
            {summary?.nextCollection
              ? moment(convertDate(summary?.nextCollection)?.slice(0, 10)).format('DD MMM YYYY')
              : '-'}
          </p>
          <span>Next Collection</span>
        </div>
      </div>
    </div>
  )
}

export default Summary
