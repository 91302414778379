/* eslint-disable no-shadow */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import deliveryApi from '_api/delivery'
import orderApi from '_api/order'
import { updateDeliveryCombinedPayment, updateDeliveryPaymentStatus } from '_redux/modules/delivery'
import { updateOrderCombinedPayment, getListCombinedPayment } from '_redux/modules/event'
import { handleDataOrder } from '_utils/function'

import {
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_BUYER_PAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_UNPAID,
  DELIVERY_HOST_PAID,
} from '_utils/constant'

import ModalWarning from '../components/ModalWarning'
import ModalMessage from '../components/ModalMessage'
import ModalPaymentScreen from '../components/ModalPaymentScreen'
import ModalDetailDelivery from '../components/ModalDetailDelivery'

export default function DeliveryList({
  listDelivery,
  setTimeReRender,
  deliveryId,
}) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const data = listDelivery.sort((a, b) => a.uName.localeCompare(b.uName))

  const { eventAndOrderById, listCombinedPayments } = useSelector((state) => state.event)

  const [modalMessage, setModalMessage] = useState(false)
  const [modalPaymentScreen, setModalPaymentScreen] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetail, setDataModalDetail] = useState({})

  const [loading, setLoading] = useState(true)

  const initListDelivery = async (_data) => {
    const listCombinedPaymentRef = []
    setLoading(true)
    _data.forEach(async (element) => {
      const { combinedPaymentRef = '' } = element
      if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
        if (combinedPaymentRef) {
          listCombinedPaymentRef.push(combinedPaymentRef)
        }
      }
    })
    if (listCombinedPaymentRef.length) {
      await dispatch(getListCombinedPayment(listCombinedPaymentRef))
    }
    setLoading(false)
  }

  useEffect(() => {
    initListDelivery(listDelivery)
  }, [])

  const toggleMessage = () => setModalMessage(!modalMessage)

  const togglePaymentScreen = () => setModalPaymentScreen(!modalPaymentScreen)

  const toggleDetail = () => {
    setModalDetail(!modalDetail)
  }

  const toggleWarning = () => setModalWarning(!modalWarning)

  const updateDeliveryBookingStatus = async (_ref, _data) => {
    dispatch(updateDeliveryPaymentStatus(
      deliveryId, _ref, _data.status, _data.bookerStatus
    ))
    await deliveryApi.updateDeliveryBookingStatus(_ref, _data)
    setTimeReRender((prevState) => prevState + 1)
  }

  const updateDeliveryBookerStatus = async (_ref, _data) => {
    dispatch(updateDeliveryPaymentStatus(
      deliveryId, _ref, _data.status, _data.bookerStatus
    ))
    await deliveryApi.updateDeliveryBookerStatus(_ref, { bookerStatus: _data.bookerStatus })
    setTimeReRender((prevState) => prevState + 1)
  }

  const updateDeliveryCombinedPaymentAPI = async (_data) => {
    dispatch(updateDeliveryCombinedPayment(_data.combinedPaymentRef,
      _data.status, _data.placerStatus))
    dispatch(updateOrderCombinedPayment(_data.combinedPaymentRef,
      _data.status, _data.placerStatus))
    await orderApi.updateInvoicesStatus(_data)
    setTimeReRender((prevState) => prevState + 1)
  }

  const onChangeSelect = async (e, _ref, _status, _bookerStatus, _combinedPaymentRef) => {
    try {
      if (_status === DELIVERY_HOST_UNPAID && Number(e.target.value) === DELIVERY_HOST_PAID) {
        const _data = { status: Number(e.target.value), bookerStatus: DELIVERY_BUYER_PAID }
        await updateDeliveryBookingStatus(_ref, _data)
      } else if (_status !== DELIVERY_HOST_CANCELLED
        && Number(e.target.value) === DELIVERY_HOST_CANCELLED
        && _bookerStatus > DELIVERY_BUYER_UNPAID) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: Number(e.target.value),
            placerStatus: DELIVERY_BUYER_REFUND
          }
          await updateDeliveryCombinedPaymentAPI(_data)
        } else {
          const _data = { status: Number(e.target.value), bookerStatus: DELIVERY_BUYER_REFUND }
          await updateDeliveryBookingStatus(_ref, _data)
        }
      } else if (_status === DELIVERY_HOST_CANCELLED
        && Number(e.target.value) !== DELIVERY_HOST_CANCELLED
        && _bookerStatus === DELIVERY_BUYER_REFUND) {
        const _data = { status: Number(e.target.value), bookerStatus: DELIVERY_BUYER_PAID }
        await updateDeliveryBookingStatus(_ref, _data)
      } else {
        const _data = { status: Number(e.target.value), bookerStatus: _bookerStatus }
        await updateDeliveryBookingStatus(_ref, _data)
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      setTimeReRender((prevState) => prevState + 1)
    }
  }

  const openMessageModal = (e, delivery) => {
    e.preventDefault()
    setDataModal(delivery)
    toggleMessage()
  }

  const openPaymentScreen = (e, delivery) => {
    e.preventDefault()

    const { combinedPaymentRef } = delivery
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef) {
      const exitCombinedPayment = listCombinedPayments
        ?.findIndex((item) => item.combinedPaymentRef === combinedPaymentRef)

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment }
          = listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment

          .forEach((element) => {
            const { eid, ref: elementRef } = element
            const { orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList } = eventAndOrderById.find((item) => item.id === eid) || {}
            const { listOrder: otherListOrder } =
              handleDataOrder(
                orders,
                adminCost,
                discount,
                deliveryCost,
                productIdList
              )
            const otherOrder = otherListOrder.find((item) => item.ref === elementRef)
            listCombinedOrder = [...listCombinedOrder, otherOrder]
          })
        listCombinedDelivery = [...deliveryCombinedPayment]
      }
    }

    setDataModal({ ...delivery, listCombinedOrder, listCombinedDelivery })
    togglePaymentScreen()
  }

  const handleInvalidPayment = async (_ref, _status, _combinedPaymentRef) => {
    try {
      const r = confirm('Are you sure this is invalid payment?')
      if (r === true) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: DELIVERY_HOST_UNPAID,
            placerStatus: DELIVERY_BUYER_UNPAID
          }
          await updateDeliveryCombinedPaymentAPI(_data)
        } else {
          const _data = { status: _status, bookerStatus: DELIVERY_BUYER_UNPAID }
          await updateDeliveryBookerStatus(_ref, _data)
        }
        togglePaymentScreen()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      togglePaymentScreen()
    }
  }

  const handleShowDetail = (e, delivery) => {
    e.preventDefault()
    setDataModalDetail(delivery)
    toggleDetail()
  }

  const handleCancel = async (e, _ref, _status, _bookerStatus, _combinedPaymentRef) => {
    e.preventDefault()
    try {
      if (_status !== DELIVERY_HOST_CANCELLED
        && _bookerStatus > DELIVERY_BUYER_UNPAID) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: DELIVERY_HOST_CANCELLED,
            placerStatus: DELIVERY_BUYER_REFUND
          }
          await updateDeliveryCombinedPaymentAPI(_data)
        } else {
          const _data = { status: DELIVERY_HOST_CANCELLED, bookerStatus: DELIVERY_BUYER_REFUND }
          await updateDeliveryBookingStatus(_ref, _data)
        }
        toggleDetail()
        toggleWarning()
      } else {
        const _data = { status: DELIVERY_HOST_CANCELLED, bookerStatus: _bookerStatus }
        await updateDeliveryBookingStatus(_ref, _data)
        toggleDetail()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetail()
      toggleWarning()
    }
  }

  const handleOpenWarning = (e, delivery) => {
    e.preventDefault()
    setDataModal(delivery)
    toggleWarning()
  }

  return (
    <div className='list-event'>
      <>
        {loading ? <div>Loading...</div> : null}
        {!loading && data && data.length > 0
          ? data.map((delivery, index) => (
            <div key={index}>
              <div className='list-event__item' style={index === 0 ? { borderTop: '1px solid rgba(134, 131, 132, 0.9)' } : {}}>
                <div className='row'>
                  <div className='col-3'>
                    <h4>
                      <a href='#' onClick={(e) => handleShowDetail(e, delivery)}>
                        {delivery.uName}
                      </a>
                    </h4>
                    {delivery.comment && (
                      <a href='#' onClick={(e) => openMessageModal(e, delivery)}>
                        <i className='icon-new'></i>
                      </a>
                    )}
                  </div>
                  <div className='col-4 text-center'>
                    ${delivery.dzPrice.toFixed(2)}
                    {delivery.bookerStatus === DELIVERY_BUYER_REFUND
                      ? ' (Refunded)'
                      : ''}
                  </div>
                  <div className='col-2 text-center'>
                    {delivery.bookerStatus === DELIVERY_BUYER_UNPAID && <span className='n-paid' style={{ paddingRight: 0 }}>Not Paid</span>}
                    {delivery.bookerStatus === DELIVERY_BUYER_PAID && (
                      <a
                        href='#'
                        className={delivery?.combinedPaymentRef ? 'paid-combined' : 'paid'}
                        data-toggle='modal'
                        data-target='#modal-paid'
                        onClick={(e) => openPaymentScreen(e, delivery)}
                      >
                        Paid
                      </a>
                    )}
                    {delivery.bookerStatus === DELIVERY_HOST_CANCELLED && <span className=''>Cancelled</span>}
                    {delivery.bookerStatus === DELIVERY_BUYER_REFUND && (
                      <span className=''>Refunded</span>
                    )}
                  </div>
                  <div className='col-3'>
                    <select
                      id='paymentStatus_0'
                      className='form-control-payment'
                      value={delivery.status}
                      onChange={(e) =>
                        onChangeSelect(
                          e,
                          delivery.ref,
                          delivery.status,
                          delivery.bookerStatus,
                          delivery.combinedPaymentRef
                        )}
                      style={
                        delivery.status === DELIVERY_HOST_UNPAID
                          ? { color: '#ff6903' }
                          : delivery.status === DELIVERY_HOST_PAID
                            ? { color: '#51aaaa' }
                            : { color: '#7c797a' }
                      }
                    >
                      <option value={DELIVERY_HOST_CANCELLED}>Cancelled</option>
                      <option value={DELIVERY_HOST_UNPAID}>Unpaid</option>
                      <option value={DELIVERY_HOST_PAID}>Verified</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ))
          : null}
      </>
      {Object.keys(dataModal).length !== 0 && (
        <ModalMessage data={dataModal} modalMessage={modalMessage} toggleMessage={toggleMessage} />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalPaymentScreen
          data={dataModal}
          modalPaymentScreen={modalPaymentScreen}
          togglePaymentScreen={togglePaymentScreen}
          handleInvalidPayment={handleInvalidPayment}
        />
      )}
      {Object.keys(dataModal).length !== 0 && (
        <ModalWarning
          data={dataModal}
          title='delivery'
          modalWarning={modalWarning}
          toggleWarning={toggleWarning}
          handleCancel={handleCancel}
        />
      )}
      {Object.keys(dataModalDetail).length !== 0 && (
        <ModalDetailDelivery
          data={dataModalDetail}
          modalDetail={modalDetail}
          toggleDetail={toggleDetail}
          handleOpenWarning={handleOpenWarning}
          setTimeReRender={setTimeReRender}
          deliveryId={deliveryId}
        />
      )}
    </div>
  )
}
