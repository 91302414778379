/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import moment from 'moment'

import { getMyEvents, getScheduledEvent } from '_redux/modules/event'
import {
  getListDeliveryCreatedByHost,
  callNewDeliveryCreatedByHost,
  getScheduledDelivery
} from '_redux/modules/delivery'
import { getMyGroups } from '_redux/modules/group'
import deliveryApi from '_api/delivery'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { sortListEventsByPickupTimestamp, convertToCurrentGMT, convertToGMT0, convertTimeStringWithDuration } from '_utils/function'
import {
  GROUP_IS_HOST,
  DEFAULT_DELIVERY_OPTION,
  LIST_TIME
} from '_utils/constant'
import {
  getUserInfo,
} from '_utils/localData'

import './style.scss'
import MyScheduledEvent from '../components/MyScheduledEvent'
import DeliveryOptionsModal from '../components/DeliveryOptionsModal'
import BoxDeliveryDateByHost from '../components/BoxDeliveryDateByHost'
import MyEventItem from '../components/MyEventItem'
import MyScheduledDelivery from '../components/MyScheduleDelivery'

const now = moment().unix()

const MyEventList = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const userInfo = getUserInfo()
  const { id: userId } = userInfo

  const { myEvents, allScheduleEvent } = useSelector((state) => state.event)
  const { listDeliveryCreatedByHost, allScheduleDelivery } = useSelector((state) => state.delivery)

  let { listDeliveryCreated = null } =
    listDeliveryCreatedByHost.find((item) => item.hostId === userId) || {}

  listDeliveryCreated = listDeliveryCreated ?
    listDeliveryCreated
      .sort((a, b) => a.deliveryTimestamp - b.deliveryTimestamp)
    : null

  const { listScheduleDelivery = null }
    = allScheduleDelivery.find((item) => item.hostId === userId) || {}

  const { myGroups } = useSelector((state) => state.group)

  const [isLoading, setIsLoading] = useState(false)

  const [textBtn, setTextBtn] = useState('COPY')
  const [shareLink, setShareLink] = useState('')
  const [modal, setModal] = useState(false)
  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [timeGetDeliveryByHost, setTimeGetDeliveryByHost] = useState(0)
  const [timeGetScheduleEvent, setTimeGetScheduleEvent] = useState(0)
  const [timeGetScheduleDelivery, setTimeGetScheduleDelivery] = useState(0)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const toggle = () => setModal(!modal)

  const [deliveryZones, setDeliveryZones] = useState([])

  const [deliveryHourStart, setDeliveryHourStart] = useState('10AM')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('1PM')

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  const isCloseEvent = (pickupTimestamp) => {
    if (pickupTimestamp < now) {
      return true
    }
    return false
  }

  useEffect(() => {
    const getEvents = async () => {
      setIsLoading(true)
      try {
        await dispatch(getMyEvents)
        await dispatch(getScheduledEvent)
        await dispatch(getMyGroups)
        await dispatch(getListDeliveryCreatedByHost(userId))
        await dispatch(getScheduledDelivery(userId))
      } catch (error) {
        addToast(error?.message, { appearance: 'error', autoDismiss: true })
      }
      setIsLoading(false)
    }

    getEvents()
  }, [])

  const [optionGroup, setOptionGroup] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')

  const [deliveryTime, setDeliveryTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  )

  useEffect(() => {
    if (myGroups && myGroups.length) {
      const hostForGroup = myGroups?.filter((group) => group.isHost === GROUP_IS_HOST)
      setOptionGroup([...hostForGroup])
      setSelectedGroup(hostForGroup[0]?.gid || hostForGroup[0]?.id)
    }
  }, [myGroups])

  useEffect(() => {
    dispatch(getListDeliveryCreatedByHost(userId))
  }, [timeGetDeliveryByHost])

  useEffect(() => {
    dispatch(getScheduledDelivery(userId))
  }, [timeGetScheduleDelivery])

  useEffect(() => {
    dispatch(getScheduledEvent)
  }, [timeGetScheduleEvent])

  const handleOnClickOk = async () => {
    setIsSubmitting(true)
    const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
    const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
    const timeStart = LIST_TIME[deliveryStartIndex].value
    const timeEnd = LIST_TIME[deliveryEndIndex].value
    const deliveryDuration = (timeEnd - timeStart) * 3600
    if (deliveryDuration < 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      setIsSubmitting(false)
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    const deliveryTimeLocal = `${moment(deliveryTime).format('DD-MM-YYYY')} ${timeStart}:00`

    const deliveryTimestamp = moment(deliveryTimeLocal, 'DD-MM-YYYY HH:mm').unix()

    if (deliveryTimestamp < now) {
      setIsSubmitting(false)
      addToast('Delivery time must after current time', { appearance: 'error', autoDismiss: true })
      return
    }

    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    try {
      await deliveryApi.createDeliveryByHost({
        groupId: selectedGroup,
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration
      })

      dispatch(callNewDeliveryCreatedByHost())
      setTimeGetDeliveryByHost((prevState) => prevState + 1)

      await deliveryApi.updateRememberedDeliveryZones(userId, {
        rememberedDeliveryZones,
      })
      addToast('Added delivery', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      return
    }
    setIsSubmitting(false)
    toggleModalAddDelivery()
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryTime(e)
  }

  const onChangeZoneDelivery = (index, type, value) => {
    deliveryZones[index][type] = value
    setDeliveryZones([...deliveryZones])
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const handleOnRemoveDeliveryZone = (index) => {
    deliveryZones.splice(index, 1)
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
  }

  const onOpenAddDelivery = async () => {
    const { msgResp } = await deliveryApi.getRememberedDeliveryZones()
    if (msgResp.length > 0) {
      setDeliveryZones(msgResp)
    } else {
      setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
    }
    toggleModalAddDelivery()
  }

  return (
    <div className='row-top my-events-page'>
      {isLoading && <div>Loading...</div>}
      {!isLoading ? <>
        <div className='text-add-delivery d-flex flex-row-reverse mt-2 mb-2'
          onClick={onOpenAddDelivery}
        >Add delivery</div>

        {listDeliveryCreated && listDeliveryCreated.length ?
          listDeliveryCreated
            .filter((ele) => ele.deliveryTimestamp >= now)
            .map((item, idex) => (
              <BoxDeliveryDateByHost
                key={idex}
                createdUserPhotoUrl={item.createdUserPhotoUrl}
                createdUserName={item.createdUserName}
                groupName={item.groupName}
                deliveryTimeString={
                  convertTimeStringWithDuration(item.deliveryTime, item.deliveryDuration)}
                deliveryTime={convertToCurrentGMT(item.deliveryTime)}
                deliveryId={item.id}
              />
            )) : null}

        {sortListEventsByPickupTimestamp(myEvents)
          ?.filter((item) => !isCloseEvent(item.pickupTimestamp))
          .map((event, index) => (
            <MyEventItem event={event} openModal={toggle} setShareLink={setShareLink} key={index} />
          ))}

        {listScheduleDelivery?.map((delivery, index) => (
          <MyScheduledDelivery
            key={index}
            delivery={delivery}
            hostId={userId}
            setTimeGetScheduleDelivery={setTimeGetScheduleDelivery}
          />
        ))}

        {allScheduleEvent?.map((event, index) => (
          <MyScheduledEvent
            event={event}
            openModal={toggle}
            setShareLink={setShareLink}
            isScheduledEvent={true}
            key={index}
            setTimeGetScheduleEvent={setTimeGetScheduleEvent}
          />
        ))}

        {listDeliveryCreated && listDeliveryCreated.length ? listDeliveryCreated
          .filter((ele) => ele.deliveryTimestamp < now)
          .map((item, idex) => (
            <BoxDeliveryDateByHost
              key={idex}
              createdUserPhotoUrl={item.createdUserPhotoUrl}
              createdUserName={item.createdUserName}
              groupName={item.groupName}
              deliveryTimeString={
                convertTimeStringWithDuration(item.deliveryTime, item.deliveryDuration)}
              deliveryTime={convertToCurrentGMT(item.deliveryTime)}
              deliveryId={item.id}
              greyOut={true}
            />
          )) : null}

        {sortListEventsByPickupTimestamp(myEvents)
          ?.filter((item) => isCloseEvent(item.pickupTimestamp))
          .map((event, index) => (
            <MyEventItem event={event} openModal={toggle} setShareLink={setShareLink} key={index} />
          ))}

        {myEvents.length === 0 && !isLoading && <div>You dont have any event!!!</div>}

        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalHeader className='modal-header-created'>
            <span className='c-txt-gray'>Share Link</span>
          </ModalHeader>
          <ModalBody>
            <div className='c-form'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control form-control--radius shareLink'
                  value={shareLink}
                  readOnly
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='modal-footer-created'>
            <div className='col-12'>
              <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
                {textBtn}
              </button>
            </div>
            <div className='col-12 mt-2'>
              <button className='btn btn--solid btn--solid04' onClick={toggle}>
                CLOSE
              </button>
            </div>
          </ModalFooter>
        </Modal>

        {openModalAddDelivery && <DeliveryOptionsModal
          modal={openModalAddDelivery}
          toggle={toggleModalAddDelivery}
          handleOnClickOk={handleOnClickOk}
          deliveryZones={deliveryZones}
          deliveryTime={deliveryTime}
          onChangeZoneDelivery={onChangeZoneDelivery}
          onChangeTimeDelivery={onChangeTimeDelivery}
          handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
          handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
          deliveryHourStart={deliveryHourStart}
          setDeliveryHourStart={setDeliveryHourStart}
          deliveryHourEnd={deliveryHourEnd}
          setDeliveryHourEnd={setDeliveryHourEnd}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          optionGroup={optionGroup}
          isSubmitting={isSubmitting}
        />}
      </> : null}

    </div>
  )
}

export default MyEventList
