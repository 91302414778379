/* eslint-disable max-len */
import moment from 'moment'
import axiosClient from './axiosClient'

const eventApi = {
  getListEventsOpening: (groupId) => {
    const url = 'list-events-opening'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getEventDetail: ({
    eid,
    isGetProducts = true,
    isGetBuyerNames = false,
    isGetDeliveryZones = false,
    isByAdmin = false,
  }) => {
    if (!eid) return null

    const params = new URLSearchParams()
    params.append('id', eid)
    if (isGetProducts) params.append('getProducts', '1')
    if (isGetBuyerNames) params.append('getBuyerNames', '1')
    if (isGetDeliveryZones) params.append('getDeliveryZones', '1')

    const endPoint = isByAdmin ? '/get-event-by-admin' : '/get-event'

    const url = `${endPoint}?${params.toString()}`
    return axiosClient.get(url)
  },
  getListEventsByGroupId: (groupId, date) => {
    let url = `/list-events-by-group?groupId=${groupId}`
    if (date) {
      url = `/list-events-by-group?groupId=${groupId}&date=${moment(date).format(
        'DD-MM-YYYY'
      )} 00:00`
    }
    return axiosClient.get(url)
  },
  getListMyEvents: (groupId) => {
    const url = '/list-my-events'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getShareEventCode: (eventId) => {
    const url = `/update-event-share?id=${eventId}`
    return axiosClient.patch(url)
  },
  getEventById: (id) => {
    const url = `/get-event?id=${id}&getProducts=1&getDeliveryZones=1`
    return axiosClient.get(url)
  },
  updateEventStatus: (id, data) => {
    const url = `/update-event-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  getEvent: (id) => {
    const url = `/get-event?id=${id}&getProduct=1`
    return axiosClient.get(url)
  },
  createEvent: (data) => {
    const url = '/create-event'
    return axiosClient.post(url, data)
  },
  updateEvent: (data) => {
    const url = `/update-event?id=${data.id}`
    return axiosClient.patch(url, data)
  },
  deleteEvent: (id) => {
    const url = `/delete-event?id=${id}&getProducts=1`
    return axiosClient.delete(url)
  },
  updateEventDiscount: (id, data) => {
    const url = `/update-event?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventDelivery: (id, data) => {
    const url = `/update-event?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventRequestPayment: (id, data) => {
    const url = `/update-event?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventRequestPaymentAndNotify: (id, data) => {
    const url = `/request-payment?eid=${id}`
    return axiosClient.patch(url, data)
  },
  getShareEvent: ({ eventId, shareCode }) => {
    const url = `/get-share-event?id=${eventId}&shareCode=${shareCode}`
    return axiosClient.get(url)
  },
  createScheduledEvent: (data) => {
    const url = '/create-scheduled-event'
    return axiosClient.post(url, data)
  },
  updateScheduledEvent: (data) => {
    const url = `/update-scheduled-event?id=${data?.id}`
    return axiosClient.patch(url, data)
  },
  getScheduledEvent: (id) => {
    const url = `/get-scheduled-event?id=${id}&getProducts=1`
    return axiosClient.get(url)
  },
  listMyScheduledEvents: (groupId) => {
    const url = '/list-my-scheduled-events'
    if (groupId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['group-id'] = groupId
        return config
      })
    }
    return axiosClient.get(url)
  },
  listInvoicesCombinedPayment: (combinedPaymentRef) => {
    const url = `/list-invoices-combined-payment-ref?combinedPaymentRef=${combinedPaymentRef}`
    return axiosClient.get(url)
  },
  deleteScheduledEvent: (id) => {
    const url = `/delete-scheduled-event?id=${id}`
    return axiosClient.delete(url)
  },
  getEventsClosePerMonthByHost: (data, groupId) => {
    const url = `/list-event-close-per-month-by-host?month=${data.month}&year=${data.year}&groupId=${groupId}`
    return axiosClient.get(url)
  },
  getEventsCollectionPerMonthByHost: (data, groupId) => {
    const url = `/list-event-collection-per-month-by-host?month=${data.month}&year=${data.year}&groupId=${groupId}`
    return axiosClient.get(url)
  },
  getListEventAndDelivery: (groupId) => {
    const url = `/get-list-orders-and-deliveries-by-host?groupId=${groupId}`
    return axiosClient.get(url)
  },
  getListEventWithoutAuth: (take, page) => {
    const url = `/list-events-opening-unauth?take=${take}&page=${page}`
    return axiosClient.get(url)
  },
  getAllEvents: ({ eventQty, isNext, timestamp, status }) => {
    const url = '/get-all-event'
    return axiosClient.get(url, {
      params: {
        eventQty,
        isNext,
        timestamp,
        status,
      },
    })
  },
}

export default eventApi
