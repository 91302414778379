import React from 'react'
import UserPage from '../../pages/User'

function UserProfile() {
  return (
    <div className='user__profile-desktop' style={{ width: '800px', margin: '0 auto' }}>
      <UserPage />
    </div>
  );
}

export default UserProfile
