import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import reportApi from '_api/report'
import userApi from '_api/user'
import useData from '_hooks/useData'
import Image from '_images/img_welcome.svg'
import { getUserInfo, setUserInfo } from '_utils/localData'

import Header from '_components/Header'
// import './style.scss'

function Welcome() {
  useData()
  const user = getUserInfo()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!user) {
      userApi
        .getUser()
        .then(({ msgResp }) => {
          setUserInfo(msgResp)
          setLoading(false)
        })
        .catch(({ msgResp, msgCode }) => {
          reportApi.report({ message: `${msgCode} - ${msgResp}` })
        })
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className='welcome'>
      <Header />
      <section className='section-content section-content--top mains-screen' id='checkHeight'>
        <div className='container'>
          <div className='row-top welcome-page'>
            <h3 className='c-txt-25 text-center c-txt-org mt-3'>
              <strong>Welcome to Co-bee</strong>
            </h3>
            <div className='box-welcome'>
              <picture>
                <img src={Image} />
              </picture>
              <div className='text-center'>
                <h4>Currently there is no deals hosted in your community</h4>
                <p>
                  In the meantime, <Link to='/user/profile'>leave a feedback</Link> on how we can
                  make Co-bee better
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Welcome
