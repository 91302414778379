import React, { useContext, useEffect, useState } from 'react'
import { SelectIdGroupContext } from '../../../context/SelectGroupContext'
import { DESKTOP } from '../../../utils/constant'

const EventSelectGroup = (props) => {
  const { register, options, disabled, selected, error } = props

  const getDevice = localStorage.getItem('DEVICE_USER')

  return (
    <div className='c-form mt-2'>
      <div className='form-group form-group--select'>
        {(() => {
          if (getDevice === DESKTOP) {
            const context = useContext(SelectIdGroupContext)
            const { groupId } = context?.infoGroupSelected
            const [gid, setGid] = useState('')

            useEffect(() => {
              if (groupId !== undefined) {
                setGid(groupId)
              }
            }, [groupId])

            const handleChangeGroup = ({ value }) => {
              setGid(value)
            }
            return (
              <select
                className='form-control form-control--height'
                {...register}
                value={gid}
                onChange={(e) => handleChangeGroup(e.target)}
                disabled={disabled}
              >
                {options?.map((group, index) => (
                  <option value={group.gid} key={index} defaultValue={selected === group.gid}>
                    {group.name}
                  </option>
                ))}
              </select>
            )
          }

          return (
            <select className='form-control form-control--height' {...register} disabled={disabled}>
              {options?.map((group, index) => (
                <option value={group.gid} key={index} defaultValue={selected === group.gid}>
                  {group.name}
                </option>
              ))}
            </select>
          )
        })()}
        <small className='errorMessage'>{error}</small>
      </div>
    </div>
  )
}

export default EventSelectGroup
