import { useContext, useEffect } from 'react'
import { PHONE } from '_utils/constant'
import { setDeviceUser } from '_utils/localData'
import { DeviceUserContext } from '../../../context/DeviceUserContext'

function SwitchMobileModel() {
  const { setCheckDeviceUser } = useContext(DeviceUserContext)
  useEffect(() => {
    setDeviceUser(PHONE)
    setCheckDeviceUser(PHONE)
    return () => {
      setDeviceUser()
      setCheckDeviceUser()
    }
  }, [])

  return <></>
}

export default SwitchMobileModel
