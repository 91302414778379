import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { callNewListOrder } from '_redux/modules/event'
import { clearMyOrder, clearDataPurchaseDetail } from '_redux/modules/order'

import eventApi from '_api/event'
import {
  PAYABLE,
  EVENT_CLOSE,
  NOT_PAYABLE
} from '_utils/constant'
import ModalRequestPayment from '../components/ModalRequestPayment'

export default function PayLater({ setTimeRender, isBuyerPaid,
  id, deliveryCost, discount, payable, status, eventAndOrderById }) {
  const [saveMessageDelivery, setSaveMessageDelivery] = useState('')
  const [saveMessageDicount, setSaveMessageDicount] = useState('')
  const [deliveryValue, setDeliveryValue] = useState(deliveryCost)
  const [discountValue, setDiscountValue] = useState(discount)
  const [modalRequest, setModalRequest] = useState(false)
  const toggleRequest = () => setModalRequest(!modalRequest)
  const { addToast } = useToasts()
  const { orders } = eventAndOrderById || {}
  const dispatch = useDispatch()
  const onDeliveryCostUpdated = async (e) => {
    try {
      dispatch(clearDataPurchaseDetail())
      dispatch(clearMyOrder())
      await eventApi.updateEventDelivery(id, { deliveryCost: Number(e.target.value) })
      dispatch(callNewListOrder())
      setTimeRender((prevState) => prevState + 1)
      setSaveMessageDelivery('SAVED')
    } catch (error) {
      const { msgResp } = error
      setSaveMessageDelivery(msgResp)
    }
  }
  const onDeliveryCostChange = (e) => {
    setDeliveryValue(e.target.value)
  }
  const onDiscountUpdated = async (e) => {
    try {
      dispatch(clearDataPurchaseDetail())
      dispatch(clearMyOrder())
      await eventApi.updateEventDiscount(id, { discount: Number(e.target.value) })
      dispatch(callNewListOrder())
      setTimeRender((prevState) => prevState + 1)
      setSaveMessageDicount('SAVED')
    } catch (error) {
      const { msgResp } = error
      setSaveMessageDicount(msgResp)
    }
  }
  const onDiscountChange = (e) => {
    setDiscountValue(e.target.value)
  }
  useEffect(() => {
    setTimeout(() => setSaveMessageDelivery(''), 3000)
  }, [saveMessageDelivery])
  useEffect(() => {
    setTimeout(() => setSaveMessageDicount(''), 3000)
  }, [saveMessageDicount])
  const onControlPaymentClicked = async () => {
    if (status === EVENT_CLOSE) {
      if (payable === NOT_PAYABLE) {
        toggleRequest()
      } else {
        try {
          dispatch(clearDataPurchaseDetail())
          dispatch(clearMyOrder())
          await eventApi.updateEventRequestPayment(id, {
            payable: payable === 1 ? 0 : 1
          })
          dispatch(callNewListOrder())
          setTimeRender((prevState) => prevState + 1)
        } catch (error) {
          const { msgResp } = error
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
        }
      }
    } else {
      alert('Request payment when event is closed')
    }
  }
  return (
    <div id='paylaterHtml' className='box-deleviry'>
      <div className='row align-items-center'>
        <div className='col-7'>
          <div className='row'>
            <div className='col-6'>
              <label>Delivery Fee ($)</label>
              <div className='ipt-wrap'>
                <input
                  disabled={isBuyerPaid}
                  value={deliveryValue}
                  type='text'
                  className='form-control'
                  onChange={onDeliveryCostChange}
                  onBlur={onDeliveryCostUpdated}
                />
                {saveMessageDelivery && <span
                  id='deliveryCostSaved'
                  style={{ fontWeight: '100', color: 'green', padding: 0 }}
                >
                  {saveMessageDelivery}
                </span>}
              </div>
            </div>
            <div className='col-6'>
              <label>Discount ($)</label>
              <div className='ipt-wrap'>
                <input
                  id='discount'
                  disabled={isBuyerPaid}
                  type='text'
                  value={discountValue}
                  className='form-control'
                  onChange={onDiscountChange}
                  onBlur={onDiscountUpdated}
                />
                {saveMessageDicount && <span id='discountSaved' style={{ fontWeight: '100', color: 'green', padding: 0 }}>
                  {saveMessageDicount}
                </span>}
              </div>
            </div>
          </div>
        </div>
        <div className='col-5 c-form'>
          <div className='row'>
            <div className='col-12'>
              <div className='slider-toogle' style={{ backgroundColor: 'white', padding: 0, marginBottom: 0 }}>
                <span>Request payment</span>
                <label className='switch'>
                  <input checked={payable === PAYABLE} type='checkbox' onChange={onControlPaymentClicked} disabled={!(orders && orders.length > 0)} />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {eventAndOrderById && <ModalRequestPayment
        modalRequest={modalRequest}
        toggleRequest={toggleRequest}
        eventAndOrderById={eventAndOrderById}
        setTimeRender={setTimeRender}
      />}
    </div>
  )
}
