import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { EVENT_STATUS_OPEN } from '_utils/constant'
import { convertToCurrentGMT, isDisableEvent } from '_utils/function'
import { getUserInfo } from '_utils/localData'

import Logo01 from '_images/copyright.svg'
import Logo02 from '_images/logo_02.svg'

import { SelectIdGroupContext } from '../../../context/SelectGroupContext'
import './style.scss'

const Header = ({ location }) => {
  const { infoGroupSelected } = useContext(SelectIdGroupContext)

  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const { allEvents } = useSelector((state) => state.event)
  const { myOrders } = useSelector((state) => state.order)

  const userInfo = getUserInfo()

  const [totalMyPurchases, setTotalMyPurchases] = useState(0)

  const [totalEvents, setTotalEvents] = useState(0)

  useEffect(() => {
    setTotalEvents(allEvents?.filter((item) => item.status === EVENT_STATUS_OPEN)?.length)
  }, [allEvents])

  useEffect(() => {
    const listOrderUnClosed = myOrders.filter((item) => {
      const isDisable = isDisableEvent(
        item?.status,
        item?.buyerStatus,
        convertToCurrentGMT(item?.ePickupTime),
        item?.ePickupDuration
      )
      if (!isDisable) {
        return item
      }
      return null
    })
    setTotalMyPurchases(listOrderUnClosed.length)
  }, [myOrders])

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])

  return (
    <div className='header align-self-start header-co-bee-desktop'>
      <div className='container-fluid header-co-bee-desktop-box' style={{ height: '56px' }}>
        {userInfo?.groups?.length > 0 ? (
          <div className='main-logo text-left d-flex flex-column'>
            <h4 className='ttl-grp pt-1'>{infoGroupSelected?.groupName}</h4>
            <img src={Logo01} alt='' />
          </div>
        ) : (
          <div id='logoCobee' className='main-logo '>
            <Link to='/'>
              <img src={Logo02} />
              <span>Good things must share!</span>
            </Link>
          </div>
        )}

        <Link to='/user/profile' className='avatar-top'>
          <div className='avatar-top__email'>
            <span id='totalNotififications'></span> <i className='icon-email'></i>
          </div>
          <div className='avatar-top__img'>
            <img
              id='userAvatar'
              src={
                userInfo?.photoUrl
                  ? userInfo.photoUrl
                  : process.env.REACT_APP_AVATAR_URL + userInfo?.name
              }
              alt=''
            />
          </div>
        </Link>
      </div>
      <div className='nav-menu'>
        <div className='container-fluid header-co-bee-desktop-box'>
          <div className='row'>
            <div className='col-4'>
              <Link to='/all-page' className={pathname[1] === 'all-page' ? 'active' : ''}>
                All {!!allEvents?.length && <span id='totalEvents'>({totalEvents})</span>}
              </Link>
            </div>
            <div className='col-4'>
              <Link
                to='/list-my-purchases'
                className={pathname[1] === 'list-my-purchases' ? 'active' : ''}
              >
                My Purchase
                {!!totalMyPurchases && <span id='totalMyPurchases'>({totalMyPurchases})</span>}
              </Link>
            </div>
            <div className='col-4'>
              <Link
                to='/list-my-groups'
                className={pathname[1] === 'list-my-groups' ? 'active' : ''}
              >
                My Group
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header)
