/* eslint-disable function-paren-newline */
import eventApi from '_api/event'
import deliveryApi from '_api/delivery'
import serviceApi from '_api/service'
import { clearMyOrder, clearDataPurchaseDetail } from '_redux/modules/order'
import { setDomain, getDomain } from '_utils/localData'
import { DOMAIN, STATUS_PAID } from '_utils/constant'

const GET_DELIVERY_DETAIL = 'GET_DELIVERY_DETAIL'
const GET_EVENT_AND_DELIVERY = 'GET_EVENT_AND_DELIVERY'
const UPDATE_DELIVERY_DETAIL = 'UPDATE_DELIVERY_DETAIL'
const UPDATE_EVENT_AND_DELIVERY = 'UPDATE_EVENT_AND_DELIVERY'
const CLEAR_EVENT_AND_DELIVERY = 'CLEAR_EVENT_AND_DELIVERY'
const CLEAR_DELIVERY_DETAIL = 'CLEAR_DELIVERY_DETAIL'
const GET_DELIVERY_BY_BUYER = 'GET_DELIVERY_BY_BUYER'
const GET_DELIVERY_BY_HOST = 'GET_DELIVERY_BY_HOST'
const GET_DELIVERY_BY_HOST_AND_DATE = 'GET_DELIVERY_BY_HOST_AND_DATE'
const UPDATE_LIST_DELIVERY = 'UPDATE_LIST_DELIVERY'
const LIST_DELIVERY_CREATED_BY_HOST = 'LIST_DELIVERY_CREATED_BY_HOST'
const GET_DELIVERY_AND_DELIVERY_BOOKING = 'GET_DELIVERY_AND_DELIVERY_BOOKING'
const GET_SCHEDULED_DELIVERY = 'GET_SCHEDULED_DELIVERY'

const initialState = {
  listDeliveryDetail: [],
  currentDeliveryDetail: {},
  errorGetDeliveryDetail: null,
  listEventAndDelivery: [],
  listDeliveryByHost: [],
  listDeliveryByBuyer: [],
  listDeliveryByHostAndDate: [],
  callNewAPIListDelivery: false,
  currentEventAndDelivery: {},
  errorGetEventAndDelivery: null,
  listDeliveryCreatedByHost: [],
  callNewAPIDeliveryCreatedByHost: false,
  listDeliveryAndDeliveryBooking: [],
  currentDeliveryAndDeliveryBooking: {},
  errorDeliveryAndDeliveryBooking: null,
  allScheduleDelivery: []
}

export const clearDeliveryDetail = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DELIVERY_DETAIL,
    payload: { listDeliveryDetail: [] },
  })
}

export const clearEventAndDelivery = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_AND_DELIVERY,
    payload: { listEventAndDelivery: [] },
  })
}

export const callNewDeliveryCreatedByHost = () => async (dispatch) => {
  dispatch({
    type: LIST_DELIVERY_CREATED_BY_HOST,
    payload: { callNewAPIDeliveryCreatedByHost: true },
  })
}

export const clearDeliveryCreatedByHost = () => async (dispatch) => {
  dispatch({
    type: LIST_DELIVERY_CREATED_BY_HOST,
    payload: { listDeliveryCreatedByHost: [] },
  })
}

export const getListDeliveryCreatedByHost = (hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    let { listDeliveryCreatedByHost = [] } = delivery
    const { callNewAPIDeliveryCreatedByHost } = delivery

    const index = listDeliveryCreatedByHost.findIndex((item) => item.hostId === hostId)

    if (index !== -1 && !callNewAPIDeliveryCreatedByHost) {
      return
    }

    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }
    const { msgResp: listDeliveryCreated } =
      await deliveryApi.listDeliveryCreatedByHost(hostId, domainLocal)

    if (index !== -1) {
      listDeliveryCreatedByHost[index] = { hostId, listDeliveryCreated }
    } else {
      listDeliveryCreatedByHost = [...listDeliveryCreatedByHost, { hostId, listDeliveryCreated }]
    }
    dispatch({
      type: LIST_DELIVERY_CREATED_BY_HOST,
      payload: {
        listDeliveryCreatedByHost,
        callNewAPIDeliveryCreatedByHost: false,
      },
    })
  } catch (e) {}
}

export const getMulListDeliveryCreatedByHost = (listHostId) => (dispatch) => {
  try {
    const getDeliveryCreatedByHost = async (_listHostId) => {
      const requests = _listHostId.map((hostId) =>
        deliveryApi
          .listDeliveryCreatedByHost(hostId)
          .then(({ msgResp: listDeliveryCreated }) => ({ hostId, listDeliveryCreated }))
      )
      return Promise.all(requests)
    }
    getDeliveryCreatedByHost(listHostId).then((data) => {
      dispatch({
        type: LIST_DELIVERY_CREATED_BY_HOST,
        payload: {
          listDeliveryCreatedByHost: [...data],
        },
      })
    })
  } catch (e) {}
}

export const getDeliveryByBuyer = (buyerId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryByBuyer = [] } = delivery

    if (listDeliveryByBuyer.length) {
      return
    }
    let newListDeliveryByBuyer = []

    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    const { msgResp: listDelivery } = await deliveryApi.listDeliveryByBuyer(buyerId, domainLocal)

    if (listDelivery.length) {
      newListDeliveryByBuyer = listDelivery.sort(
        (a, b) => a.dDeliveryTimestamp - b.dDeliveryTimestamp
      )
    }

    dispatch({
      type: GET_DELIVERY_BY_BUYER,
      payload: {
        listDeliveryByBuyer: newListDeliveryByBuyer,
      },
    })
  } catch (error) {}
}

export const clearDeliveryByBuyer = () => async (dispatch) => {
  dispatch({
    type: GET_DELIVERY_BY_BUYER,
    payload: {
      listDeliveryByBuyer: [],
    },
  })
}

export const getDeliveryDetail = (ref) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    let { listDeliveryDetail } = delivery

    const index = listDeliveryDetail.findIndex((item) => item.ref === ref)

    if (index !== -1) {
      dispatch({
        type: GET_DELIVERY_DETAIL,
        payload: {
          errorGetDeliveryDetail: null,
          currentDeliveryDetail: listDeliveryDetail[index],
        },
      })
      return
    }

    let newDeliveryDetail = null

    const { msgResp: deliveryDetail } = await deliveryApi.getDeliveryBooking(ref)

    if (deliveryDetail) {
      newDeliveryDetail = deliveryDetail
      const { dCreatedUserId } = deliveryDetail
      await dispatch(getListDeliveryCreatedByHost(dCreatedUserId))
      listDeliveryDetail = [...listDeliveryDetail, deliveryDetail]
    }

    dispatch({
      type: GET_DELIVERY_DETAIL,
      payload: {
        listDeliveryDetail,
        currentDeliveryDetail: newDeliveryDetail,
        errorGetDeliveryDetail: null,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_DELIVERY_DETAIL,
      payload: {
        errorGetDeliveryDetail: error,
      },
    })
  }
}

export const getDeliveryByHost = (hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryByHost = [] } = delivery

    if (listDeliveryByHost.length) {
      return
    }
    let newListDeliveryByHost = []

    const { msgResp: listDelivery } = await deliveryApi.listDeliveryByHost(hostId)
    if (listDelivery && listDelivery.length) {
      newListDeliveryByHost = listDelivery
    }
    dispatch({
      type: GET_DELIVERY_BY_HOST,
      payload: {
        listDeliveryByHost: newListDeliveryByHost,
      },
    })
  } catch (error) {}
}

export const updateListDelivery =
  (ref, hostId, deliveryTime, status, bookerStatus) => async (dispatch, getState) => {
    const { delivery } = getState()
    const { listDeliveryByHostAndDate = [], listDeliveryByHost = [] } = delivery
    const indexHD = listDeliveryByHostAndDate.findIndex(
      (item) => item.hostId === hostId && item.deliveryTime === deliveryTime
    )
    if (indexHD !== -1) {
      const { listDelivery } = listDeliveryByHostAndDate[indexHD]
      for (let indexD = 0; indexD < listDelivery.length; indexD++) {
        if (listDelivery[indexD].ref === ref) {
          listDelivery[indexD].status = status
          listDelivery[indexD].bookerStatus = bookerStatus
        }
      }
    }
    if (listDeliveryByHost.length) {
      for (let index = 0; index < listDeliveryByHost.length; index++) {
        if (listDeliveryByHost[index].ref === ref) {
          listDeliveryByHost[index].status = status
          listDeliveryByHost[index].bookerStatus = bookerStatus
        }
      }
    }
    dispatch(clearDeliveryDetail())
    dispatch(clearMyOrder())
    dispatch(clearDataPurchaseDetail())
    dispatch({
      type: UPDATE_LIST_DELIVERY,
      payload: {
        listDeliveryByHost,
        listDeliveryByHostAndDate,
        callNewAPIListDelivery: true,
      },
    })
  }

// update bookerStatus
export const updateDeliveryBookerStatus = (ref, bookerStatus) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryDetail } = delivery

    const indexDelivery = listDeliveryDetail.findIndex((item) => item.ref === ref)

    if (indexDelivery !== -1) {
      listDeliveryDetail[indexDelivery].bookerStatus = bookerStatus
    }

    dispatch({
      type: UPDATE_DELIVERY_DETAIL,
      payload: { listDeliveryDetail },
    })
  } catch (error) {}
}

export const updateDeliveryDetail = (ref, data) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryDetail } = delivery
    const indexDelivery = listDeliveryDetail.findIndex((item) => item.ref === ref)

    if (indexDelivery !== -1) {
      listDeliveryDetail[indexDelivery] = {
        ...listDeliveryDetail[indexDelivery],
        ...data,
      }
    }

    dispatch({
      type: UPDATE_DELIVERY_DETAIL,
      payload: { listDeliveryDetail },
    })
  } catch (error) {}
}

export const getEventAndDelivery = (eid) => async (dispatch, getState) => {
  try {
    const { delivery, event } = getState()
    let { listEventAndDelivery = [] } = delivery
    const { callNewAPIListDelivery } = delivery
    const { eventAndOrderById = [] } = event

    const indexED = listEventAndDelivery.findIndex((item) => item.id === eid)
    const indexEO = eventAndOrderById.findIndex((item) => item.id === eid)

    if (indexED !== -1) {
      dispatch({
        type: GET_EVENT_AND_DELIVERY,
        payload: {
          errorGetEventAndDelivery: null,
          currentEventAndDelivery: listEventAndDelivery[indexED],
        },
      })
      if (!callNewAPIListDelivery) {
        return
      }
    }
    let newEventAndDelivery = {}
    if (indexEO !== -1) {
      const { pickupTimestamp, pickupTime, totalOrderCount, title, deliveryStatus } =
        eventAndOrderById[indexEO]

      newEventAndDelivery = {
        ...newEventAndDelivery,
        pickupTimestamp,
        pickupTime,
        totalOrderCount,
        id: eid,
        title,
        deliveryStatus,
      }
    } else if (indexED !== -1) {
      const { pickupTimestamp, pickupTime, totalOrderCount, title, deliveryStatus } =
        listEventAndDelivery[indexED]

      newEventAndDelivery = {
        ...newEventAndDelivery,
        pickupTimestamp,
        pickupTime,
        totalOrderCount,
        id: eid,
        title,
        deliveryStatus,
      }
    } else {
      const { msgResp: eventDetail } = await eventApi.getEventDetail({
        eid,
      })
      if (eventDetail) {
        const { pickupTimestamp, pickupTime, totalOrderCount, title, deliveryStatus } = eventDetail

        newEventAndDelivery = {
          ...newEventAndDelivery,
          pickupTimestamp,
          pickupTime,
          totalOrderCount,
          id: eid,
          title,
          deliveryStatus,
        }
      }
    }
    const { msgResp: listDelivery } = await deliveryApi.listDeliveryByEvent(eid)
    if (listDelivery) {
      newEventAndDelivery = {
        ...newEventAndDelivery,
        listDelivery,
      }
      if (indexED !== -1) {
        listEventAndDelivery[indexED] = newEventAndDelivery
      } else {
        listEventAndDelivery = [...listEventAndDelivery, newEventAndDelivery]
      }
    }
    dispatch({
      type: GET_EVENT_AND_DELIVERY,
      payload: {
        listEventAndDelivery,
        currentEventAndDelivery: newEventAndDelivery,
        errorGetEventAndDelivery: null,
        callNewAPIListDelivery: false,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_EVENT_AND_DELIVERY,
      payload: {
        errorGetEventAndDelivery: error,
        callNewAPIListDelivery: false,
      },
    })
  }
}

export const getListDeliveryAndDeliveryBooking = (deliveryId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    let { listDeliveryAndDeliveryBooking = [] } = delivery
    const { callNewAPIListDelivery } = delivery

    const index = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)

    if (index !== -1) {
      dispatch({
        type: GET_DELIVERY_AND_DELIVERY_BOOKING,
        payload: {
          errorDeliveryAndDeliveryBooking: null,
          currentDeliveryAndDeliveryBooking: listDeliveryAndDeliveryBooking[index],
        },
      })
      if (!callNewAPIListDelivery) {
        return
      }
    }
    let newDeliveryAndDeliveryBooking = {}
    const { msgResp: deliveryAndDeliveryBooking } = await deliveryApi.getDelivery(deliveryId, true)
    if (Object.keys(deliveryAndDeliveryBooking).length) {
      newDeliveryAndDeliveryBooking = { ...deliveryAndDeliveryBooking }
      if (index !== -1) {
        listDeliveryAndDeliveryBooking[index] = newDeliveryAndDeliveryBooking
      } else {
        listDeliveryAndDeliveryBooking = [
          ...listDeliveryAndDeliveryBooking,
          newDeliveryAndDeliveryBooking,
        ]
      }
    }
    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        listDeliveryAndDeliveryBooking,
        currentDeliveryAndDeliveryBooking: newDeliveryAndDeliveryBooking,
        errorDeliveryAndDeliveryBooking: null,
        callNewAPIListDelivery: false,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        errorDeliveryAndDeliveryBooking: error,
        callNewAPIListDelivery: false,
      },
    })
  }
}

export const clearListDeliveryAndDeliveryBooking = () => async (dispatch) => {
  dispatch({
    type: GET_DELIVERY_AND_DELIVERY_BOOKING,
    payload: { listDeliveryAndDeliveryBooking: [] },
  })
}

export const updateDeliveryInfo = (deliveryId, data) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryAndDeliveryBooking } = delivery

    const indexDelivery = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)

    if (indexDelivery !== -1) {
      listDeliveryAndDeliveryBooking[indexDelivery] = {
        ...listDeliveryAndDeliveryBooking[indexDelivery],
        ...data,
      }
    }

    dispatch(clearDeliveryByBuyer())
    dispatch(clearDeliveryCreatedByHost())

    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: { listDeliveryAndDeliveryBooking },
    })
  } catch (error) {}
}

export const deleteDelivery = (deliveryId, hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { listDeliveryAndDeliveryBooking = [], listDeliveryCreatedByHost = [] } = delivery

    const indexDD = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)
    const indexDB = listDeliveryCreatedByHost.findIndex((item) => item.hostId === hostId)

    const newListDeliveryAndDeliveryBooking = listDeliveryAndDeliveryBooking
    const newListDeliveryCreatedByHost = listDeliveryCreatedByHost

    if (indexDD !== -1) {
      newListDeliveryAndDeliveryBooking.splice(indexDD, 1)
    }

    if (indexDB !== -1) {
      const { listDeliveryCreated: newListDeliveryCreated } = newListDeliveryCreatedByHost[indexDB]
      const indexD = newListDeliveryCreated.findIndex((item) => item.id === deliveryId)
      newListDeliveryCreated.splice(indexD, 1)
      newListDeliveryCreatedByHost[indexDB].listDeliveryCreated = newListDeliveryCreated
    }

    dispatch(clearDeliveryByBuyer())

    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        listDeliveryAndDeliveryBooking: [...newListDeliveryAndDeliveryBooking],
        listDeliveryCreatedByHost: [...newListDeliveryCreatedByHost],
      },
    })
  } catch (error) {}
}

export const updateDeliveryPaymentStatusDesktop =
  (deliveryId, refDeliveryBooking, status, bookerStatus) => async (dispatch, getState) => {
    const { event } = getState()
    const { deliveryBookings } = event.listEventAndDelivery
    const index = deliveryBookings.findIndex((item) => item.did === deliveryId)

    if (index !== -1) {
      if (deliveryBookings[index].ref === refDeliveryBooking) {
          deliveryBookings[index].status = status
          deliveryBookings[index].bookerStatus = bookerStatus
        }
    }
    dispatch(clearDeliveryDetail())
    dispatch(clearMyOrder())
    dispatch(clearDataPurchaseDetail())
    dispatch(clearDeliveryByBuyer())
    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        deliveryBookings,
        callNewAPIDeliveryCreatedByHost: true,
      },
    })
  }

// update status and bookerStatus of delivery booking manage page
export const updateDeliveryPaymentStatus =
  (deliveryId, refDeliveryBooking, status, bookerStatus) => async (dispatch, getState) => {
    const { delivery } = getState()
    const { listDeliveryAndDeliveryBooking = [] } = delivery
    const index = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)
    if (index !== -1) {
      const { deliveryBookings } = listDeliveryAndDeliveryBooking[index]
      for (let indexD = 0; indexD < deliveryBookings.length; indexD++) {
        if (deliveryBookings[indexD].ref === refDeliveryBooking) {
          deliveryBookings[indexD].status = status
          deliveryBookings[indexD].bookerStatus = bookerStatus
        }
      }
    }
    dispatch(clearDeliveryDetail())
    dispatch(clearMyOrder())
    dispatch(clearDataPurchaseDetail())
    dispatch(clearDeliveryByBuyer())
    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        listDeliveryAndDeliveryBooking,
        callNewAPIDeliveryCreatedByHost: true,
      },
    })
  }

export const updateDeliveryComment = (deliveryId, ref, comment) => async (dispatch, getState) => {
  const { delivery } = getState()
  const { listDeliveryAndDeliveryBooking = [] } = delivery

  const index = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)

  if (index !== -1) {
    const { deliveryBookings } = listDeliveryAndDeliveryBooking[index]
    for (let indexD = 0; indexD < deliveryBookings.length; indexD++) {
      if (deliveryBookings[indexD].ref === ref) {
        deliveryBookings[indexD].comment = comment
      }
    }
  }
  dispatch({
    type: GET_DELIVERY_AND_DELIVERY_BOOKING,
    payload: {
      listDeliveryAndDeliveryBooking,
    },
  })
}

// listDeliveryAndDeliveryBooking
export const updateDeliveryCombinedPayment =
  (combinedPaymentRef, status, bookerStatus) => async (dispatch, getState) => {
    try {
      const { delivery } = getState()
      const { listDeliveryAndDeliveryBooking = [] } = delivery

      const { event } = getState()
      const { listCombinedPayments = [] } = event

      const exitDeliveryCombinedPayment = listCombinedPayments.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      let deliveryCombinedPayment = []
      if (exitDeliveryCombinedPayment !== -1) {
        // eslint-disable-next-line prefer-destructuring
        deliveryCombinedPayment =
          listCombinedPayments[exitDeliveryCombinedPayment].deliveryCombinedPayment

        deliveryCombinedPayment.forEach(async (element) => {
          const { did, ref } = element
          const exitDelivery = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === did)
          if (exitDelivery !== -1) {
            const newDeliveryBookings =
              listDeliveryAndDeliveryBooking[exitDelivery].deliveryBookings
            for (
              let indexDelivery = 0;
              indexDelivery < newDeliveryBookings.length;
              indexDelivery++
            ) {
              if (newDeliveryBookings[indexDelivery].ref === ref) {
                newDeliveryBookings[indexDelivery].status = status
                newDeliveryBookings[indexDelivery].bookerStatus = bookerStatus
              }
            }
            listDeliveryAndDeliveryBooking[exitDelivery] = {
              ...listDeliveryAndDeliveryBooking[exitDelivery],
              deliveryBookings: newDeliveryBookings,
            }
          }
        })
      }
      dispatch(clearMyOrder())
      dispatch(clearDeliveryDetail())
      dispatch(clearDeliveryByBuyer())
      dispatch({
        type: UPDATE_EVENT_AND_DELIVERY,
        payload: {
          listDeliveryAndDeliveryBooking,
          callNewAPIListDelivery: true,
        },
      })
    } catch (e) {}
  }

export const getScheduledDelivery = (hostId) => async (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery = [] } = delivery

    const index = allScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (index !== -1) {
      return
    }

    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    let newScheduleDelivery = {}
    const { msgResp: listScheduleDelivery } =
      await deliveryApi.listScheduledDeliveries(hostId, domainLocal)
    newScheduleDelivery = {
      hostId, listScheduleDelivery
    }
    dispatch({
      type: GET_SCHEDULED_DELIVERY,
      payload: { allScheduleDelivery: [...allScheduleDelivery, newScheduleDelivery] },
    })
  } catch (error) {

  }
}

export const clearScheduledDelivery = (dispatch) => {
  dispatch({
    type: GET_SCHEDULED_DELIVERY,
    payload: { allScheduleDelivery: [] }
  })
}

export const deleteScheduledDelivery = (hostId, id) => (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery } = delivery

    const newAllScheduleDelivery = allScheduleDelivery

    const indexHost = newAllScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (indexHost !== -1) {
      const { listScheduleDelivery = [] } = newAllScheduleDelivery[indexHost]
      const indexDel = listScheduleDelivery.findIndex((item) => item.id === id)
      if (indexDel !== -1) {
        listScheduleDelivery.splice(indexDel, 1)
      }
      newAllScheduleDelivery[indexHost].listScheduleDelivery = listScheduleDelivery
    }

    dispatch({
      type: GET_SCHEDULED_DELIVERY,
      payload: {
        allScheduleDelivery: [...newAllScheduleDelivery],
      }
    })
  } catch (error) { }
}

export const updateScheduledDelivery = (hostId, id, data) => (dispatch, getState) => {
  try {
    const { delivery } = getState()
    const { allScheduleDelivery } = delivery

    const newAllScheduleDelivery = allScheduleDelivery

    const indexHost = newAllScheduleDelivery.findIndex((item) => item.hostId === hostId)

    if (indexHost !== -1) {
      const { listScheduleDelivery = [] } = newAllScheduleDelivery[indexHost]
      const indexDel = listScheduleDelivery.findIndex((item) => item.id === id)
      if (indexDel !== -1) {
        listScheduleDelivery[indexDel] = {
          ...listScheduleDelivery[indexDel],
          ...data
        }
      }
      newAllScheduleDelivery[indexHost].listScheduleDelivery = listScheduleDelivery
    }

    dispatch({
      type: GET_SCHEDULED_DELIVERY,
      payload: {
        allScheduleDelivery: [...newAllScheduleDelivery],
      }
    })
  } catch (error) { }
}

const delivery = (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERY_DETAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_DELIVERY_DETAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENT_AND_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT_AND_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_EVENT_AND_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_DELIVERY_DETAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case LIST_DELIVERY_CREATED_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_DELIVERY_BY_BUYER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_DELIVERY_BY_HOST_AND_DATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_DELIVERY_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_LIST_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_DELIVERY_AND_DELIVERY_BOOKING: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_SCHEDULED_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default delivery
