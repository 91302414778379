/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { findNearestDateOfEventClose } from '_utils/function'

function ListEventClose({ listEventClose }) {
  const [checkIsData, setCheckIsData] = useState(false)
  const [listEventSorted, setListEventSorted] = useState([])
  const [eventCloseNearest, setEventCloseNearest] = useState([])
  const [seeMoreEvent, setSeeMoreEvent] = useState(false)
  const [isShowTextSeeMore, setIsShowTextSeeMore] = useState(false)
  const [countSeeMore, setCountSeeMore] = useState(0)

  useEffect(() => {
    const getCurrentMonth = moment().format('MM')
    if (listEventClose?.length > 0) {
      const getMonthOfEventClose = moment.unix(listEventClose[0].closingTimestamp).format('MM')
      if (getCurrentMonth === getMonthOfEventClose) {
      const getCurrentDate = moment().format('DD-MM-YYYY HH:mm')
      const dateOfEventClose = []
      listEventClose?.map((date) => dateOfEventClose.push(date.closingTime))
      const listEventCloseNearest = findNearestDateOfEventClose(
        dateOfEventClose,
        getCurrentDate,
        listEventClose
      )
      setListEventSorted(listEventCloseNearest)
      setEventCloseNearest([listEventCloseNearest[0]])
      setCheckIsData(false)
      const convertFinalDateInListEventClose = moment(
        listEventCloseNearest[listEventCloseNearest?.length - 1]?.closingTime,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD')
      const convertCurrentDate = moment().format('YYYY-MM-DD')
      setIsShowTextSeeMore(moment(convertFinalDateInListEventClose).isAfter(convertCurrentDate))
      } else {
        // THIS LOGIC TO CHECK GREATER THAN EVENT CLOSE DATE
        const currentDate = moment().format('YYYY-MM-DD')
        const EventCloseDate = moment(listEventClose[0].closingTime, 'DD-MM-YYYY').format('YYYY-MM-DD')
        const checkCurrentGreaterThanEventCloseDate = moment(currentDate).isAfter(EventCloseDate)
        setEventCloseNearest(
          checkCurrentGreaterThanEventCloseDate
            ? [listEventClose[listEventClose.length - 1]]
            : [listEventClose[0]]
        )
        setCheckIsData(false)
      setIsShowTextSeeMore(false)
      }
    } else {
      setCheckIsData(true)
    }
  }, [listEventClose])

  const handleShowMoreEventClose = () => {
    const count = countSeeMore + 5
    if (!seeMoreEvent) {
      if (listEventSorted?.length > count) {
        listEventSorted?.slice(0, count)
        setEventCloseNearest(listEventSorted?.slice(0, count))
        setCountSeeMore(count)
        setSeeMoreEvent(false)
      } else {
        setEventCloseNearest(listEventSorted)
        setSeeMoreEvent(true)
      }
    } else {
      setEventCloseNearest([listEventSorted[0]])
      setSeeMoreEvent(false)
      setCountSeeMore(0)
    }
  }

  if (checkIsData) {
    return <div style={{ fontSize: '14px' }}> No event close </div>
  }

  return (
    <div className='row'>
      {eventCloseNearest?.map((item, i) => (
        <div className='row event__close-info' key={i}>
          <div className='col-4'>
            <div className=' event__close-info-day'>
              <div className='event__close-info-day-text'>
                {moment.unix(item?.closingTimestamp).format('ddd')}
              </div>
              <div className='event__close-info-day-number'>
                {moment.unix(item?.closingTimestamp).format('DD-MM-YYYY').slice(0, 2)}
              </div>
            </div>
          </div>
          <div className='col-8 event__close-info-detail'>
            <p>Event Close</p>
            <div className='event__close-info-detail-name'>
              <span>{item?.title}</span>
            </div>
            <span>Number purchase: {item?.totalOrderCount}</span>
            <span>Total members: {item?.totalMember}</span>
            <span>Total sales: {item?.totalSales}</span>
          </div>
        </div>
      ))}
      <div className='mt-1 mb-1'>{isShowTextSeeMore ? <span className='event-info-see-more' onClick={handleShowMoreEventClose}>{seeMoreEvent ? 'see less' : 'see more'}</span> : ''}</div>
    </div>
  )
}

export default ListEventClose
