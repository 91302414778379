import { useContext, useState } from 'react'
import { AiFillSetting } from 'react-icons/ai'
import {
  BsFillCalendarEventFill,
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
  BsJournalRichtext,
} from 'react-icons/bs'
import { FaCompass, FaDollarSign, FaTruck } from 'react-icons/fa'
import { IoNotifications } from 'react-icons/io5'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import useQuery from '_hooks/useQuery'
import { GROUP_IS_ADMIN, GROUP_IS_HOST } from '_utils/constant'
import { getGroupIdSelected, getUserInfo, setGroupIdSelected } from '_utils/localData'
import { CollapseContext } from '../../../context/CollapseMenuContext'
import { SelectIdGroupContext } from '../../../context/SelectGroupContext'
import './style.scss'

const menu = [
  {
    id: 1,
    path: '/',
    icon: <IoNotifications />,
    title: 'Dashboard',
  },
  {
    id: 2,
    path: '/event/list-hosted-by-group',
    icon: <BsFillCalendarEventFill />,
    title: 'Hosted Event',
  },
  {
    id: 3,
    path: '/manage-orders',
    icon: <FaDollarSign />,
    title: 'Events Orders',
  },
  {
    id: 4,
    path: '/event/delivery-list',
    icon: <FaTruck />,
    title: 'Delivery',
  },
  {
    id: 5,
    path: '/delivery-booking',
    icon: <BsJournalRichtext />,
    title: 'Delivery Orders',
  },
  {
    id: 6,
    path: '/members/member-list',
    icon: <FaCompass />,
    title: 'Members',
  },
  {
    id: 7,
    path: '/settings',
    icon: <AiFillSetting />,
    title: 'Settings',
  },
]

function LeftMenu() {
  const userInfo = getUserInfo()
  const history = useHistory()
  const [myGroups] = useState([...userInfo.groups])
  const { setInfoGroupSelected, infoGroupSelected } = useContext(SelectIdGroupContext)
  const { isCollapse, toggleCollapse } = useContext(CollapseContext)
  const params = useQuery()
  const listGroupWithAdmin = myGroups?.filter(
    (item) => item.isHost === GROUP_IS_HOST || item.isAdmin === GROUP_IS_ADMIN
  )

  const handleSelectGroup = (e) => {
    const groupSelected = listGroupWithAdmin?.filter((group) => group.gid === e.target.value)[0]
    setGroupIdSelected(groupSelected?.gid)
    setInfoGroupSelected({
      groupId: groupSelected?.gid,
      groupName: groupSelected?.name,
      totalMembers: groupSelected?.totalMembers,
    })

    if (
      `${process.env.REACT_APP_APP_URL}/event/manage-orders?eventId=${params.get('eventId')}` ===
      window.location.href
    ) {
      history.push('/event/list-hosted-by-group')
    } else if (
      `${process.env.REACT_APP_APP_URL}/event/manage-delivery?id=${params.get('id')}` ===
      window.location.href
    ) {
      history.push('/event/delivery-list')
    } else if (
      `${process.env.REACT_APP_APP_URL}/members/member-detail?gid=${params.get(
        'gid'
      )}&uid=${params.get('uid')}` === window.location.href
    ) {
      history.push('/members/member-list')
    }
  }

  return (
    <div className='sidebar pe-0'>
      <div className='d-flex justify-content-between'>
        <div className={`${isCollapse ? 'ms-2' : ''} d-flex flex-column`}>
          <div className='d-flex align-items-start mb-2'>
            <img
              src={
                userInfo?.photoUrl
                  ? userInfo.photoUrl
                  : process.env.REACT_APP_AVATAR_URL + userInfo?.name
              }
              className='rounded-circle'
              alt='avatar'
            />
            <div className={`${isCollapse ? 'd-none' : 'd-flex'} flex-column w-100 mx-3`}>
              {myGroups && (
                <>
                  <select
                    name='groups'
                    id='groups'
                    defaultValue={getGroupIdSelected()}
                    onChange={(e) => handleSelectGroup(e)}
                    className='form-select w-100 mb-1 fw-bold text-orange'
                  >
                    {listGroupWithAdmin.map((group) => (
                      <option key={group.gid} value={group.gid}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: '#585e5a', fontSize: 12 }}>
                    {infoGroupSelected?.totalMembers} Members
                  </span>
                </>
              )}
            </div>
          </div>
          <ul className={`${!isCollapse ? 'px-2' : 'ps-2'} mb-0 list-menu`}>
            {menu.map((item) => (
              <li className='d-flex fw-bold align-items-end mb-2 menu-item' key={item.id}>
                <NavLink
                  to={item.path}
                  className='text-uppercase'
                  activeClassName='text-orange'
                  exact
                >
                  <span className='me-4 icons'>{item.icon}</span>
                  <span>{isCollapse ? null : item.title}</span>
                  {isCollapse && <span className='tooltip'>{item.title}</span>}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className='style-menu' onClick={toggleCollapse}>
          <div className='group'>
            <span className='text-uppercase'>menu</span>
            <span className='d-inline-block'>
              {isCollapse ? <BsFillCaretRightFill /> : <BsFillCaretLeftFill />}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftMenu
