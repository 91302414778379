/* eslint-disable no-unused-vars */
import { createBrowserHistory } from 'history'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import ProtectedRoute from '_components/ProtectedRoute'
import useScript from '_hooks/useScript'
import ForgotPasswordPage from '_pages/ForgotPassword'
import MainPage from '_pages/Main'
import Policy from '_pages/Policy'
import ResetPasswordPage from '_pages/ResetPassword'
import SignInPage from '_pages/SignIn'
import SignUpPage from '_pages/SignUp'
import { DESKTOP, PHONE } from '_utils/constant'
import { getDeviceUser, setDeviceUser } from '_utils/localData'

import { store } from '_redux/store'

import Welcome from '_pages/Event/components/Welcome'
import { DeviceUserContext } from '../context/DeviceUserContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { firebase } from '../firebase/config'
import AppDesktop from '../pages-desktop'
import ListAllEvent from './ListAllEvent'

const history = createBrowserHistory()

const RedirectGoogleSites = () => {
  useEffect(() => {
    window.location.href = 'https://sites.google.com/co-bee.com/home'
  }, [])

  return null
}

export default function App() {
  const [checkDeviceUser, setCheckDeviceUser] = useState(PHONE)

  useEffect(() => {
    if (getDeviceUser() === null) {
      if (screen.width >= 1203) {
        setDeviceUser(DESKTOP)
        setCheckDeviceUser(DESKTOP)
      } else {
        setDeviceUser(PHONE)
        setCheckDeviceUser(PHONE)
      }
    } else {
      setCheckDeviceUser(getDeviceUser())
    }
  }, [])

  if (process.env.REACT_APP_ENVIRONMENT === 'production') useScript()

  return (
    <Provider store={store}>
      <ToastProvider>
        <FirebaseContext.Provider value={{ firebase }}>
          <DeviceUserContext.Provider value={{ checkDeviceUser, setCheckDeviceUser }}>
            <Router history={history}>
              <Switch>
                <Route path='/private/list-event' exact={true} component={ListAllEvent} />
                <Route path='/sign-in' exact={true} component={SignInPage} />
                <Route path='/sign-up' exact={true} component={SignUpPage} />
                <Route path='/reset-password' exact={true} component={ForgotPasswordPage} />
                <Route path='/reset-password-with-code' component={ResetPasswordPage} />
                <Route path='/info' exact={true} component={RedirectGoogleSites} />
                <Route path='/policy' exact={true} component={Policy} />
                <ProtectedRoute path='/welcome' exact={true} component={Welcome} />
                <ProtectedRoute
                  path='/'
                  component={checkDeviceUser === DESKTOP ? AppDesktop : MainPage}
                />
              </Switch>
            </Router>
          </DeviceUserContext.Provider>
        </FirebaseContext.Provider>
      </ToastProvider>
    </Provider>
  )
}
