import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const options = [
  { value: 1, label: 'Not Paid' },
  { value: 2, label: 'Paid' },
  { value: -2, label: 'Refunded' },
]

function SelectPaymentStatus({ onChangePaymentStatus, paymentStatus }) {
  const [state, setState] = useState({
    selectedOption: [],
  })

  useEffect(() => {
    setState({
      ...state,
      selectedOption: paymentStatus,
    })
  }, [paymentStatus])

  const handleChange = (selectedOption) => {
    setState({ selectedOption })
    onChangePaymentStatus({ selectedOption })
  }

  return (
    <div>
      <Select
        isMulti
        closeMenuOnSelect={false}
        value={paymentStatus}
        onChange={handleChange}
        options={options}
        placeholder='Payment Status'
        className='rounded-0 ms-3 text-orange w-auto'
      />
    </div>
  )
}

export default SelectPaymentStatus
