/* eslint-disable no-unused-vars */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import {
  cartesian,
  convertStatusNumberToText,
  convertToCurrentGMT,
  exportToCsv,
  getFirstStringAndUppercase,
  groupBy,
  handleDataOrder,
  normalizeName,
  sortArrayBaseOnAnotherArray,
  sum,
} from '_utils/function'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import eventApi from '_api/event'
import {
  callNewListOrder,
  clearAllEvents,
  clearMyEvents,
  updateStatusEvent,
} from '_redux/modules/event'
import {
  BUYER_ORDER_CANCELED,
  BUYER_REFUNDED,
  EVENT_IS_REOPEN,
  HOST_ORDER_CANCELED,
  NOT_PAYABLE,
  NOT_PAYNOW,
  PAYABLE,
  PAYNOW,
} from '_utils/constant'
import { setCreateEventData } from '_utils/localData'

import useQuery from '_hooks/useQuery'
import { useDispatch } from 'react-redux'

const ModalExport = ({ eventAndOrderById, isOpen, toggle }) => {
  // const { eventAndOrderById } = useSelector((state) => state.event)
  const {
    title,
    description,
    closingTime,
    pickupTime,
    createdUserName,
    createdUserPhone,
    orders = [],
    adminCost,
    discount,
    deliveryCost,
  } = eventAndOrderById || {}

  const { listOrder, totalAllOrder, adminCostAllOrder, paymentCollected } = handleDataOrder(
    orders,
    adminCost,
    discount,
    deliveryCost
  )

  const ordersActive = orders.filter(
    (order) =>
      order.buyerStatus !== BUYER_ORDER_CANCELED &&
      order.buyerStatus !== BUYER_REFUNDED &&
      order.status !== HOST_ORDER_CANCELED
  )

  const firstName = createdUserName ? createdUserName.split(' ').slice(0, 1) : ''
  const lastName = createdUserName ? createdUserName.split(' ').slice(1).join(' ') : ''

  const generalInfo = [
    ['Title', title],
    ['Description', description],
    ['Closing Date', convertToCurrentGMT(closingTime).trim().split(' ')[0]],
    ['Closing Time', convertToCurrentGMT(closingTime).trim().split(' ')[1]],
    ['Pick-up Date', convertToCurrentGMT(pickupTime).trim().split(' ')[0]],
    ['Pick-up Time', convertToCurrentGMT(pickupTime).trim().split(' ')[1]],
    ['Organiser First Name', firstName],
    ['Organiser Last Name', lastName],
    ['Organiser Number', createdUserPhone],
    [''],
  ]

  const total = [
    ['Total Purchase', `$${totalAllOrder}`],
    ['Admin Fee', `$${adminCostAllOrder}`],
    ['Payment Confirmed', `$${paymentCollected}`],
    [''],
  ]

  const totalOrderArr = [
    ...ordersActive.reduce((acc, curr) => {
      const order = acc.find((p) => p.pid === curr.pid)
      if (order) {
        const index = acc.indexOf(order)
        acc[index].count += curr.pQuantity
      } else {
        curr.count = curr.pQuantity
        acc.push(curr)
      }
      return acc
    }, []),
  ]

  const rowsOfTotalOrder = new Array(totalOrderArr.length)

  const export1 = () => {
    const rowsOfProduct = new Array(ordersActive.length) || []

    for (let index = 0; index < ordersActive.length; index++) {
      const product = ordersActive[index]
      const num = ordersActive.filter((o) => o.ref === product.ref).length

      const {
        eOrderNo,
        ref,
        uName,
        uPhone,
        pQuantity,
        pName,
        pPrice,
        buyerStatus,
        status: productStatus,
        pSubItems,
        comment,
        combinedPaymentRef,
      } = product

      const newRef = `${getFirstStringAndUppercase(title)}-${ref.slice(0, 6).toUpperCase()}`
      const { deliveryPerOrder } = listOrder.find((x) => x.ref === ref)
      const sharedDiscount = Number(discount * ((pPrice * pQuantity) / totalAllOrder))
      const sharedDeliveryCost = Number(deliveryPerOrder / num)
      const adminFee = Number(adminCost / num)
      const amount = pPrice * pQuantity - sharedDiscount + sharedDeliveryCost + adminFee
      const payTogether = combinedPaymentRef ? 'Yes' : 'No'
      let pNameAndSub = pName

      if (Array.isArray(pSubItems)) {
        if (pSubItems.length) {
          pNameAndSub += ' ('
          for (let i = 0; i < pSubItems.length; i++) {
            const subItem = pSubItems[i]
            pNameAndSub += `${subItem.name}: +$${subItem.price}; `
          }
          pNameAndSub += ')'
        }
      }

      const buyerFirstName = uName ? uName.split(' ').slice(0, 1) : ''
      const buyerLastName = uName ? uName.split(' ').slice(1).join(' ') : ''

      rowsOfProduct[index] = [
        eOrderNo,
        newRef,
        buyerFirstName,
        buyerLastName,
        uPhone,
        pQuantity,
        pNameAndSub,
        pPrice,
        sharedDiscount.toFixed(2),
        sharedDeliveryCost.toFixed(2),
        adminFee.toFixed(2),
        Number(amount).toFixed(2),
        convertStatusNumberToText(buyerStatus), // BUYER
        convertStatusNumberToText(productStatus), // HOST
        payTogether,
        comment,
      ]
    }

    for (let index = 0; index < totalOrderArr.length; index++) {
      const totalOrder = totalOrderArr[index]

      const { pName, count } = totalOrder

      rowsOfTotalOrder[index] = [pName, count]
    }

    const rows = [
      ...generalInfo,
      [
        'Order No',
        'Ref',
        'First Name',
        'Last Name',
        'Contact',
        'Quantity',
        'Item',
        'Price',
        'Shared discount',
        'Shared delivery cost',
        'Admin fee',
        'Amount',
        'Payment',
        'Status',
        'Pay together',
        'Comment',
      ],
      ...rowsOfProduct,
      [''],
      ...total,
      ['Total orders'],
      ...rowsOfTotalOrder,
    ]

    const fileName = normalizeName(title)
    exportToCsv(`${fileName}.csv`, rows)
  }

  const export2 = () => {
    const { products, productIdList } = eventAndOrderById

    const rowsOfContact = new Array(0)
    let colsOfProduct = []

    for (let i = 0; i < totalOrderArr.length; i++) {
      const order = totalOrderArr[i]
      const listTempProName = []
      const listTempProPrice = []
      const subitems = products.find((x) => x.id === order.pid).subItems || []
      subitems.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
      for (let j = 0; j < subitems.length; j++) {
        const subitem = subitems[j]
        const optionsTempName = []
        const optionsTempPrice = []
        const { list, required } = subitem
        if (required === 0) {
          optionsTempName.push('')
          optionsTempPrice.push(0)
        }
        for (let k = 0; k < list.length; k++) {
          const option = list[k]
          optionsTempName.push(option.name)
          optionsTempPrice.push(option.price)
        }
        listTempProName.push(optionsTempName)
        listTempProPrice.push(optionsTempPrice)
      }

      let cartesianProductPrice = []
      if (listTempProPrice.length) {
        cartesianProductPrice = cartesian(listTempProPrice.map((item) => item))
      }

      if (!listTempProName.length) listTempProName.push([order.pName])
      if (!listTempProPrice.length) listTempProPrice.push([order.pPrice])

      const cartesianProductName = cartesian(listTempProName.map((item) => item))

      const _products = []

      for (let l = 0; l < cartesianProductName.length; l++) {
        const productName = cartesianProductName[l].toString()
        let productPrice = 0
        if (cartesianProductPrice.length) {
          productPrice = cartesianProductPrice[l].reduce((a, b) => a + b, 0)
        }
        _products.push({ pid: order.pid, name: productName, price: productPrice })
      }

      colsOfProduct = [
        ...colsOfProduct,
        ..._products.map((product) => {
          if (subitems.length) {
            return {
              ...product,
              name: product.name ? `${order.pName} (${product.name})` : `${order.pName}`,
            }
          }
          return { ...product, name: order.pName }
        }),
      ]

      totalOrderArr[i] = { ...order, subItems: subitems }
    }

    const _totalOrderArr = sortArrayBaseOnAnotherArray(totalOrderArr, [...productIdList])

    const _sortColsOfProduct = sortArrayBaseOnAnotherArray(colsOfProduct, [...productIdList])

    for (let i = 0; i < products.length; i++) {
      const productTemp = products[i]
      for (let j = 0; j < _sortColsOfProduct.length; j++) {
        const product = _sortColsOfProduct[j]
        if (product.pid === productTemp.id) {
          _sortColsOfProduct[j] = { ...product, price: product.price + productTemp.price }
        }
      }
    }

    const groupByOrderWithBuyerID = groupBy(ordersActive, 'uid')
    const listOfBuyerName = Object.keys(groupByOrderWithBuyerID)

    const colProductName = _sortColsOfProduct.map((p) => p.name)
    const colProductNameAndId = _sortColsOfProduct.map((p) => `${p.name} ${p.pid}`)
    const colProductPrice = _sortColsOfProduct.map((p) => `$${p.price}`)

    if (Array.isArray(listOfBuyerName)) {
      for (let i = 0; i < listOfBuyerName.length; i++) {
        const colOrderContact = []
        const buyerID = listOfBuyerName[i]
        const { uPhone: buyerPhone } = groupByOrderWithBuyerID[buyerID][0]

        for (let j = 0; j < groupByOrderWithBuyerID[buyerID].length; j++) {
          const objOrder = groupByOrderWithBuyerID[buyerID][j]
          const { pName, pSubItems, pid } = objOrder

          let productName = `${pName} ${pid}`
          if (pSubItems.length) {
            productName = `${pName} (${pSubItems
              .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
              .map((s) => s.name)
              .toString()}) ${pid}`
          }

          groupByOrderWithBuyerID[buyerID][j] = { ...objOrder, pName: productName }
        }

        const _sum = sum(groupByOrderWithBuyerID[buyerID]).sort((a, b) =>
          a.pName > b.pName ? 1 : b.pName > a.pName ? -1 : 0
        )

        for (let k = 0; k < colProductNameAndId.length; k++) {
          let _quantity = 0
          const productNameAndId = colProductNameAndId[k]
          for (let j = 0; j < _sum.length; j++) {
            const _order = _sum[j]
            if (productNameAndId === _order.pNameAndPid) {
              _quantity += _order.pQuantity
            }
          }
          colOrderContact.push(_quantity)
        }

        const buyerFirstName = groupByOrderWithBuyerID[buyerID][0].uName
          ? groupByOrderWithBuyerID[buyerID][0].uName.split(' ').slice(0, 1)
          : ''
        const buyerLastName = groupByOrderWithBuyerID[buyerID][0].uName
          ? groupByOrderWithBuyerID[buyerID][0].uName.split(' ').slice(1).join(' ')
          : ''

        const comments = groupByOrderWithBuyerID[buyerID].map((order) => order.comment)
        const commentCombined = comments.filter((comment) => comment !== null).join(', ')

        rowsOfContact.push([
          '',
          buyerFirstName,
          buyerLastName,
          buyerPhone,
          commentCombined,
          ...colOrderContact,
        ])
      }
    }

    for (let index = 0; index < _totalOrderArr.length; index++) {
      const totalOrder = _totalOrderArr[index]

      const { pName, count } = totalOrder

      rowsOfTotalOrder[index] = [pName, count]
    }

    const rows = [
      ...generalInfo,
      ['', '', '', '', '', ...colProductPrice],
      ['', 'First Name', 'Last Name', 'Contact', 'Comment', ...colProductName],
      ...rowsOfContact,
      [''],
      ...total,
      ['Total orders'],
      ...rowsOfTotalOrder,
    ]

    const fileName = normalizeName(title)
    exportToCsv(`${fileName}.csv`, rows)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='p-0'>
        <div className='c-form'>
          <div className='form-group container text-center'>
            <h2 className='c-txt-gray'>Select the file format you want to export</h2>
            <div className='row'>
              <div className='col-6'>
                <button onClick={export1} className='btn btn--org btn--org02'>
                  Format 1
                </button>
              </div>
              <div className='col-6'>
                <button onClick={export2} className='btn btn--org btn--org02'>
                  Format 2
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const Navbar = ({
  eventId,
  handelBroadcast,
  status,
  setTimeRender,
  payable,
  paynow,
  isBuyerPaid,
  eventAndOrderById,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const query = useQuery()
  const isByAdmin = query.get('isByAdmin') === 'true'

  const [modal, setModal] = useState(false)
  const [message, setMessage] = useState('')
  const [openModalExport, setOpenModalExport] = useState(false)

  const toggle = () => setModal(!modal)

  const toggleModalExport = () => setOpenModalExport(!openModalExport)

  const onEditPostClicked = () => {
    if (paynow === NOT_PAYNOW && payable === PAYABLE) {
      alert('Not allow to edit event')
    } else {
      delete eventAndOrderById.orders
      for (let i = 0; i < eventAndOrderById.products.length; i++) {
        eventAndOrderById.products[i].pid = eventAndOrderById.products[i].id
      }
      const { closingTime, pickupTime } = eventAndOrderById

      const closingTimeCurrentGMT = convertToCurrentGMT(closingTime)

      const pickupTimeCurrentGMT = convertToCurrentGMT(pickupTime)

      eventAndOrderById = {
        ...eventAndOrderById,
        closingTime: closingTimeCurrentGMT,
        pickupTime: pickupTimeCurrentGMT,
      }
      setCreateEventData(eventAndOrderById)
      history.push(`/event/update?id=${eventId}`)
    }
  }

  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onCloseOrderClicked = async () => {
    try {
      const r = confirm('Are you sure?')
      if (r === true) {
        await eventApi.updateEventStatus(eventId, { status: 0 })
        dispatch(callNewListOrder())
        dispatch(updateStatusEvent())
        dispatch(clearAllEvents())
        dispatch(clearMyEvents())
        setTimeRender((prevState) => prevState + 1)
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onOpenOrderClicked = async () => {
    try {
      if (paynow === PAYNOW || (paynow === NOT_PAYNOW && !isBuyerPaid && payable === NOT_PAYABLE)) {
        const r = confirm('Are you sure?')
        if (r === true) {
          await eventApi.updateEventStatus(eventId, { status: 1 })
          dispatch(updateStatusEvent())
          dispatch(callNewListOrder())
          dispatch(clearAllEvents())
          dispatch(clearMyEvents())
          setTimeRender((prevState) => prevState + 1)
        }
      } else {
        alert('You can not re-open order for this event')
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onCreateNotification = () => {
    handelBroadcast(message)
    toggle()
  }

  const onExportOrder = () => {
    setOpenModalExport(true)
  }

  const disabledStyleActions = {
    background: 'rgb(241, 241, 241)',
    pointerEvents: 'none',
  }

  return (
    <>
      <ul className='nav-tab'>
        <li style={isByAdmin ? disabledStyleActions : {}} onClick={onEditPostClicked}>
          Edit Event
        </li>
        <li style={isByAdmin ? disabledStyleActions : {}} onClick={toggle}>
          Broadcast
        </li>
        {status === EVENT_IS_REOPEN ? (
          <li style={isByAdmin ? disabledStyleActions : {}} onClick={onOpenOrderClicked}>
            Re-open
          </li>
        ) : (
          <li style={isByAdmin ? disabledStyleActions : {}} onClick={onCloseOrderClicked}>
            Close Order
          </li>
        )}
        <li style={isByAdmin ? disabledStyleActions : {}} onClick={onExportOrder}>
          Export Orders
        </li>
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalHeader className='modal-header-created' style={{ margin: '0 auto' }}>
            <span className='c-txt-gra'>Message to Broadcast?</span>
          </ModalHeader>
          <ModalBody>
            <div className='c-form'>
              <div className='form-group'>
                <input
                  type='text'
                  value={message}
                  className='form-control form-control--radius'
                  onChange={onChangeMessage}
                ></input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
              <button className='btn btn--solid btn--solid04' onClick={toggle}>
                CANCEL
              </button>
            </div>
            <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
              <button className='btn btn--org btn--org02' onClick={onCreateNotification}>
                OK
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </ul>
      {eventAndOrderById && (
        <ModalExport
          eventAndOrderById={eventAndOrderById}
          eventId={eventId}
          isOpen={openModalExport}
          toggle={toggleModalExport}
        />
      )}
    </>
  )
}

export default Navbar

Navbar.propTypes = {
  eventId: PropTypes.string,
  handelBroadcast: PropTypes.func,
  status: PropTypes.number,
  setStatusEvent: PropTypes.func,
  payable: PropTypes.number,
  paynow: PropTypes.number,
  isBuyerPaid: PropTypes.bool,
}
