import React, { useContext } from 'react'
import { CollapseContext } from '../../../context/CollapseMenuContext'
import Calendar from '../Calendar'
import LeftMenu from '../LeftMenu'
import './style.scss'

function Sidebar() {
  const { isCollapse } = useContext(CollapseContext)
  return (
    <React.Fragment>
      <div
        className={`${
          isCollapse ? 'menu-shrink' : 'menu-dilate custom-scroll'
        } animate position-fixed w-max-inherit`}
      >
        <div className='row me-0 mb-2'>
          <LeftMenu />
        </div>
        <div className='row wrap-calendar'>
          <Calendar />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
