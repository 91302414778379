/* eslint-disable function-paren-newline */
/* eslint-disable array-callback-return */
import { EVENT_STATUS_OPEN } from '_utils/constant'
import { sortListEvents } from '_utils/function'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import { useEvents } from '../../hooks/useEvents'
import EventItem from '../Event/components/EventItem'
import EventSearch from '../Event/components/EventSearch'
import Pagination from '../Event/components/Pagination'

function ListAllEvent() {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [filterEvents, setFilterEvents] = useState([])

  const {
    getAllEventsOpening,
    loading,
    allEvents,
    currentPage,
    setCurrentPage,
    PageSize,
    totalEvents,
  } = useEvents()

  useEffect(() => {
    dispatch(getAllEventsOpening)
  }, [])

  useEffect(() => {
    if (searchString === '') {
      setFilterEvents(allEvents)
    } else {
      setFilterEvents(
        allEvents?.filter((val) => {
          if (val.title.toLowerCase().includes(searchString.toLowerCase())) {
            return val
          }
        })
      )
    }
  }, [searchString, allEvents])

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  let currentEvents = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return filterEvents.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, filterEvents])

  const isExistInCurrentEvents = filterEvents.some((event) =>
    currentEvents.some((currentEvent) => event.id === currentEvent.id)
  )

  if (searchString.length > 1 && !isExistInCurrentEvents) {
    currentEvents = filterEvents
    if (currentEvents.length > PageSize && filterEvents.length > PageSize) {
      setCurrentPage(1)
    }
  }

  if (loading) {
    return (
      <div
        className='d-flex flex-column align-items-center justify-content-center h-100'
        style={{ color: '#51aaaa' }}
      >
        <Spinner />
        <p className='mt-2'>Loading...</p>
      </div>
    )
  }

  return (
    <div className='row-top event-page container'>
      <EventSearch searchString={searchString} onChange={onChangeSearch} />
      {!loading && currentEvents?.length === 0 ? (
        <div className='text-center mt-3'>
          <p>Not Found Events Are Opening</p>
        </div>
      ) : (
        <div className='group-list group-list--event group-list--event02 pb-4'>
          {loading && <div>Loading...</div>}
          {sortListEvents(currentEvents)
            ?.filter((item) => item.closingTimestamp > moment().unix())
            ?.filter((item) => item.status === EVENT_STATUS_OPEN)
            .map((event, index) => (
              <EventItem event={event} key={index} />
            ))}
          <Pagination
            className='pagination-bar'
            currentPage={currentPage}
            totalCount={totalEvents}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  )
}

export default ListAllEvent
