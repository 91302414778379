import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { callNewListOrder } from '_redux/modules/event'
import { clearMyOrder, clearDataPurchaseDetail } from '_redux/modules/order'

import eventApi from '_api/event'

export default function ModalRequestPayment({ modalRequest, setTimeRender,
  toggleRequest, eventAndOrderById }) {
  const [checkbox, setCheckbox] = useState(false)
  const onClickCheckbox = () => setCheckbox(!checkbox)
  const { addToast } = useToasts()
  const { id } = eventAndOrderById
  const dispatch = useDispatch()
  const requestUserPayment = async (e) => {
    e.preventDefault()
    try {
      dispatch(clearDataPurchaseDetail())
      dispatch(clearMyOrder())
      await eventApi.updateEventRequestPaymentAndNotify(id, {
        payable: 1,
        notify: checkbox ? 1 : 0
      })
      dispatch(callNewListOrder())
      setTimeRender((prevState) => prevState + 1)
      toggleRequest()
    } catch (error) {
      addToast(error, { appearance: 'error', autoDismiss: true })
    }
  }
  return (
    <div>
      <Modal isOpen={modalRequest} toggle={toggleRequest} centered>
        <ModalBody style={{ textAlign: 'center', padding: 0 }}>
          Request Payment
          <br />
          <strong>Delivery</strong> and <strong>Discount</strong> cannot be amended once
          payment is made.
          <br />
          <br />
          <label><input id="notifyPayable" type="checkbox" defaultChecked={checkbox} onChange={onClickCheckbox} />
            <small style={{ marginLeft: 5 }}>Notify buyers</small></label>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row', border: 0 }}>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <button className='btn btn--solid btn--solid04' onClick={toggleRequest}>
              CANCEL
            </button>
          </div>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <button
              className='btn btn--org btn--org02'
              onClick={requestUserPayment}
            >
              PROCEED
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
