import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import { clearGroup, createGroup } from '_redux/modules/group'

import uploadApi from '_api/upload'
import GroupDescription from '../components/GroupDescription'
import GroupName from '../components/GroupName'
import GroupQuestion from '../components/GroupQuestion'
import GroupSetting from '../components/GroupSetting'

import CreatedModal from './CreatedModal'

import './style.scss'

const validationSchema = Yup.object().shape({
  groupName: Yup.string('Invalid name').required('Invalid name'),
  groupDescription: Yup.string('Invalid description').required('Invalid description'),
  groupQuestion: Yup.string().nullable().notRequired(),
  passCode: Yup.string().nullable().notRequired(),
})

const CreateGroup = () => {
  const dispatch = useDispatch()

  const [groupImg, setGroupImg] = useState('')
  const [groupImgUrl, setGroupUrl] = useState('')
  const [groupType, setGroupType] = useState(0)
  const [defaultRole, setDefaultRole] = useState(1)
  const [autoApproval, setAutoApproval] = useState(0)
  const [accessMemberList, setAccessMemberList] = useState(1)
  const [errorMessage, setErrorMessage] = useState('')
  const [processing, setProcessing] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onGroupImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setGroupUrl(URL.createObjectURL(img))
      setGroupImg(files)
    }
  }

  const onChangeRadio = (state, value) => {
    if (state === 'groupType') setGroupType(value)
    else if (state === 'defaultRole') setDefaultRole(value)
    else if (state === 'accessMemberList') setAccessMemberList(value)
    else setAutoApproval(value)
  }

  const onSubmit = async (data) => {
    setProcessing(true)
    const postData = {
      name: data.groupName.trim(),
      description: data.groupDescription,
      photoUrl: null,
      passcode: data.passCode,
      question: data.groupQuestion,
      public: groupType,
      defaultRole,
      autoApproval,
      accessMemberList,
    }
    if (groupImg) {
      const { msgResp: res } = await uploadApi.uploadPhoto(groupImg)
      postData.photoUrl = res.url
    }
    try {
      const createGroupThunk = createGroup(postData)
      await dispatch(createGroupThunk)
    } catch (err) {
      setProcessing(false)
      setErrorMessage(err.msgResp)
    }
  }

  useEffect(
    () => () => {
      dispatch(clearGroup())
    },
    []
  )

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className='container'>
        <div className='c-form c-form--group mt-2 create-group'>
          <div className='row'>
            <div className='col-4'>
              <div className='upload-image'>
                <img
                  src={groupImgUrl?.url || groupImgUrl}
                  alt=''
                  className='w-100 h-100'
                  style={{ zIndex: 3, objectFit: 'cover' }}
                />
                <div className='upload-image-txt'>
                  <i className='icon-capture'></i>
                  <span>
                    Add Profile
                    <br />
                    Picture
                  </span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        type='file'
                        accept='image/*'
                        name='file_source_01'
                        size='40'
                        className='inputFile'
                        data-error='#error-file_source_01'
                        onChange={onGroupImageUploaded}
                        style={{ zIndex: '999' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-8'>
              <GroupName register={register('groupName')} error={errors?.groupName} />
              <GroupDescription
                register={register('groupDescription')}
                error={errors?.groupDescription}
              />
            </div>
          </div>
          <div className='box-question'>
            <GroupQuestion register={register('groupQuestion')} error={errors?.groupQuestion} />
            <GroupSetting
              groupType={groupType}
              defaultRole={defaultRole}
              autoApproval={autoApproval}
              onChangeRadio={onChangeRadio}
              accessMemberList={accessMemberList}
              register={register('passCode')}
            />
          </div>
        </div>
        <div className='row row--bot align-self-end'>
          <small className='errorMessage'>{errorMessage || errors?.passCode?.message}</small>
          <div className='col-6 m-auto'>
            <button disabled={processing} className='btn btn--main'>
              {processing ? <Spinner color='light' size='sm' /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
      <CreatedModal />
    </React.Fragment>
  )
}

export default CreateGroup
