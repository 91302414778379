import React from 'react'
import SaleChart from './components/SaleChart'
import Top10BuyersChart from './components/Top10BuyersChart'
import Top10EventsChart from './components/Top10EventsChart'
import TopMembers from './components/TopMembers'

function Dashboard2({ listTotalSaleAfterSort }) {
  return (
    <div className='dashboard__desktop my-5'>
      <div className='row chart-layout'>
      <div className='dashboard__desktop-order-chart'>
          <p>Top 10 buyers</p>
          <SaleChart data={listTotalSaleAfterSort} />
        </div>
        <div className='dashboard__desktop-event-chart'>
          <p>Top 10 events </p>
          <Top10EventsChart />
        </div>
      </div>
      <div className='row chart-layout'>
        <div className='dashboard__desktop-buyer-chart'>
          <p>Top 10 buyers</p>
          <Top10BuyersChart />
        </div>
        <div className=''>
          <TopMembers />
        </div>
      </div>
    </div>
  )
}

export default Dashboard2
