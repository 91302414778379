import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal } from 'reactstrap'

import groupIcon from '_images/group-icon.png'

const ModalJoin = ({
  modal,
  toggle,
  groupInfo,
  passCodeValue,
  setPassCodeValue,
  onSubmitJoin,
  questionValue,
  setQuestionValue,
}) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const handleOnJoin = () => {
    setIsSubmit(true)
    onSubmitJoin()
  }

  const renderNotify = () => (
    <div className='welcome-group'>
      <div className='c-form mt-4 mb-3'>
        <div className='form-group'>
          <h3>Request submitted</h3>
          <small className='passcode'>
            You will be notified through email once the access has been granted
          </small>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-8'>
          <button className='btn btn--org btn--org02' onClick={toggle}>
            OK
          </button>
        </div>
      </div>
    </div>
  )

  const renderForm = () => (
    <div className='welcome-group'>
      <div className='welcome-group-top'>
        <h2 className='text-center c-ttl c-ttl--01'>{groupInfo?.name}</h2>
        <div className='welcome-group__img'>
          <div className='c-image'>
            <img src={groupInfo?.photoUrl || groupIcon} alt='' />
          </div>
        </div>
        <p className='mb-1'>{groupInfo?.name}</p>
        <div className='welcome-number mb-3'>
          <ul>
            <li>
              <span>{groupInfo?.totalEvents || 0}</span>
              <span>EVENT HOSTED</span>
            </li>
            <li>
              <span>{groupInfo?.totalMembers || 1}</span>
              <span>MEMBERS</span>
            </li>
          </ul>
        </div>
      </div>
      <div className='c-form mt-4 mb-3'>
        {groupInfo?.question && (
          <div className='form-group'>
            <h2 className='c-txt-gray'>Question {groupInfo?.question}</h2>
            <input
              type='text'
              className='form-control form-control--radius'
              value={questionValue}
              onChange={(e) => setQuestionValue(e.target.value)}
            />
          </div>
        )}
        <div className='form-group'>
          <h2 className='c-txt-gray'>Passcode</h2>
          <small className='passcode'>Leave it blank if you don&apos;t have passcode</small>
          <input
            type='text'
            className='form-control form-control--radius'
            value={passCodeValue}
            onChange={(e) => setPassCodeValue(e.target.value)}
          />
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-8'>
          <button className='btn btn--org btn--org02' onClick={handleOnJoin}>
            JOIN
          </button>
        </div>
      </div>
    </div>
  )
  return (
    <Modal isOpen={modal} toggle={toggle} centered className='search-modal'>
      {isSubmit ? renderNotify() : renderForm()}
    </Modal>
  )
}

ModalJoin.propTypes = {
  groupInfo: PropTypes.shape({
    events: PropTypes.array,
    members: PropTypes.array,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    question: PropTypes.string,
  }),
  modal: PropTypes.bool,
  onSubmitJoin: PropTypes.func,
  passCodeValue: PropTypes.string,
  questionValue: PropTypes.any,
  setPassCodeValue: PropTypes.func,
  setQuestionValue: PropTypes.func,
  toggle: PropTypes.func,
}

export default ModalJoin
