/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import useQuery from '_hooks/useQuery'
import { getGroupWithEventById } from '_redux/modules/group'
import { PAYMENT_STATUS_PAID, STATUS_PENDING } from '_utils/constant'
import { sortListEventByClosingDate } from '_utils/function'
import { getUserInfo } from '_utils/localData'

import GroupEventItem from '../components/GroupEventItem'

import GroupDetail from '../components/GroupDetail'
import './style.scss'

const ListEvents = () => {
  const params = useQuery()
  const dispatch = useDispatch()
  const idGroupInParam = params.get('groupId')
  const [isLoading, setIsLoading] = useState(false)

  const [domain, setDomain] = useState({})

  const { groupById = {} } = useSelector((state) => state.group)

  const currentUser = getUserInfo()

  const isAllowHost =
    currentUser?.groups?.length && currentUser?.groups?.some((g) => g?.isHost || g?.isAdmin)

  useEffect(() => {
    const getGroup = async () => {
      setIsLoading(true)
      await dispatch(getGroupWithEventById(idGroupInParam))
      setIsLoading(false)
    }

    getGroup()
  }, [])

  useEffect(() => {
    if (currentUser) {
      const { domainFeatures = [] } = currentUser
      if (domainFeatures.length) {
        const domainFeaturePaid = domainFeatures.filter(
          (item) =>
            item.domainPaymentStatus >= PAYMENT_STATUS_PAID &&
            item.domainStatus >= STATUS_PENDING &&
            item.groupId === idGroupInParam
        )
        if (domainFeaturePaid.length) {
          setDomain(domainFeaturePaid[0])
        } else {
          setDomain({})
        }
      } else {
        setDomain({})
      }
    }
  }, [])

  const sortedEvents = groupById?.events?.sort((a, b) => a?.createdAt - b?.createdAt)

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className='row-top list-events-page'>
      <GroupDetail
        idGroupInParam={idGroupInParam}
        groupById={groupById}
        domain={domain}
        setDomain={setDomain}
      />
      <div className='news-group'>
        {isAllowHost && (
          <div className='text-right'>
            <Link
              to={`/event/create?groupId=${groupById?.id}`}
              className='c-txt-14 text-underline'
              style={{ display: 'block' }}
            >
              Host an Event
            </Link>
          </div>
        )}
        <div className='row mt-2'>
          {sortListEventByClosingDate(sortedEvents)?.map((item, index) => (
            <GroupEventItem eventInfo={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListEvents
