import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { Link, useHistory } from 'react-router-dom'
import { LIMIT_TEXT } from '_utils/constant'
import { getUserToken } from '_utils/localData'
import { shortDescription, convertToCurrentGMT } from '_utils/function'
import HeaderEventInfo from '../components/HeaderEventInfo'

export default function EventItem({ event }) {
  const history = useHistory()
  const userToken = getUserToken()
  const [isShowMore, setIsShowMore] = useState(false)
  const showMore = () => {
    setIsShowMore(true)
  }
  const listImages = event?.photoUrls?.map((item) => ({ original: item })) || []

  const directToEventDetail = () => {
    if (!userToken) {
      return '/login'
    }

    return `/event/detail?eid=${event?.id}`
  }
  return (
    <div className='group-list__item'>
      <HeaderEventInfo
        createdUserPhotoUrl={event?.createdUserPhotoUrl}
        createdUserName={event?.createdUserName}
        eClosingTime={convertToCurrentGMT(event?.closingTime)}
        eTotalOrderCount={event?.totalOrderCount}
        groupName={event?.groupName}
      />
      <div className='group-list__des'>
        {listImages?.length > 0 && (
          <ReactImageGallery
            items={listImages}
            lazyLoad
            autoPlay
            showPlayButton={false}
            showNav={false}
            showBullets={true}
            showThumbnails={false}
            onClick={() => history.push(`/event/detail?eid=${event?.id}`)}
          />
        )}

        <h4>
          <Link to={directToEventDetail}>{event?.title}</Link>
        </h4>
        <p className='paragraph-pre-wrap'>
          <Link to={directToEventDetail}>
            {isShowMore
              ? event?.description
              : shortDescription({ description: event?.description })}
          </Link>
          {!isShowMore &&
          event?.description &&
          event?.description.split(' ').length > LIMIT_TEXT ? (
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }}
              onClick={showMore}
            >
              More
            </span>
          ) : null}
        </p>
      </div>
    </div>
  )
}

EventItem.propTypes = {
  event: PropTypes.shape({
    createdUserId: PropTypes.string,
    groupId: PropTypes.string,
  }),
}
