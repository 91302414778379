/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { getAllGroups, joinGroup } from '_redux/modules/group'
import { calculatePercentMatch } from '_utils/function'
import { useDispatch, useSelector } from 'react-redux'

import GroupItem from '../components/GroupItem'
import GroupSearch from '../components/GroupSearch'
import ModalJoin from '../components/ModalJoin'

import './style.scss'

const SearchGroup = () => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')

  const [passCodeValue, setPassCodeValue] = useState('')
  const [questionValue, setQuestionValue] = useState('')
  const [groupSelected, setGroupSelected] = useState(null)
  const { allGroups } = useSelector((state) => state.group)

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitJoin = async () => {
    try {
      const postData = {
        groupId: groupSelected.id,
        question: groupSelected.question,
        answer: questionValue,
        passcode: passCodeValue,
      }
      dispatch(joinGroup(postData))
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  useEffect(() => {
    setIsLoading(true)
    if (allGroups === null) dispatch(getAllGroups())
    setIsLoading(false)
  }, [])

  return (
    <div className='search-page'>
      <div className='box-center pt-3'>
        <p className='c-txt-12'>
          To start co-bee, find a group in your neighbourhood to start your fun in group buy. You
          can search for a Group you know or you could start with Co-bee Recommended groups.
        </p>
        <div className='c-form c-form--search'>
          <h2 className='c-ttl c-ttl--01 text-center'>Search for a Group to Join</h2>
          <GroupSearch searchString={searchString} onChange={onChangeSearch} />
        </div>
        <div className='group-list'>
          <h2 className='c-ttl c-ttl--01 text-center'>Groups</h2>
          <div className='tempItem'>
            {isLoading && <div>Loading...</div>}
            {searchString === '' ? (
              <></>
            ) : (
              allGroups
                ?.filter((val) => {
                  if (searchString === '') {
                    return val
                  }
                  if (calculatePercentMatch(val.name.trim(), searchString.trim()) >= 0.4) {
                    return val
                  }
                })
                .map((item, index) => (
                  <GroupItem
                    groupInfo={item}
                    isJoin
                    key={index}
                    setGroupSelected={setGroupSelected}
                    toggleModal={toggle}
                    requested={item.request === 3 && true}
                    disabled={true}
                  />
                ))
            )}
          </div>
        </div>
      </div>
      <div className='row row--bot align-self-end'>
        <div className='col-12'>
          <Link className='btn btn--main' to='/group/list-my-groups'>
            Done
          </Link>
        </div>
      </div>
      <ModalJoin
        groupInfo={groupSelected}
        modal={modal}
        toggle={toggle}
        onSubmitJoin={onSubmitJoin}
        passCodeValue={passCodeValue}
        setPassCodeValue={setPassCodeValue}
        questionValue={questionValue}
        setQuestionValue={setQuestionValue}
      />
    </div>
  )
}

export default SearchGroup
