/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import serviceApi from '_api/service'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import bgAvatar from '_images/bg_avatar.png'
import {
  DESKTOP,
  GROUP_IS_ADMIN,
  GROUP_IS_HOST,
  PAYMENT_STATUS_PAID,
  PHONE,
  STATUS_PAID,
  STATUS_PENDING,
} from '_utils/constant'
import { getUserInfo, setDeviceUser, setUserInfo } from '_utils/localData'
import ModalGeneralPayment from '../../components/ModalGeneralPayment'
import { DeviceUserContext } from '../../context/DeviceUserContext'
import ChangePasswordPage from './ChangePassword'
import Avatar from './components/Avatar'
import Invoices from './Invoices'
import NotificationsPage from './Notifications'
import './style.scss'
import UpdateProfilePage from './UpdateProfile'

const PAYMENT_SERVICE_DESKTOP_PRICE = 50

const Index = () => {
  const { addToast } = useToasts()
  const [currentUser, setCurrentUser] = useState({})
  const { checkDeviceUser, setCheckDeviceUser } = useContext(DeviceUserContext)
  const [checkIsAdminOfGroup, setIsCheckAdminOfGroup] = useState()
  const [isShow, setIsShow] = useState(false)
  const [paymentFileUrl, setPaymentFileUrl] = useState()
  const [paymentFileUpload, setPaymentFileUpload] = useState()
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [registerPremiumAccount, setRegisterPremiumAccount] = useState(false)
  const [isCheckPremiumFeature, setIsCheckPremiumFeature] = useState(false)
  const [isUpdateListPaymentOfUser, setIsUpdateListPaymentOfUser] = useState()
  const toggle = () => {
    setIsShow(!isShow)
  }

  const handleSwitchVersion = () => {
    if (screen.width >= 1203) {
      if (isCheckPremiumFeature) {
        setDeviceUser(checkDeviceUser === DESKTOP ? PHONE : DESKTOP)
        setCheckDeviceUser(checkDeviceUser === DESKTOP ? PHONE : DESKTOP)
      } else {
        addToast('You have not activated this feature yet', {
          appearance: 'warning',
          autoDismiss: true,
        })
      }
    } else {
      addToast('desktop model only use for device desktop', {
        appearance: 'warning',
        autoDismiss: true,
      })
    }
  }

  const onUserImageUploaded = (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPaymentFileUrl(URL.createObjectURL(img))
      setPaymentFileUpload(files)
    }
  }

  const routes = [
    {
      id: 0,
      link: '/user/profile',
      label: 'Notification',
      icon: 'icon-noti',
    },
    {
      id: 1,
      link: '/user/update',
      label: 'Edit Profile',
      icon: 'icon-profile',
    },
    {
      id: 2,
      link: '/user/change-password',
      label: 'PIN Update',
      icon: 'icon-pin',
    },
    {
      id: 3,
      link: '/user/invoices',
      label: 'Invoices',
      icon: 'icon-invoice',
    },
  ]

  useEffect(() => {
    setCurrentUser(getUserInfo())
  }, [])

  useEffect(() => {
    const isAdminOfGroup = currentUser?.groups?.some((item) => item.isAdmin === GROUP_IS_ADMIN)
    const isHostOfGroup = currentUser?.groups?.some((item) => item.isHost === GROUP_IS_HOST)
    setIsCheckAdminOfGroup(isAdminOfGroup || isHostOfGroup)
    setRegisterPremiumAccount(false)
    if (currentUser) {
      const { premiumFeatures = [] } = currentUser
      if (premiumFeatures.length) {
        const premiumFeaturePaid = premiumFeatures.filter(
          (item) =>
            item.premiumPaymentStatus >= PAYMENT_STATUS_PAID && item.premiumStatus >= STATUS_PENDING
        )
        const checkUserHavePremiumFeature = premiumFeatures.filter(
          (item) => item.premiumStatus === STATUS_PAID
        )

        if (checkUserHavePremiumFeature.length) {
          setIsCheckPremiumFeature(true)
        }

        if (premiumFeaturePaid.length) {
          setRegisterPremiumAccount(false)
        } else {
          setRegisterPremiumAccount(true)
        }
      } else {
        setRegisterPremiumAccount(true)
      }
    }
  }, [currentUser])

  if (!Object.keys(currentUser).length) return <div>Loading...</div>

  const { name, photoUrl, id } = currentUser
  const avatarUrl = photoUrl || process.env.REACT_APP_AVATAR_URL + name

  const confirmPayment = async (e) => {
    e.preventDefault()
    setIsSubmittedPayment(true)
    let url = paymentFileUrl
    if (paymentFileUpload) {
      const { msgResp: res } = await uploadApi.uploadPhoto(paymentFileUpload)
      // eslint-disable-next-line prefer-destructuring
      url = res.url
    }
    if (url) {
      try {
        await serviceApi.purchasePaymentPremium(id, {
          premiumPaymentScreenshot: url,
          premiumPrice: PAYMENT_SERVICE_DESKTOP_PRICE,
        })
        setIsUpdateListPaymentOfUser(!registerPremiumAccount)
        userApi
          .getUser()
          .then(({ msgResp }) => {
            const { premiumFeatures = [] } = msgResp
            if (premiumFeatures.length) {
              const premiumFeaturePaid = premiumFeatures.filter(
                (item) =>
                  item.premiumPaymentStatus >= PAYMENT_STATUS_PAID &&
                  item.premiumStatus >= STATUS_PENDING
              )
              if (premiumFeaturePaid.length) {
                setRegisterPremiumAccount(false)
              } else {
                setRegisterPremiumAccount(true)
              }
            } else {
              setRegisterPremiumAccount(true)
            }
            setUserInfo(msgResp)
          })
          .catch(({ msgResp }) => {
            addToast(msgResp, { appearance: 'error', autoDismiss: true })
          })

        setIsSubmittedPayment(false)
        toggle()
      } catch (error) {
        setIsSubmittedPayment(false)
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      setIsSubmittedPayment(false)
      alert('Please upload payment screenshot')
    }
  }
  return (
    <>
      <div className='profile-page'>
        <div className='profile-avatar-bg'>
          <img src={bgAvatar} alt='' />
        </div>
        <div className='c-form c-form--profile'>
          <Avatar photoUrl={avatarUrl} />
          <div className='upload-name'>{name}</div>
          <div className='upgrade-premium'>
            {/* {checkIsAdminOfGroup && registerPremiumAccount ? (
              <p className='upgrade-premium-btn' onClick={toggle}>
                Upgrade premium account
              </p>
            ) : (
              ''
            )} */}

            {/* {isCheckPremiumFeature ? (
              <p onClick={handleSwitchVersion}>
                {checkDeviceUser === DESKTOP
                  ? 'Switch version mobile model'
                  : 'Switch version desktop model'}
              </p>
            ) : (
              ''
            )} */}
          </div>
          <div className='nav-profile'>
            <ul className='justify-content-center'>
              {routes.map((route) => (
                <li key={route.id}>
                  <Link to={route.link}>
                    <i className={route.icon}></i>
                    <span>{route.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Switch>
          <Route path='/user/profile' component={NotificationsPage} />
          <Route path='/user/update' component={UpdateProfilePage} />
          <Route path='/user/change-password' component={ChangePasswordPage} />
          <Route
            path='/user/invoices'
            component={() => (
              <Invoices
                isUpdateListPaymentOfUser={isUpdateListPaymentOfUser}
                setRegisterPremiumAccount={setRegisterPremiumAccount}
                setIsCheckPremiumFeature={setIsCheckPremiumFeature}
              />
            )}
          />
        </Switch>
      </div>
      <ModalGeneralPayment
        isShow={isShow}
        toggle={toggle}
        onUserImageUploaded={onUserImageUploaded}
        paymentFileUrl={paymentFileUrl}
        isSubmittedPayment={isSubmittedPayment}
        confirmPayment={confirmPayment}
        service={{ type: 'Upgrade premium account', price: 50 }}
      />
    </>
  )
}

export default Index
