import { Modal } from 'reactstrap'
import {
  AVAILABLE_STOCK_DEFAULT,
  LIMIT_PER_ORDER_DEFAULT,
  MINIMUM_ORDER_PER_PRODUCT,
} from '_utils/constant'
import InputProduct from './InputProduct'

const ProductSettingModal = (props) => {
  const { isOpen, toggle, register, productIndex, errors } = props

  const isError = !!errors?.products
  const handleToggle = () => {
    if (errors && errors?.length > 0 && Object.entries(errors?.products).length !== 0) {
      return
    }
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} centered className='product-setting'>
      <div className='container'>
        <p className='c-txt-org text-center'>Item Options</p>
        <div className='c-form'>
          <div className='form-group'>
            <div className='row'>
              <label className='col-5 align-self-center'>Available stock</label>
              <div className='col-7'>
                <InputProduct
                  type='number'
                  className={`form-control ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.maxQuantity &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.maxQuantity`)}
                  defaultValue={AVAILABLE_STOCK_DEFAULT}
                  error={errors?.products?.[productIndex]?.maxQuantity?.message}
                />
              </div>
              <label className='col-5 align-self-center'>Limit per order</label>
              <div className='col-7'>
                <InputProduct
                  type='number'
                  className={`form-control ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.limitPerOrder &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.limitPerOrder`)}
                  defaultValue={LIMIT_PER_ORDER_DEFAULT}
                  error={errors?.products?.[productIndex]?.limitPerOrder?.message}
                />
              </div>
              <label className='col-5 align-self-center'>Minimum per order</label>
              <div className='col-7'>
                <InputProduct
                  type='number'
                  className={`form-control ${
                    errors?.products &&
                    Object.entries(errors?.products).length !== 0 &&
                    errors?.products[productIndex]?.minOrderQty &&
                    'is-error'
                  }`}
                  register={register(`products.${productIndex}.minOrderQty`)}
                  defaultValue={MINIMUM_ORDER_PER_PRODUCT}
                  error={errors?.products?.[productIndex]?.minOrderQty?.message}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-6 m-auto'>
            <button disabled={isError} className='btn btn--org btn--org02' onClick={handleToggle}>
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProductSettingModal
