import { yupResolver } from '@hookform/resolvers/yup'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form, Spinner } from 'reactstrap'
import * as Yup from 'yup'

import { useDispatch } from 'react-redux'
import groupApi from '_api/group'
import { updateGroup } from '_redux/modules/group'
import { getGroupIdSelected } from '_utils/localData'

import uploadApi from '_api/upload'
import WithErrorBoundary from '_components/WithErrorBoundary'
import { SelectIdGroupContext } from '../../context/SelectGroupContext'
import GroupDescription from './components/GroupDescription'
import GroupName from './components/GroupName'
import GroupQuestion from './components/GroupQuestion'
import GroupSetting from './components/GroupSetting'

const Settings = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [groupId, setGroupId] = useState(getGroupIdSelected())
  const { infoGroupSelected } = useContext(SelectIdGroupContext)
  const [myGroups, setMyGroups] = useState()
  const [groupInfo, setGroupInfo] = useState()

  useEffect(() => {
    setGroupId(infoGroupSelected?.groupId)
  }, [infoGroupSelected])

  useEffect(async () => {
    if (groupId !== undefined) {
      const { msgResp: groupData } = await groupApi.getById(groupId)
      if (groupData) {
        setGroupInfo(groupData)
        setMyGroups(groupData)
      }
    }
  }, [groupId])

  const validationSchema = Yup.object().shape({
    groupName: Yup.string('Invalid name').required('Invalid name'),
    groupDescription: Yup.string('Invalid description').required('Invalid description'),
    groupQuestion: Yup.string().nullable().notRequired(),
    passCode: Yup.string().nullable().notRequired(),
  })
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      groupName: groupInfo?.name,
      groupDescription: groupInfo?.description,
      groupQuestion: groupInfo?.question,
      passCode: groupInfo?.passcode,
    },
  })
  const [groupImg, setGroupImg] = useState('')
  const [groupImgUrl, setGroupUrl] = useState(groupInfo?.photoUrl)
  const [groupType, setGroupType] = useState(groupInfo?.public)
  const [defaultRole, setDefaultRole] = useState(groupInfo?.defaultRole)
  const [autoApproval, setAutoApproval] = useState(groupInfo?.autoApproval)
  const [accessMemberList, setAccessMemberList] = useState(groupInfo?.accessMemberList)

  const onGroupImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setGroupUrl(URL.createObjectURL(img))
      setGroupImg(files)
    }
  }

  const onChangeRadio = (state, value) => {
    if (state === 'groupType') setGroupType(value)
    else if (state === 'defaultRole') setDefaultRole(value)
    else if (state === 'accessMemberList') setAccessMemberList(value)
    else setAutoApproval(value)
  }

  const onSubmit = async (data) => {
    const postData = {
      name: data.groupName,
      description: data.groupDescription,
      photoUrl: groupImgUrl,
      passcode: data.passCode,
      question: data.groupQuestion,
      public: groupType,
      defaultRole,
      autoApproval,
      accessMemberList,
    }
    if (groupImg) {
      const { msgResp: res } = await uploadApi.uploadPhoto(groupImg)
      postData.photoUrl = res.url
    }
    try {
      await dispatch(updateGroup({ id: groupId, postData }))
      addToast('Update group successfully', { appearance: 'success', autoDismiss: true })
      history.push('/')
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  useEffect(() => {
    const groupNeed = myGroups
    setGroupInfo(groupNeed)
    setValue('groupName', groupNeed?.name)
    setValue('groupDescription', groupNeed?.description)
    setValue('groupQuestion', groupNeed?.question)
    setValue('passCode', groupNeed?.passcode)
    setGroupUrl(groupNeed?.photoUrl)
    setGroupType(groupNeed?.public)
    setDefaultRole(groupNeed?.defaultRole)
    setAutoApproval(groupNeed?.autoApproval)
    setAccessMemberList(groupNeed?.accessMemberList)
  }, [myGroups])

  return (
    <div className='size-width-desktop-col-1 mb-3'>
      <Form onSubmit={handleSubmit(onSubmit)} className='update-group-page'>
        <div className='c-form c-form--group mt-2'>
          <div className='row'>
            <div className='col-4'>
              <div className='upload-image'>
                <img src={groupImgUrl?.url || groupImgUrl} alt='' style={{ zIndex: 3 }} />
                <div className='upload-image-txt'>
                  <i className='icon-capture'></i>
                  <span>
                    Add Profile
                    <br />
                    Picture
                  </span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        type='file'
                        accept='image/*'
                        name='file_source_01'
                        size='40'
                        className='inputFile'
                        data-error='#error-file_source_01'
                        onChange={onGroupImageUploaded}
                        style={{ zIndex: '999' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-8'>
              <GroupName register={register('groupName')} error={errors?.groupName} />
              <GroupDescription
                register={register('groupDescription')}
                error={errors?.groupDescription}
              />
            </div>
          </div>
          <div className='box-question'>
            <GroupQuestion register={register('groupQuestion')} error={errors?.groupQuestion} />
            <GroupSetting
              groupType={groupType}
              defaultRole={defaultRole}
              autoApproval={autoApproval}
              onChangeRadio={onChangeRadio}
              accessMemberList={accessMemberList}
              register={register('passCode')}
            />
          </div>
        </div>
        <div className='row row--bot align-self-end mt-3'>
          <div className='col-6 m-auto'>
            <button className='btn btn--main' type='submit' disabled={isSubmitting}>
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default WithErrorBoundary(Settings)
