import React from 'react'
import { FaTruck } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { getDateRemainFromNow } from '_utils/function'

export default function BoxDeliveryDateByHost({
  createdUserPhotoUrl,
  createdUserName,
  groupName,
  deliveryTime,
  deliveryTimeString,
  deliveryId,
  greyOut = false,
}) {
  const history = useHistory()
  const onLinkToListDelivery = () => {
    history.push(`/event/manage-delivery?id=${deliveryId}`)
  }

  return (
    <div
      className='event-item group-list group-list--event group-list--event01'
      style={
        greyOut
          ? { cursor: 'pointer', backgroundColor: '#dee2e6', filter: 'grayscale(100%)' }
          : { cursor: 'pointer', backgroundColor: '#B8F0EF' }
      }
      onClick={onLinkToListDelivery}
    >
      <div
        className='group-list__item'
        style={greyOut ? { backgroundColor: '#dee2e6' } : { backgroundColor: '#B8F0EF' }}
      >
        <div className='group-list__img'>
          <div className='c-image'>
            <a href='' onClick={(e) => e.preventDefault()}>
              <img
                src={createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName}
                alt=''
              />
            </a>
          </div>
        </div>
        <div className='group-list__info'>
          <h3>
            <a href='' onClick={(e) => e.preventDefault()}>
              {createdUserName}
            </a>
          </h3>
          <p>{groupName}</p>
        </div>
        <div className='group-list__btn text-right' style={{ lineHeight: '1rem' }}>
          <span style={{ fontSize: '1.5rem', display: 'block' }}>
            <FaTruck />
          </span>
          <span style={{ fontSize: '0.6875rem' }}>{`Delivery in ${getDateRemainFromNow(
            deliveryTime
          )}`}</span>
        </div>
      </div>
      <div className='group-list__des text-color-blue'>
        <div>Delivery for {deliveryTimeString}</div>
      </div>
    </div>
  )
}
