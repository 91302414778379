/* eslint-disable no-multi-assign */
/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import ReactImageGallery from 'react-image-gallery'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import deliveryApi from '_api/delivery'
import eventApi from '_api/event'

import { clearDeliveryCreatedByHost } from '_redux/modules/delivery'
import {
  clearAllEvents,
  clearEventAndOrder,
  clearMyEvents,
  clearScheduledEvent,
} from '_redux/modules/event'
import {
  appendNewEventToGroup,
  clearPastPurchasedMember,
  getMyGroups,
  updateEventInGroup,
} from '_redux/modules/group'
import { clearDataPurchaseDetail, clearMyOrder } from '_redux/modules/order'
import {
  DELIVERY_CLOSED,
  DELIVERY_OPENED,
  DESKTOP,
  PAYMENT_STATUS_VERIFY,
  STATUS_PAID,
} from '_utils/constant'
import { convertToGMT0, getDateRemainFromNow } from '_utils/function'
import {
  getCreateEventData,
  getDeviceUser,
  getUserInfo,
  removeCreateEventData,
  setCreateEventData,
} from '_utils/localData'

import ModalSchedule from '../components/ModalSchedule'
import ProductConfirm from '../components/ProductConfirm'
import './style.scss'

const Confirm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const refSubmitSchedule = useRef(null)
  const refSubmitEvent = useRef(null)
  const { myGroups } = useSelector((state) => state.group)
  const { addToast } = useToasts()
  const userInfo = getUserInfo()
  const { id: userId } = userInfo
  const createEventData = getCreateEventData()

  if (!createEventData) {
    history.push('/event/create')
  }

  const groupName = myGroups?.filter((group) => group.id === createEventData?.groupId)[0]?.name

  const listImages =
    createEventData?.photoUrls?.map((item) => ({
      original: item,
    })) || []

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: createEventData,
  })
  const { fields } = useFieldArray({
    control,
    name: 'products',
  })
  const watchFieldArray = watch('products')
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))
  const totalPrice = () => {
    let amount = 0
    for (let i = 0; i < controlledFields.length; i++) {
      const item = controlledFields[i]
      if (item) {
        amount += item.defaultQuantity * parseFloat(item.price)

        if (item.defaultQuantity > 0 && Array.isArray(item.subItems)) {
          for (let j = 0; j < item.subItems.length; j++) {
            const subItem = item.subItems[j]

            const sum = subItem.list.reduce((total, currentValue) => total + currentValue.price, 0)

            amount += sum * item.defaultQuantity
          }
        }
      }
    }
    return amount
  }

  const [openDate, setOpenDate] = useState(new Date(new Date().getTime() + 24 * 3 * 60 * 60 * 1000))
  const [openTime, setOpenTime] = useState('06:00')
  const [totalPriceValue, setTotalPrice] = useState(totalPrice())
  const [showScheduleEvent, setShowScheduleEvent] = useState(false)
  const [isSubmitScheduled, setIsSubmitScheduled] = useState(false)
  // const [registeredScheduleEvent, setRegisteredScheduleEvent] = useState(false)
  const [modalSchedule, setModalSchedule] = useState(false)
  const toggleSchedule = () => setModalSchedule(!modalSchedule)

  const handleOnSubmitEvent = handleSubmit(async (data) => {
    const {
      products,
      deliveryOption = {},
      closingTime,
      pickupTime,
      addDelivery,
      deliveryTime,
    } = data

    const new2 = products?.map((product) => ({
      ...product,
      id: product.pid,
      subItems: product.subItems?.map((sub) => ({
        ...sub,
        required: sub.required ? 1 : 0,
      })),
    }))

    const pickupTimeGMT0 = convertToGMT0(pickupTime)
    const closingTimeGMT0 = convertToGMT0(closingTime)
    const deliveryTimeGMT0 = convertToGMT0(deliveryTime)

    let deliveryStatus = DELIVERY_CLOSED

    if (addDelivery) {
      deliveryStatus = DELIVERY_OPENED
    }

    if (Object.keys(deliveryOption).length && addDelivery) {
      const { deliveryZones } = deliveryOption
      let rememberedDeliveryZones = []

      const dataRememberedDeliveryZones = deliveryZones
        // eslint-disable-next-line no-unused-vars
        .map(({ name, price, ...rest }) => ({ name, price }))
        .filter((item) => item.name && item.price >= 0)

      rememberedDeliveryZones = dataRememberedDeliveryZones
      try {
        await deliveryApi.updateRememberedDeliveryZones(userId, {
          rememberedDeliveryZones,
        })
      } catch (error) {
        addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      }
    }

    delete data.deliveryOption
    delete data.addDelivery

    const postData = {
      ...data,
      closingTime: closingTimeGMT0,
      pickupTime: pickupTimeGMT0,
      deliveryTime: deliveryTimeGMT0,
      products: new2,
      paynow: data.paynow === true || data.paynow === 1 ? 0 : 1,
      payable: data.paynow === true || data.paynow === 1 ? 0 : 1,
      payTogether: data.payTogether === true || data.payTogether === 1 ? 1 : 0,
      createdUserPhotoUrl: userInfo?.photoUrl,
      deliveryStatus,
    }

    if (postData.id) {
      try {
        const { msgCode, msgResp } = await eventApi.updateEvent(postData)
        if (msgCode && Number(msgCode) % 100 === 0) {
          dispatch(clearEventAndOrder())
          dispatch(clearAllEvents())
          dispatch(clearMyEvents())
          dispatch(updateEventInGroup(postData))
          dispatch(clearDataPurchaseDetail())
          dispatch(clearPastPurchasedMember())
          dispatch(clearMyOrder())
          addToast('Update event successfully!', { appearance: 'success', autoDismiss: true })
          removeCreateEventData()
          history.push(`/event/manage-orders?eventId=${postData.id}`)
        } else {
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
          return
        }
      } catch (error) {
        if (error?.msgResp) {
          addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
        } else {
          addToast('Something went wrong!', { appearance: 'error', autoDismiss: true })
        }
      }
    } else {
      try {
        const { msgCode, msgResp } = await eventApi.createEvent(postData)
        if (msgCode === 99999) {
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
          return
        }
        dispatch(clearAllEvents())
        dispatch(clearMyEvents())
        dispatch(clearDeliveryCreatedByHost())
        dispatch(appendNewEventToGroup(msgResp))
        addToast('Create event successfully!', { appearance: 'success', autoDismiss: true })
        removeCreateEventData()
        history.push(`/event/manage-orders?eventId=${msgResp.id}`)
      } catch (error) {
        if (error?.msgResp) {
          addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
        } else {
          addToast('Something went wrong!', { appearance: 'error', autoDismiss: true })
        }
      }
    }
  })

  const handleOnSubmitSchedule = handleSubmit(async (data) => {
    const {
      products,
      closingTime,
      pickupTime,
      deliveryOption = {},
      addDelivery,
      deliveryTime,
    } = data

    const new2 = products?.map((product) => ({
      ...product,
      id: product.pid,
      subItems: product.subItems?.map((sub) => ({
        ...sub,
        required: sub.required ? 1 : 0,
      })),
    }))
    const openTimeLocal = `${moment(openDate).format('DD-MM-YYYY')} ${openTime}`

    const pickupTimeGMT0 = convertToGMT0(pickupTime)
    const closingTimeGMT0 = convertToGMT0(closingTime)
    const openTimeGMT0 = convertToGMT0(openTimeLocal)
    const deliveryTimeGMT0 = convertToGMT0(deliveryTime)
    const currentTimeGMT0 = convertToGMT0(moment().format('DD-MM-YYYY HH:mm'))

    if (openTimeGMT0 > currentTimeGMT0) {
      if (openTimeGMT0 < closingTimeGMT0) {
        let deliveryStatus = DELIVERY_CLOSED

        if (addDelivery) {
          deliveryStatus = DELIVERY_OPENED
        }

        if (Object.keys(deliveryOption).length) {
          const { deliveryZones } = deliveryOption
          let rememberedDeliveryZones = []

          const dataRememberedDeliveryZones = deliveryZones
            // eslint-disable-next-line no-unused-vars
            .map(({ name, price, ...rest }) => ({ name, price }))
            .filter((item) => item.name && item.price >= 0)

          rememberedDeliveryZones = dataRememberedDeliveryZones
          try {
            await deliveryApi.updateRememberedDeliveryZones(userId, {
              rememberedDeliveryZones,
            })
          } catch (error) {
            addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
          }
        }

        delete data.deliveryOption
        delete data.addDelivery

        const postData = {
          ...data,
          products: new2,
          closingTime: closingTimeGMT0,
          pickupTime: pickupTimeGMT0,
          deliveryTime: deliveryTimeGMT0,
          paynow: data.paynow === true || data.paynow === 1 ? 0 : 1,
          payable: data.paynow === true || data.paynow === 1 ? 0 : 1,
          payTogether: data.payTogether === true || data.payTogether === 1 ? 1 : 0,
          createdUserPhotoUrl: userInfo?.photoUrl,
          openTime: openTimeGMT0,
          deliveryStatus,
        }
        setIsSubmitScheduled(true)
        if (postData.id) {
          try {
            const { msgCode, msgResp } = await eventApi.updateScheduledEvent(postData)
            if (msgCode && Number(msgCode) % 100 === 0) {
              addToast('Update scheduled event successfully!', {
                appearance: 'success',
                autoDismiss: true,
              })
              removeCreateEventData()
              dispatch(clearScheduledEvent)
              setIsSubmitScheduled(false)
              if (getDeviceUser() === DESKTOP) {
                history.push('/event/list-hosted-by-group')
              } else {
                history.push('/event/list-my-events')
              }
            } else {
              setIsSubmitScheduled(false)
              addToast(msgResp, { appearance: 'error', autoDismiss: true })
              return
            }
          } catch (error) {
            setIsSubmitScheduled(false)
            if (error?.msgResp) {
              addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
            } else {
              addToast('Something went wrong!', { appearance: 'error', autoDismiss: true })
            }
          }
        } else {
          try {
            const { msgCode, msgResp } = await eventApi.createScheduledEvent(postData)
            if (msgCode === 99999) {
              setIsSubmitScheduled(false)
              addToast(msgResp, { appearance: 'error', autoDismiss: true })
              return
            }
            addToast('Create scheduled event successfully!', {
              appearance: 'success',
              autoDismiss: true,
            })
            removeCreateEventData()
            dispatch(clearScheduledEvent)
            setIsSubmitScheduled(false)
            if (getDeviceUser() === DESKTOP) {
              history.push('/event/list-hosted-by-group')
            } else {
              history.push('/event/list-my-events')
            }
          } catch (error) {
            setIsSubmitScheduled(false)
            if (error?.msgResp) {
              addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
            } else {
              addToast('Something went wrong!', { appearance: 'error', autoDismiss: true })
            }
          }
        }
      } else {
        addToast('Invalid Time', { appearance: 'error', autoDismiss: true })
      }
    } else {
      addToast('Invalid Time', { appearance: 'error', autoDismiss: true })
    }
  })

  useEffect(() => {
    setTotalPrice(totalPrice())
  }, [controlledFields])

  useEffect(() => {
    const getGroups = () => {
      try {
        dispatch(getMyGroups)
      } catch (error) {
        addToast(error, { appearance: 'error', autoDismiss: true })
      }
    }

    getGroups()
  }, [])

  useEffect(() => {
    if (createEventData) {
      if (createEventData?.openTime) {
        const _openDate = createEventData.openTime.split(' ')[0].split('-').reverse()
        const _openTime = createEventData.openTime.split(' ')[1]
        setOpenDate(new Date(_openDate[0], _openDate[1] - 1, _openDate[2]))
        setOpenTime(_openTime)
      }
    }
  }, [createEventData?.openTime])

  useEffect(() => {
    setShowScheduleEvent(false)

    if (createEventData && (!createEventData.id || createEventData.openTime)) {
      if (userInfo) {
        const { scheduleFeatures = [] } = userInfo
        if (scheduleFeatures.length) {
          const scheduleFeaturePaid = scheduleFeatures.filter(
            (item) =>
              item.schedulePaymentStatus === PAYMENT_STATUS_VERIFY &&
              item.scheduleStatus === STATUS_PAID
          )
          if (scheduleFeaturePaid.length) {
            setShowScheduleEvent(true)
          } else {
            setShowScheduleEvent(false)
          }
        } else {
          setShowScheduleEvent(false)
        }
      }
    }
  }, [userInfo])

  const onChangeOpenTime = (e) => {
    setOpenTime(e.target.value)
  }

  return (
    <form id='confirm-form' className='row-top created-confirm-page'>
      <div className='group-list group-list--event detail-event group-list--event02'>
        <div className='group-list__item'>
          <div className='group-list__img'>
            <div className='c-image'>
              <img
                src={userInfo?.photoUrl || process.env.REACT_APP_AVATAR_URL + userInfo.name}
                alt=''
              />
            </div>
          </div>
          <div className='group-list__info'>
            <h3>{userInfo?.name}</h3>
            <p>{groupName}</p>
          </div>
          <div className='group-list__btn text-right'>
            <span className='txt-date'>
              <span>{getDateRemainFromNow(createEventData?.closingTime)}</span> left
            </span>
            <span className='txt-number'>0 BUY</span>
          </div>
          <div className='group-list__des'>
            {listImages?.length > 0 && (
              <ReactImageGallery
                items={listImages}
                lazyLoad
                autoPlay
                showPlayButton={false}
                showNav={false}
                showBullets={true}
                showThumbnails={false}
              />
            )}

            <h4>{createEventData?.title}</h4>
            <p>{createEventData?.description}</p>
          </div>
          <div className='group-list__detail'>
            <div className='row'>
              <div className='col-4'>Closing:</div>
              <div className='col-8 align-self-center'>
                <p>{createEventData?.closingTime.split(' ')[0]}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <p>Collection:</p>
                <p>Address:</p>
              </div>
              <div className='col-8 text-blue'>
                <p>{createEventData?.pickupTime.split(' ')[0]}</p>
                <p>{createEventData?.pickupAddress}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='detail-list-news'>
        {controlledFields?.map((product, index) => (
          <ProductConfirm
            product={product}
            productIndex={index}
            key={index}
            setValue={setValue}
            register={register(`products.${index}.defaultQuantity`)}
          />
        ))}
      </div>
      <div className='nav-amount'>
        <div className='row'>
          <div className='col-8 '>
            <p className={`note fs-10 ${!createEventData?.paynow && 'is-close'}`}>
              <b>PAY LATER:</b>
              <br />
              Host requests payment to be made after the event closed. Sharing of delivery charges,
              discounts and admin fee may apply.
            </p>
          </div>
          <div className='col-4'>
            <div className='sum'>
              <p>
                <span className='c-txt-org'>
                  $<span>{totalPriceValue?.toFixed(2)}</span>
                </span>
              </p>
              <p>
                <span>
                  Admin Fee: $<span>{createEventData?.adminCost?.toFixed(2)}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <small style={{ color: '#ff9900' }}></small>
      <div className='c-form'>
        <div className='row justify-content-center'>
          <div className='col-6'>
            <button
              className='btn btn--gray'
              onClick={() => {
                setCreateEventData(getValues())
                if (createEventData?.id) {
                  history.push(`/event/update-items?id=${createEventData?.id}`)
                } else {
                  history.push('/event/create-items')
                }
              }}
            >
              PREVIOUS
            </button>
          </div>
          <div className='col-6'>
            <button
              onClick={handleOnSubmitEvent}
              disabled={isSubmitting || createEventData.openTime}
              ref={refSubmitEvent}
              type='submit'
              className='btn btn--org btn--org02'
            >
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'PUBLISH'}
            </button>
          </div>
        </div>
        {showScheduleEvent ? (
          <div
            style={{
              textAlign: 'center',
              fontSize: '0.9rem',
              marginTop: '20px',
              textDecoration: 'underline',
              color: '#d1274d',
              cursor: 'pointer',
            }}
            onClick={toggleSchedule}
          >
            Click here to schedule a later date to publish
          </div>
        ) : null}
      </div>
      <ModalSchedule
        idForm='confirm-form'
        modalSchedule={modalSchedule}
        toggleSchedule={toggleSchedule}
        openDate={openDate}
        openTime={openTime}
        onChangeOpenTime={onChangeOpenTime}
        onChangeOpenDate={setOpenDate}
        isSubmitScheduled={isSubmitScheduled}
        refSubmitButton={refSubmitSchedule}
        handleOnSubmitSchedule={handleOnSubmitSchedule}
      />
    </form>
  )
}

export default Confirm
