import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import reportApi from '_api/report'
import userApi from '_api/user'
import Footer from '_components/Footer'
import Header from '_components/Header'
import useData from '_hooks/useData'
import { setUserInfo, getUserInfo } from '_utils/localData'

import EventList from './Event/EventList'
import EventPage from './Event'
import GroupPage from './Group'
import UserPage from './User'

const MainPage = () => {
  useData()
  const user = getUserInfo()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (user) {
      userApi
        .getUser()
        .then(({ msgResp }) => {
          setUserInfo(msgResp)
          setLoading(false)
        })
        .catch(({ msgResp, msgCode, message }) => {
          if (msgCode) {
            reportApi.report({ message: `[Main]${msgCode} - ${msgResp}` })
          } else {
            reportApi.report({ message: `[Main - client]${message}` })
          }
        })
      return () => {
        setLoading({})
      }
    }
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <React.Fragment>
      <Header />
      <section className='section-content section-content--top mains-screen' id='checkHeight'>
        <div className='container'>
          <Switch>
            <Route path='/user' component={UserPage} />
            <Route path='/group' component={GroupPage} />
            <Route path='/event' component={EventPage} />
            <Route path='/' exact component={EventList} />
          </Switch>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}

export default MainPage
