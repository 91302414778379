import React from 'react'
import { FaTruck } from 'react-icons/fa'
import PropTypes from 'prop-types'

export default function BoxAddDeliveryByBuyer(props) {
  const { createdUserPhotoUrl, createdUserName, groupName, addDelivery, hostId, groupId } = props
  return (
    <div className="group-list__item" style={{ backgroundColor: '#B8F0EF' }}>
      <div className="group-list__img">
        <div className="c-image">
          <a href=''>
            <img
              src={
                createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName
              }
              alt=''
            />
          </a>
        </div>
      </div>
      <div className="group-list__info">
        <h3><a href=''>{createdUserName}</a></h3>
        <p>{groupName}</p>
      </div>
      <div
        className="text-right d-flex align-items-center text-add-delivery"
        onClick={() => addDelivery(hostId, groupId)}
      >
        <span>
          Add delivery <FaTruck />
        </span>
      </div>
    </div>
  )
}

BoxAddDeliveryByBuyer.propTypes = {
  addDelivery: PropTypes.func,
  createdUserPhotoUrl: PropTypes.string,
  createdUserName: PropTypes.string,
  groupName: PropTypes.string,
}
