/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { getListDeliveryAndDeliveryBooking, deleteDelivery } from '_redux/modules/delivery'
import useQuery from '_hooks/useQuery'
import { DELIVERY_BUYER_CANCELLED, DELIVERY_HOST_CANCELLED, DESKTOP } from '_utils/constant'
import { convertTimeStringWithDuration } from '_utils/function'
import { getUserInfo, getDeviceUser } from '_utils/localData'
import userApi from '_api/user'
import deliveryApi from '_api/delivery'
import DeliveryInfo from './DeliveryInfo'
import NavBar from './NavBar'
import DeliveryList from './DeliveryList'

export default function ManageDelivery() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const params = useQuery()
  const history = useHistory()

  const deliveryId = params.get('id')

  const userInfo = getUserInfo()
  const { id: userId } = userInfo

  const { currentDeliveryAndDeliveryBooking, errorDeliveryAndDeliveryBooking } = useSelector(
    (state) => state.delivery
  )

  const [isLoading, setIsLoading] = useState(true)

  const { deliveryBookings, deliveryTime, deliveryDuration } = currentDeliveryAndDeliveryBooking

  /*
    will setTimeReRender when change status Delivery,
    or status Event Delivery, or Comment
  */
  const [timeReRender, setTimeReRender] = useState(0)

  const [totalPurchase, setTotalPurchase] = useState(0)

  const [deliveryBookingCount, setDeliveryBookingCount] = useState(0)

  const initialDelivery = async () => {
    setIsLoading(true)
    await dispatch(getListDeliveryAndDeliveryBooking(deliveryId))
    setIsLoading(false)
  }

  useEffect(() => {
    initialDelivery()
  }, [deliveryId])

  useEffect(() => {
    let price = 0
    let count = 0
    if (deliveryBookings && !!deliveryBookings.length) {
      deliveryBookings.forEach((item) => {
        if (
          item.status !== DELIVERY_HOST_CANCELLED &&
          item.bookerStatus !== DELIVERY_BUYER_CANCELLED
        ) {
          price += item.dzPrice
          count += 1
        }
      })
    }
    setTotalPurchase(price)
    setDeliveryBookingCount(count)
  }, [deliveryBookings])

  useEffect(() => {
    dispatch(getListDeliveryAndDeliveryBooking(deliveryId))
  }, [timeReRender])

  const handelBroadcast = (mess) => {
    if (deliveryBookings && deliveryBookings.length > 0) {
      const listDeliveryExcludeCancelled = deliveryBookings.filter(
        (item) =>
          item.status !== DELIVERY_HOST_CANCELLED && item.bookerStatus !== DELIVERY_BUYER_CANCELLED
      )
      try {
        listDeliveryExcludeCancelled.forEach(async (e) => {
          await userApi.createNotifications({
            receiverId: e.uid,
            title: `Broadcast Delivery date: ${convertTimeStringWithDuration(
              deliveryTime,
              deliveryDuration
            )}`,
            message: mess,
          })
        })
        addToast('Message sent!', { appearance: 'success', autoDismiss: true })
      } catch (error) {
        addToast(error, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const handleBackMangeOrder = (e) => {
    e.preventDefault()
    history.push('/event/list-my-events')
  }

  const onDeleteClicked = () => {
    const r = confirm('Are you sure?')
    if (r === true) {
      deliveryApi
        .deleteDelivery(deliveryId)
        .then(() => {
          dispatch(deleteDelivery(deliveryId, userId))
          if (getDeviceUser() === DESKTOP) {
            history.push('/event/delivery-list')
          } else {
            history.push('/event/list-my-events')
          }
        })
        .catch((error) => {
          const { msgResp } = error
          addToast(msgResp, { appearance: 'error', autoDismiss: true })
        })
    }
  }

  return (
    <div className='container'>
      {isLoading ? <div>Loading...</div> : null}
      {!isLoading && errorDeliveryAndDeliveryBooking ? <div>No Delivery Found</div> : null}
      {!isLoading && !errorDeliveryAndDeliveryBooking ? (
        <>
          <div className='row-top'>
            <DeliveryInfo
              deliveryTime={convertTimeStringWithDuration(deliveryTime, deliveryDuration)}
              deliveryBookingCount={deliveryBookingCount}
            />
            <NavBar
              handelBroadcast={handelBroadcast}
              setTimeReRender={setTimeReRender}
              deliveryInfo={currentDeliveryAndDeliveryBooking}
            />
            <div className='list-event-note'>
              Click on <strong>Names</strong> for Delivery Details and on{' '}
              <i className='icon-link'></i>
              for Payment Details.
            </div>
            {deliveryBookings && !!deliveryBookings.length > 0 ? (
              <DeliveryList
                listDelivery={deliveryBookings}
                setTimeReRender={setTimeReRender}
                deliveryTime={deliveryTime}
                deliveryId={deliveryId}
              />
            ) : null}
          </div>
          <div className='row-bot'>
            <div className='text-right price havebg'>
              <p>
                Total Purchase: $<span id='totalAmount'>{totalPurchase.toFixed(2)}</span>
              </p>
            </div>
            <div className='row align-items-center'>
              <div className='col-6 6 m-auto'>
                <a className='btn btn--solid btn--solid03' href='' onClick={handleBackMangeOrder}>
                  Exit
                </a>
              </div>
            </div>
          </div>
          <p className='link-share' style={{ cursor: 'pointer' }} onClick={onDeleteClicked}>
            DELETE
          </p>
        </>
      ) : null}
    </div>
  )
}
