import React, { useContext, useEffect, useState } from 'react'
import reportApi from '_api/report'
import userApi from '_api/user'
import { PHONE, STATUS_PAID } from '_utils/constant'
import { setDeviceUser, setUserInfo } from '_utils/localData'
import Header from './components/Header'

import CollapseProvider from '../context/CollapseMenuContext'
import { DeviceUserContext } from '../context/DeviceUserContext'
import SelectGroupContext from '../context/SelectGroupContext'
import MainDesktop from './Main'

function AppDesktop() {
  const [loading, setLoading] = useState(true)

  const { setCheckDeviceUser } = useContext(DeviceUserContext)

  useEffect(() => {
    userApi
      .getUser()
      .then(({ msgResp }) => {
        setUserInfo(msgResp)
        const { premiumFeatures = [] } = msgResp
        if (premiumFeatures.length) {
          const checkUserHavePremiumFeature = premiumFeatures.filter(
            (item) => item.premiumStatus === STATUS_PAID
          )
          if (!checkUserHavePremiumFeature?.length) {
            setDeviceUser(PHONE)
            setCheckDeviceUser(PHONE)
          }
        } else {
          setDeviceUser(PHONE)
          setCheckDeviceUser(PHONE)
        }
        setLoading(false)
      })
      .catch(({ msgResp }) => {
        reportApi.report({ message: `[page desktop] ${msgResp}` })
      })
    return () => {
      setLoading({})
    }
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <React.Fragment>
      <SelectGroupContext>
        <CollapseProvider>
          <Header />
          <section
            className='section-content section-content--top mains-screen h-100 pb-0'
            id='checkHeight'
          >
            <MainDesktop />
          </section>
        </CollapseProvider>
      </SelectGroupContext>
    </React.Fragment>
  )
}

export default AppDesktop
