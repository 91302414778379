/* eslint-disable no-unused-vars */
import React from 'react'
import Summary from './components/Summary'
import OrderChart from './components/OrderChart'
import SaleChart from './components/SaleChart'
import AverageChart from './components/AverageChart'
import TopProductChart from './components/TopProductChart'

function Dashboard1({
  listTotalSaleAfterSort,
  listTotalOrdersAfterSort,
  listTotalAverageOrdersAfterSort,
  listTopProduct,
}) {
  return (
    <div className='dashboard__desktop-chart'>
      <div className='row chart-layout'>
        <div className='dashboard__desktop-sale-chart'>
          <Summary />
        </div>
        <div className='dashboard__desktop-order-chart'>
          <p>How many orders ?</p>
          <OrderChart data={listTotalOrdersAfterSort} />
        </div>
      </div>
      <div className='row chart-layout'>
        <div className='dashboard__desktop-average-chart'>
          <p>What is the average order size ?</p>
          <AverageChart data={listTotalAverageOrdersAfterSort} />
        </div>
        <div className='dashboard__desktop-topProduct-chart'>
          <p>What are my top products ?</p>
          <TopProductChart data={listTopProduct} />
        </div>
      </div>
    </div>
  )
}

export default Dashboard1
