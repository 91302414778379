import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  deleteScheduledDelivery,
  updateScheduledDelivery
} from '_redux/modules/delivery'
import {
  convertToCurrentGMT,
  convertToGMT0,
  convertTimeStringWithDuration
} from '_utils/function'
import { DEFAULT_DELIVERY_OPTION } from '_utils/constant'
import deliveryApi from '_api/delivery'
import ModalConfirm from './ModalConfirm'
import DeliveryOptionsModal from './DeliveryOptionsModal'

const MyScheduledDelivery = (props) => {
  const {
    delivery,
    hostId,
    setTimeGetScheduleDelivery
  } = props

  const { createdUserPhotoUrl,
    createdUserName,
    groupName,
    openTime,
    id: deliveryId,
    deliveryTime,
    deliveryDuration,
  } = delivery

  const deliveryTimeString =
    convertTimeStringWithDuration(deliveryTime, deliveryDuration)

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [modalConfirm, setModalConfirm] = useState(false)

  const toggleModalConfirm = () => setModalConfirm(!modalConfirm)

  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])

  const [deliveryHourStart, setDeliveryHourStart] = useState('')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('')
  const [deliveryDate, setDeliveryDate] = useState(
    new Date()
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  const handleOnClickOk = async () => {
    setIsSubmitting(true)
    const timeStart = Number(deliveryHourStart.split(':')[0])
    const timeEnd = Number(deliveryHourEnd.split(':')[0])
    const deliveryDur = (timeEnd - timeStart) * 3600
    if (deliveryDur < 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    const deliveryTimeLocal = `${moment(deliveryDate).format('DD-MM-YYYY')} ${deliveryHourStart}:00`
    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    try {
      await deliveryApi.updateScheduledDelivery(deliveryId, {
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration: deliveryDur,
      })

      await deliveryApi.updateRememberedDeliveryZones(hostId, {
        rememberedDeliveryZones,
      })

      dispatch(updateScheduledDelivery(hostId, deliveryId, {
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration: deliveryDur,
      }))

      addToast('Update success', { appearance: 'success', autoDismiss: true })

      setTimeGetScheduleDelivery((prevState) => prevState + 1)
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      return
    }
    setIsSubmitting(false)
    toggleModalAddDelivery()
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryDate(e)
  }

  const onChangeZoneDelivery = (index, type, value) => {
    deliveryZones[index][type] = value
    setDeliveryZones([...deliveryZones])
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const onEditDelivery = async () => {
    const { msgResp } = await deliveryApi.getRememberedDeliveryZones()

    if (msgResp.length > 0) {
      setDeliveryZones(msgResp)
    }
    const deliveryTimeCurrentGMT = convertToCurrentGMT(deliveryTime)
    const timeStart = Number(deliveryTimeCurrentGMT.split(' ')[1].split(':')[0])
    const timeEnd = timeStart + deliveryDuration / 3600
    const deliveryTimeSplit = deliveryTimeCurrentGMT.split(' ')[0].split('-').reverse()
    setDeliveryDate(new Date(deliveryTimeSplit[0], deliveryTimeSplit[1] - 1, deliveryTimeSplit[2]))
    const timeStartText = timeStart < 10 ? `0${timeStart}:00` : `${timeStart}:00`
    const timeEndText = timeEnd < 10 ? `0${timeEnd}:00` : `${timeEnd}:00`
    setDeliveryHourStart(timeStartText)
    setDeliveryHourEnd(timeEndText)
    toggleModalAddDelivery()
  }

  const deleteScheduleDelivery = async () => {
    try {
      await deliveryApi.deleteScheduledDelivery(deliveryId)
      dispatch(deleteScheduledDelivery(hostId, deliveryId))
      addToast('Delete successful', { appearance: 'success', autoDismiss: true })
      toggleModalConfirm()
      setTimeGetScheduleDelivery((prevSate) => prevSate + 1)
    } catch (e) {
      addToast(e?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <div className="event-item group-list group-list--event group-list--event01 scheduled-event">
      <div className="group-list__item">
        <div className="group-list__img">
          <div className="c-image">
            <a href='' onClick={(e) => e.preventDefault()}>
              <img
                src={
                  createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName
                }
                alt=''
              />
            </a>
          </div>
        </div>
        <div className="group-list__info">
          <h3><a href='' onClick={(e) => e.preventDefault()}>{createdUserName}</a></h3>
          <p>{groupName}</p>
        </div>
        <div className='group-list__btn text-right scheduled-event-right'>
          <div className='scheduled-event-text-right'>
            Scheduled on {moment(openTime, 'DD-MM-YYYY').format('DD-MM-YYYY')}
          </div>
        </div>
      </div>
      <div className="group-list__des text-color-blue">
        <div>
          Delivery for {deliveryTimeString}
        </div>
      </div>

      <ul className='nav-tab'>
        <li onClick={onEditDelivery}>Edit Post</li>
        <li onClick={toggleModalConfirm}>Delete</li>
      </ul>

      <ModalConfirm
        modal={modalConfirm}
        toggleModal={toggleModalConfirm}
        handleConfirm={deleteScheduleDelivery}
        title='schedule delivery'
      />

      {openModalAddDelivery && <DeliveryOptionsModal
        modal={openModalAddDelivery}
        toggle={toggleModalAddDelivery}
        handleOnClickOk={handleOnClickOk}
        deliveryZones={deliveryZones}
        deliveryTime={deliveryDate}
        onChangeZoneDelivery={onChangeZoneDelivery}
        onChangeTimeDelivery={onChangeTimeDelivery}
        handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
        deliveryHourStart={deliveryHourStart}
        setDeliveryHourStart={setDeliveryHourStart}
        deliveryHourEnd={deliveryHourEnd}
        setDeliveryHourEnd={setDeliveryHourEnd}
        isSubmitting={isSubmitting}
      />}
    </div>
  )
}

MyScheduledDelivery.propTypes = {
  delivery: PropTypes.object,
  setTimeGetScheduleDelivery: PropTypes.func
}

export default MyScheduledDelivery
