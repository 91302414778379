import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, withRouter } from 'react-router-dom'

import { getCreateEventData, getUserInfo, removeCreateEventData } from '_utils/localData'

import './style.scss'

const Footer = ({ location }) => {
  const history = useHistory()
  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const { myEvents } = useSelector((state) => state.event)

  const currentUser = getUserInfo()

  const isAllowHost =
    currentUser?.groups?.length && currentUser?.groups?.some((g) => g?.isHost || g?.isAdmin)

  const checkHighLight = () => {
    const paths = [
      'create',
      'create-items',
      'created-confirm',
      'update',
      'update-items',
      'update-confirm',
    ]
    if (pathname[1] === 'event' && paths.includes(pathname[2].split('?')[0])) {
      return true
    }
    return false
  }

  const handleOnClickToCreateEvent = () => {
    const eventData = getCreateEventData()
    if (eventData?.id) {
      removeCreateEventData()
    }
    history.push('/event/create')
  }

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])
  return (
    <React.Fragment>
      {isAllowHost && (
        <footer className='footer footer-admin'>
          <div className='container'>
            <ul className='menu'>
              <li>
                <a
                  className={`${checkHighLight() ? 'active' : ''} cursor-pointer`}
                  onClick={handleOnClickToCreateEvent}
                >
                  <i className='icon-host'></i>
                  <span>Host</span>
                </a>
              </li>
              <li>
                <Link
                  to='/event/list-my-events'
                  className={
                    pathname[1] === 'event' && pathname[2] === 'list-my-events' ? 'active' : ''
                  }
                >
                  <i className='icon-host-event'></i>
                  <span>
                    Hosted Event {!!myEvents?.length && <span>({myEvents?.length})</span>}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      )}
    </React.Fragment>
  )
}

export default withRouter(Footer)
