import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { getMyOrders } from '_redux/modules/order'
import { getMulListDeliveryCreatedByHost, getDeliveryByBuyer } from '_redux/modules/delivery'
import {
  sortListMyOrder,
  convertTimeStringWithDuration,
  sortListMyDelivery,
  convertTimeStringWithDurationTwoPart,
  convertToCurrentGMT
} from '_utils/function'
import {
  getUserInfo,
} from '_utils/localData'
import { DELIVERY_OPENED } from '_utils/constant'
import { getListCombinedPayment } from '_redux/modules/event'

import ItemOrder from '../components/ItemOrder'
import ModalDelivery from '../components/ModalDelivery'
import BoxAddDeliveryByBuyer from '../components/BoxAddDeliveryByBuyer'
import ItemDeliveryBooking from '../components/ItemDeliveryBooking'

const now = moment().unix()

function ListMyPurchases() {
  const history = useHistory()
  const dispatch = useDispatch()

  const userInfo = getUserInfo()
  const { id: userId } = userInfo

  const [isLoading, setIsLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [deliveryInfo, setDeliveryInfo] = useState(null)

  const {
    myOrders,
    listHostId,
    listHostDetail } = useSelector((state) => state.order)

  const { listDeliveryCreatedByHost, listDeliveryByBuyer } = useSelector((state) => state.delivery)

  const initialOrders = async () => {
    setIsLoading(true)
    await dispatch(getMyOrders)
    await dispatch(getDeliveryByBuyer(userId))
    setIsLoading(false)
  }

  const initListCombined = async (_myOrders, _listDeliveryByBuyer) => {
    const listCombinedPaymentRef = []
    _myOrders.forEach(async (element) => {
      const { combinedPaymentRef = '' } = element
      if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
        if (combinedPaymentRef) {
          listCombinedPaymentRef.push(combinedPaymentRef)
        }
      }
    })
    _listDeliveryByBuyer.forEach(async (element) => {
      const { combinedPaymentRef = '' } = element
      if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
        if (combinedPaymentRef) {
          listCombinedPaymentRef.push(combinedPaymentRef)
        }
      }
    })
    if (listCombinedPaymentRef.length) {
      await dispatch(getListCombinedPayment(listCombinedPaymentRef))
    }
  }

  const addDelivery = async (hostId, groupId) => {
    let { listDeliveryCreated } = listDeliveryCreatedByHost.find((item) => item.hostId === hostId)
    const { deliveryZones } = listDeliveryCreated[0]
    const { eCreatedUserName, eCreatedUserPhotoUrl,
    } = listHostDetail.find((item) => item.eCreatedUserId === hostId)

    listDeliveryCreated = listDeliveryCreated
      .sort((a, b) => b.deliveryTimestamp - a.deliveryTimestamp)
      .filter((item) => item.groupId === groupId
        && item.deliveryStatus === DELIVERY_OPENED
        && item.deliveryTimestamp > now)
      .map((item) => ({
        ...item,
        deliveryTime: convertTimeStringWithDuration(item.deliveryTime, item.deliveryDuration)
      }))

    setDeliveryInfo({
      deliveryZones,
      listDeliveryCreated,
      eCreatedUserName,
      eCreatedUserPhotoUrl,
    })
    setIsShowModal(true)
  }

  useEffect(() => {
    initListCombined(myOrders, listDeliveryByBuyer)
  }, [myOrders, listDeliveryByBuyer])

  useEffect(() => {
    initialOrders()
  }, [])

  const getListDeliveryCreatedByHost = async (_listHostId) => {
    await dispatch(getMulListDeliveryCreatedByHost(_listHostId))
  }

  useEffect(() => {
    getListDeliveryCreatedByHost(listHostId)
  }, [listHostId])

  const renderBoxAddDelivery = () => (
    <>
      {listHostDetail
        && listHostDetail.length
        && listDeliveryCreatedByHost
        && listDeliveryCreatedByHost.length === listHostId.length ?
        <>
          {listHostDetail.map((item, index) => {
            const
              { eCreatedUserName,
                eCreatedUserPhotoUrl,
                eGroupName,
                eCreatedUserId,
                eGroupId
              } = item
            let { listDeliveryCreated = [] } = listDeliveryCreatedByHost
              .find((del) => del.hostId === eCreatedUserId)

            listDeliveryCreated = listDeliveryCreated.filter((del) =>
              del.deliveryStatus === DELIVERY_OPENED
              && del.groupId === eGroupId
              && del.deliveryTimestamp > now)

            if (listDeliveryCreated && listDeliveryCreated.length) {
              return <BoxAddDeliveryByBuyer
                key={index}
                createdUserPhotoUrl={eCreatedUserPhotoUrl}
                createdUserName={eCreatedUserName}
                groupName={eGroupName}
                hostId={eCreatedUserId}
                groupId={eGroupId}
                addDelivery={addDelivery}
              />
            }
            return null
          })}
        </> : null
      }
    </>
  )

  const handleToDeliveryDetail = (_ref) => {
    history.push(`/event/delivery-detail?ref=${_ref}`)
  }

  return (
    <React.Fragment>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className='group-list group-list--event'>
          {renderBoxAddDelivery()}

          {listDeliveryByBuyer &&
            !!listDeliveryByBuyer.length &&
            sortListMyDelivery(listDeliveryByBuyer).listDeliveryActiveSort.map((item, index) => (
              <ItemDeliveryBooking
                key={index}
                createdUserPhotoUrl={item.dCreatedUserPhotoUrl}
                createdUserName={item.dCreatedUserName}
                groupName={item.dGroupName}
                deliveryTimeString={
                  convertTimeStringWithDurationTwoPart(item.dDeliveryTime, item.dDeliveryDuration)}
                deliveryTime={convertToCurrentGMT(item.dDeliveryTime)}
                bookerStatus={item.bookerStatus}
                status={item.status}
                combinedPaymentRef={item.combinedPaymentRef || null}
                dRef={item.ref}
                onClick={() => handleToDeliveryDetail(item.ref)}
              />
            ))}

          {(!myOrders || !myOrders.length) && <p>No purchase found.</p>}
          {myOrders &&
            !!myOrders.length &&
            sortListMyOrder(myOrders).listOrderActiveSort.map((o, i) => (
              <ItemOrder
                key={o.id || i}
                order={o}
              />
            ))}

          {listDeliveryByBuyer &&
            !!listDeliveryByBuyer.length &&
            sortListMyDelivery(listDeliveryByBuyer).listDeliveryDisableSort.map((item, index) => (
              <ItemDeliveryBooking
                key={index}
                createdUserPhotoUrl={item.dCreatedUserPhotoUrl}
                createdUserName={item.dCreatedUserName}
                groupName={item.dGroupName}
                deliveryTimeString={
                  convertTimeStringWithDurationTwoPart(item.dDeliveryTime, item.dDeliveryDuration)}
                deliveryTime={convertToCurrentGMT(item.dDeliveryTime)}
                bookerStatus={item.bookerStatus}
                status={item.status}
                combinedPaymentRef={item.combinedPaymentRef || null}
                dRef={item.ref}
                onClick={() => handleToDeliveryDetail(item.ref)}
                isDisable={true}
              />
            ))}

          {myOrders &&
            !!myOrders.length &&
            sortListMyOrder(myOrders).listOrderDisableSort.map((o, i) => (
              <ItemOrder
                key={o.id || i}
                order={o}
              />
            ))}
        </div>
      )}
      {isShowModal && (
        <ModalDelivery
          isShowModal={isShowModal}
          deliveryInfo={deliveryInfo}
          toggle={() => setIsShowModal(false)}
        />
      )}
    </React.Fragment>
  )
}

export default ListMyPurchases
