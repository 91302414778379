import React, { forwardRef, useEffect, useState } from 'react'

import uploadApi from '_api/upload'
import { SINGAPORE_CODE } from '_utils/constant'
import { generatePayNowStr } from '_utils/paynow'
import PropTypes from 'prop-types'
import QRCode from 'qrcode'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'

const Payment = forwardRef((props, ref) => {
  const {
    paymentFileUrl,
    confirmPayment,
    final,
    phone,
    pickupTime,
    isSubmittedPayment,
    stringRefNumber = '',
    setPaymentFileUrl,
  } = props

  const { addToast } = useToasts()
  const [src, setSrc] = useState('')
  const ePickupTime = pickupTime.split(' ')
  const ePickupDate = ePickupTime[0].split('-')
  const [payNowCode, setPayNowCode] = useState('')
  const [space, setSpace] = useState('')
  const [photoUrl, setPhotoUrl] = useState(paymentFileUrl)
  const [rand, setRand] = useState(0) // Spaces number

  const expiryDate =
    // eslint-disable-next-line radix
    (parseInt(ePickupDate[2]) + 1)?.toString() + ePickupDate[1] + ePickupDate[0]
  const options = {
    uen: `${SINGAPORE_CODE}${phone.replace(`${SINGAPORE_CODE}`, '')}`,
    editable: 0,
    expiry: expiryDate,
    amount: Number(final.toFixed(2)),
    refNumber: `${stringRefNumber}${space}`,
  }

  const onUserImageUploaded = async (_event) => {
    const { files } = _event.target
    if (files) {
      const { msgResp: res } = await uploadApi.uploadPhoto(files)
      const { url } = res
      setPhotoUrl(url)
      ref.current = url
      setPaymentFileUrl(url)
    }
  }

  const onReloadQRCode = () => {
    const newRand = (rand + 1) % 5
    setRand(newRand)
    setSpace(' '.repeat(newRand))
  }

  const onDownloadQRCode = () => {
    // Sử dụng thư viện 'qrcode' để tạo mã QR code
    const qrCodeOptions = {
      errorCorrectionLevel: 'H',
      type: 'image/png',
      color: {
        dark: '#981c84',
      },
    }

    QRCode.toDataURL(payNowCode, qrCodeOptions)
      .then((dataUrl) => {
        const a = document.createElement('a')
        a.href = dataUrl
        a.download = `${options.refNumber}.png`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .catch((error) => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  useEffect(() => {
    setPayNowCode(generatePayNowStr(options))
    QRCode.toDataURL(generatePayNowStr(options), {
      errorCorrectionLevel: 'H',
      color: {
        dark: '#981c84',
      },
    }).then(setSrc)
  }, [space, options.amount])

  return (
    <div>
      <div id='allowPaymentHtml' style={{ display: 'block' }}>
        <div style={{ height: '16px' }}></div>
        <div id='pendingPaymentHtmlBlock' className='row-bot' style={{ display: 'block' }}>
          <div className='group-list group-list--event detail-event'>
            <div className='detail-list-news detail-list-news--org'>
              <h3 className='c-ttl-blue text-center' style={{ fontSize: '20px' }}>
                <strong>Step 1:</strong> Please scan the following QR code to pay
                <span style={{ color: '#51aaaa' }}>
                  {' '}
                  $<span id='amount2'>{final?.toFixed(2)} </span>
                </span>
                to
                <span id='sellerPhone' style={{ color: '#51aaaa' }}>
                  {' '}
                  {phone}
                </span>
              </h3>
              <p className='text-center' style={{ fontSize: '20px' }}>
                Add your unique reference code <b id='orderRef'> {stringRefNumber}</b>
              </p>
              <div className='qr-box text-center'>
                <div id='qrcode' style={{ width: '256px', margin: 'auto' }} title={payNowCode}>
                  <canvas width='256' height='256' style={{ display: 'none' }}></canvas>
                  <img src={src} style={{ display: 'block' }} width='256' height='256' />
                </div>
                <div className={'d-flex align-items-center justify-content-between mt-3'}>
                  <button
                    onClick={onDownloadQRCode}
                    style={{ backgroundColor: 'transparent', border: 'none', color: '#15cdca' }}
                  >
                    <AiOutlineCloudDownload className={'me-1'} />
                    Download QRCode
                  </button>
                  <button
                    onClick={onReloadQRCode}
                    style={{ backgroundColor: 'transparent', border: 'none', color: '#939091' }}
                  >
                    Click to reload QRCode
                  </button>
                </div>
              </div>
            </div>
          </div>
          <h3 className='c-ttl-blue text-center'>
            <strong>Step 2: </strong>
            Attach PayNow screenshot (Do not attach SMS)
          </h3>
          <div className='c-form mb-4'>
            <div className='upload-image upload-screenshot'>
              <img
                style={
                  photoUrl
                    ? { height: '100%', width: '100%', objectFit: 'contain' }
                    : { display: 'none' }
                }
                id='eventImageImg'
                src={photoUrl}
                alt=''
              />
              <div className='upload-image-txt' style={photoUrl ? { opacity: 0 } : {}}>
                <i className='icon-capture'></i>
                <span>Screenshot</span>
              </div>
              <div className='u-file-upload__item'>
                <div className='u-file-upload__wrap'>
                  <a className='c-upload preview-images-zone'>
                    <input
                      type='file'
                      accept='image/*'
                      size='40'
                      className='inputFile'
                      onChange={onUserImageUploaded}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button className='btn btn--main' onClick={confirmPayment} disabled={isSubmittedPayment}>
            {isSubmittedPayment ? <Spinner color='light' size='sm' /> : 'CONFIRM PAYMENT'}
          </button>
        </div>
      </div>
    </div>
  )
})

Payment.displayName = 'Payment'
export default Payment

Payment.propTypes = {
  confirmPayment: PropTypes.func,
  phone: PropTypes.string,
  paymentFileUrl: PropTypes.string,
  final: PropTypes.number,
  orderRef: PropTypes.string,
  orderNo: PropTypes.number,
  pickupTime: PropTypes.string,
  isSubmittedPayment: PropTypes.bool,
}
