import axiosClient from './axiosClient'

const userApi = {
  getUser: () => {
    const url = 'get-user?getGroups=1'
    return axiosClient.get(url)
  },
  getUserById: (userId) => {
    const url = `/get-user?id=${userId}`
    return axiosClient.get(url)
  },
  getNotifications: () => {
    const url = 'list-notifications'
    return axiosClient.get(url)
  },
  createNotifications: (data) => {
    const url = '/create-notification'
    return axiosClient.post(url, data)
  },
  createFeedBack: (data) => {
    const url = 'create-feedback'
    return axiosClient.post(url, data)
  },
  updateUser: (data) => {
    const url = 'update-user'
    return axiosClient.patch(url, data)
  },
  changePassword: (data) => {
    const url = 'change-password'
    return axiosClient.patch(url, data)
  },
  getUserInfoById: (id) => {
    const url = `get-user?id=${id}`
    return axiosClient.get(url)
  },
  searchUser: (uid = '') => {
    const url = `search-user?id=${uid}`
    return axiosClient.get(url)
  },
}

export default userApi
