import eventApi from '_api/event'
import useQuery from '_hooks/useQuery'
import { GMT_0, SHORT_DATE } from '_utils/constant'
import { getDateRemainFromNow } from '_utils/function'
import { setCreateEventData } from '_utils/localData'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function EventInfo({
  title,
  closingTimestamp,
  pickupTimestamp,
  closingTime,
  pickupTime,
  id,
  shareCode,
  numBuy,
  eventAndOrderById,
}) {
  const history = useHistory()
  const { addToast } = useToasts()
  const [textBtn, setTextBtn] = useState('COPY')
  const [shareLink, setShareLink] = useState('')
  const [_shareCode, setShareCode] = useState(null)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const query = useQuery()
  const isByAdmin = query.get('isByAdmin') === 'true'

  const onShareClicked = async () => {
    const eventName = title.toLowerCase().replace(/\s+/g, ' ').trim()
    setShareLink(
      `${
        process.env.REACT_APP_APP_URL
      }/event/detail-shared?eid=${id}&shareCode=${_shareCode}&eventName=${eventName
        ?.split(' ')
        .join('-')}`
    )
    toggle()
  }

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  // const numDay = (date) => {
  //   const given = moment(date.split(' ')[0], 'DD-MM-YYYY')
  //   const current = moment().startOf('day')
  //   return moment.duration(given.diff(current)).asDays()
  // }

  const reHost = (e) => {
    e.preventDefault()

    if (isByAdmin) {
      addToast('You are not allowed to re-host this event', {
        appearance: 'error',
      })
      return
    }

    delete eventAndOrderById.orders
    delete eventAndOrderById.id
    eventAndOrderById.deliveryCost = 0
    eventAndOrderById.discount = 0

    eventAndOrderById = {
      ...eventAndOrderById,
      closingTime,
      pickupTime,
    }

    setCreateEventData(eventAndOrderById)
    history.push('/event/create')
  }

  useEffect(() => {
    const getShareCode = async () => {
      const { msgCode, msgResp } = await eventApi.getShareEventCode(id)
      if (msgCode % 100 === 0) {
        setShareCode(msgResp.shareCode)
      }
    }

    if (shareCode) {
      setShareCode(shareCode)
    } else {
      getShareCode()
    }
  }, [shareCode])

  return (
    <div className='group-list group-list--event'>
      <div className='group-list__item group-list__item--nobackground'>
        <div className='group-list__info'>
          <h4 id='eventTitle'>{title}</h4>
          <p>
            Closing:{' '}
            <b id='eventClosingTime'>
              {moment.unix(closingTimestamp).utcOffset(GMT_0).format(SHORT_DATE)}
            </b>
          </p>
          <p>
            Collection:{' '}
            <b id='eventPickupTime'>
              {moment.unix(pickupTimestamp).utcOffset(GMT_0).format(SHORT_DATE)}
            </b>
          </p>
          <p id='eventPickupAddress'></p>
          <span id='eventStatus' style={{ fontSize: '10px' }}></span>
        </div>
        <div className='group-list__btn text-right'>
          <span className='txt-date'>
            closing in{' '}
            <span id='dayLeftToClose'>
              {/* {closingTime ? (numDay(closingTime) > 0
                ? numDay(closingTime) : 0) : ''} */}
              {closingTime ? getDateRemainFromNow(closingTime) : ''}
            </span>{' '}
            {/* days */}
          </span>
          <span className='txt-date'>
            Delivery in{' '}
            <span id='dayLeftToCollection'>
              {/* {pickupTime ? (numDay(pickupTime) > 0 ? numDay(pickupTime) : 0) : ''} */}
              {closingTime ? getDateRemainFromNow(pickupTime) : ''}
            </span>{' '}
            {/* days */}
          </span>
          <span className='txt-number my-1'>
            <span id='totalBuy'>{numBuy}</span> BUY
          </span>
          <p>
            <a href='#' onClick={reHost} className='link-share' style={{ marginRight: '5px' }}>
              RE-HOST
            </a>
            <a href='#' className='link-share' onClick={onShareClicked}>
              SHARE
            </a>
          </p>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader className='modal-header-created'>
          <span className='c-txt-gray'>Share Link</span>
        </ModalHeader>
        <ModalBody>
          <div className='c-form'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control form-control--radius shareLink'
                value={shareLink}
                readOnly
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='modal-footer-created'>
          <div className='col-12'>
            <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
              {textBtn}
            </button>
          </div>
          <div className='col-12 mt-2'>
            <button className='btn btn--solid btn--solid04' onClick={toggle}>
              CLOSE
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
EventInfo.propTypes = {
  // eventId: PropTypes.string,
  title: PropTypes.string,
  closingTimestamp: PropTypes.number,
  pickupTimestamp: PropTypes.number,
  closingTime: PropTypes.string,
  pickupTime: PropTypes.string,
  id: PropTypes.string,
  shareCode: PropTypes.string,
  numBy: PropTypes.number,
}
