import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getUserToken } from '_utils/localData'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()

  const renderedComponent = (props) => {
    const user = getUserToken()

    if (!user) {
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: location },
          }}
        />
      )
    }

    return <Component {...props} />
  }

  return <Route {...rest} render={renderedComponent} />
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
}
