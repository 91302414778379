import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function SelectEvent({ listEvent, onChangeEvent, listEventGroupIdByHost }) {
  const listEventOption = listEventGroupIdByHost?.map((event, index) => ({
    value: index,
    label: event.title,
    id: event.id,
  }))

  const [state, setState] = useState({
    selectedOption: [],
  })

  useEffect(() => {
    setState({
      ...state,
      selectedOption: listEvent,
    })
  }, [listEvent])

  const handleChange = (selectedOption) => {
    setState({ selectedOption })
    onChangeEvent({ selectedOption })
  }

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      value={listEvent}
      onChange={handleChange}
      options={listEventOption}
      placeholder='Event'
      className='rounded-0 text-orange payment-status me-3'
    />
  )
}

export default SelectEvent
