import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import deliveryApi from '_api/delivery'
import {
  clearDeliveryByBuyer,
  clearListDeliveryAndDeliveryBooking
} from '_redux/modules/delivery'
import { getRememberedDeliveryAddress, updateRememberedDeliveryAddress } from '_redux/modules/user'
import DeliveryForm from './DeliveryForm'

export default function ModalDelivery(props) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()

  const { deliveryInfo, isShowModal, toggle } = props

  const { addressDeliveryBooking } = useSelector((state) => state.user)

  const {
    deliveryZones,
    listDeliveryCreated,
    eCreatedUserName,
    eCreatedUserPhotoUrl
  } = deliveryInfo

  const [location, setLocation] = useState(deliveryZones?.[0]?.name)
  const [deliveryTime, setDeliveryTime] = useState(listDeliveryCreated?.[0]?.id)
  const [address, setAddress] = useState('')
  const [comment, setComment] = useState('')
  const [errorMessage, setErrorMessage] = useState({ deliveryZone: '', deliveryAddress: '' })
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    dispatch(getRememberedDeliveryAddress())
  }, [])

  useEffect(() => {
    setAddress(addressDeliveryBooking)
  }, [addressDeliveryBooking])

  useEffect(() => {
    setLocation(deliveryZones?.[0]?.name)
    setDeliveryTime(listDeliveryCreated?.[0]?.id)
  }, [deliveryZones, listDeliveryCreated])

  const onChangeDZone = (e) => {
    setLocation(e.target.value)
  }

  const onChangeDTime = (e) => {
    setDeliveryTime(e.target.value)
  }

  const onChangeDAddress = (e) => {
    setAddress(e.target.value)
    setErrorMessage({ ...errorMessage, deliveryAddress: '' })
  }
  const onChangeDComment = (e) => {
    setComment(e.target.value)
  }

  const onSubmit = async () => {
    if (!address) {
      setErrorMessage({ ...errorMessage, deliveryAddress: 'Delivery address cannot be blank' })
      return
    }

    setIsSubmitting(true)

    if (address !== addressDeliveryBooking) {
      try {
        await deliveryApi.updateRememberedDeliveryAddress({ rememberedDeliveryAddress: address })
        dispatch(updateRememberedDeliveryAddress(address))
      } catch (e) {
        setIsSubmitting(false)
        addToast(e.msgResp, { appearance: 'error', autoDismiss: true })
      }
    }

    const payload = {
      deliveryId: deliveryTime,
      deliveryZone: deliveryZones.find((item) => item.name === location),
      address,
      comment,
    }
    try {
      const { msgResp } = await deliveryApi.createDeliveryBooking(payload)

      addToast('Booking delivery successfully.', { appearance: 'success', autoDismiss: true })
      toggle()
      setIsSubmitting(false)
      dispatch(clearDeliveryByBuyer())
      dispatch(clearListDeliveryAndDeliveryBooking())
      history.push(`/event/delivery-detail?ref=${msgResp.ref}`)
    } catch (e) {
      setIsSubmitting(false)
      addToast(e.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <Modal isOpen={isShowModal} centered toggle={toggle}>
      <ModalBody>
        <DeliveryForm
          dZone={location}
          deliveryTime={deliveryTime}
          dAddress={address}
          dComment={comment}
          onChangeDZone={onChangeDZone}
          onChangeDTime={onChangeDTime}
          onChangeDAddress={onChangeDAddress}
          onChangeDComment={onChangeDComment}
          onSubmit={onSubmit}
          deliveryZones={deliveryZones}
          createdUserName={eCreatedUserName}
          createdUserPhotoUrl={eCreatedUserPhotoUrl}
          errorMessage={errorMessage}
          listDeliveryTime={listDeliveryCreated}
          isSubmitting={isSubmitting}
        />
      </ModalBody>
    </Modal>
  )
}
