import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Button } from 'reactstrap'
import SelectDate from './SelectDate'
import SelectEvent from './SelectEvent'
import SelectEventStatus from './SelectEventStatus'
import SelectPaymentStatus from './SelectPaymentStatus'

function SelectFilter({
  filter,
  listEventGroupIdByHost,
  onChangeEvent,
  onChangeEventStatus,
  onChangePaymentStatus,
  onChangeRangeDate,
  handleClearFilter,
}) {
  const { listEvent, eventStatus, paymentStatus } = filter

  return (
    <>
      <div className='d-flex align-items-center'>
        <SelectEvent
          listEvent={listEvent}
          onChangeEvent={onChangeEvent}
          listEventGroupIdByHost={listEventGroupIdByHost}
        />
        <SelectEventStatus onChangeEventStatus={onChangeEventStatus} eventStatus={eventStatus} />
        <SelectDate onChangeRangeDate={onChangeRangeDate} />
        <SelectPaymentStatus
          onChangePaymentStatus={onChangePaymentStatus}
          paymentStatus={paymentStatus}
        />
        <Button
          role='button'
          outline
          className='btn btn-date rounded-0 ms-3 shadow-none'
          onClick={handleClearFilter}
        >
          Clear Filter
        </Button>
      </div>
    </>
  )
}

export default SelectFilter
