import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import DatePicker from './DatePicker'
import InputTime from './InputTime'

export default function ModalSchedule({ modalSchedule, toggleSchedule,
  openDate, openTime, onChangeOpenTime, onChangeOpenDate, isSubmitScheduled,
  idForm, refSubmitButton, handleOnSubmitSchedule
}) {
  return (
    <div>
      <Modal isOpen={modalSchedule} toggle={toggleSchedule} centered>
        <ModalHeader style={{ margin: '0 auto', padding: 0, border: 0 }}>
          Schedule Date & Time
        </ModalHeader>
        <ModalBody style={{ textAlign: 'center', padding: 0 }}>
          <div className='form-group' style={{ margin: '20px' }}>
            <div className='have-icon' style={{ color: 'black' }}>
              <DatePicker
                value={openDate}
                onChange={onChangeOpenDate}
              />
            </div>
          </div>
          <InputTime
            value={openTime}
            onChange={onChangeOpenTime}
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <button className='btn btn--solid btn--solid04' onClick={toggleSchedule}>
              CANCEL
            </button>
          </div>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <button
              disabled={isSubmitScheduled}
              id="submit-schedule"
              className='btn btn--org btn--org02'
              ref={refSubmitButton}
              type='submit'
              form={idForm}
              onClick={handleOnSubmitSchedule}
            >
              {isSubmitScheduled ? <Spinner color='light' size='sm' /> : 'SCHEDULE'}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
