import React from 'react'

export default function InputTime({ value, onChange }) {
  return (
    <div
      style={{
        border: '1px solid #ced4da',
        margin: '1rem',
        borderRadius: '5px',
        outline: 'none'
      }}>
      <input
        style={{
          border: 0,
          width: '100%',
          padding: '0.375rem 0.75rem',
          paddingRight: 0,
          textAlign: 'center',
          borderRadius: '5px',
        }}
        type="time"
        value={value}
        onChange={onChange}
      ></input>
    </div>
  )
}
