/* eslint-disable function-paren-newline */
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, Spinner } from 'reactstrap'
import * as XLSX from 'xlsx'
import { getListEventAndDelivery } from '_redux/modules/event'
import {
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'
import { convertTimeStringWithDuration, normalizeName } from '_utils/function'
import { SelectIdGroupContext } from '../../context/SelectGroupContext'
import DeliveryList from './components/DeliveryList'
import Pagination from './components/Pagination'
import SearchDelivery from './components/SearchDelivery'
import SelectFilter from './components/SelectFilter'
import './style.scss'

function DeliveryBooking() {
  const dispatch = useDispatch()
  const context = useContext(SelectIdGroupContext)
  const { groupId } = context.infoGroupSelected

  const { currentDeliveryAndDeliveryBooking, errorDeliveryAndDeliveryBooking } = useSelector(
    (state) => state.delivery
  )
  const { listEventAndDelivery } = useSelector((state) => state.event)

  const listDelivery = listEventAndDelivery?.deliveryBookings

  const { deliveryBookings, deliveryTime } = currentDeliveryAndDeliveryBooking

  /*
    will setTimeReRender when change status Delivery,
    or status Event Delivery, or Comment
  */
  const [timeReRender, setTimeReRender] = useState(0)
  const [, setTotalPurchase] = useState(0)
  const [, setDeliveryBookingCount] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [filter, setFilter] = useState({
    rangeDate: [
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ],
    paymentStatus: [],
  })

  const [page, setPage] = useState({
    currentPage: 1,
    deliveriesPerPage: 10,
  })
  const [sorting, setSorting] = useState({ field: 'eOrderNo', order: 'asc', type: 'number' })

  const { currentPage, deliveriesPerPage } = page
  const { rangeDate, paymentStatus } = filter
  const paymentStatusValue = paymentStatus.map((item) => item.value)

  // Logic for displaying Deliveries
  const indexOfLastDelivery = currentPage * deliveriesPerPage
  const indexOfFirstDelivery = indexOfLastDelivery - deliveriesPerPage

  // Logic for displaying page numbers
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(listDelivery?.length / deliveriesPerPage); i++) {
    pageNumbers.push(i)
  }

  const [loading, setLoading] = useState(false)

  const initialDelivery = async (_gid) => {
    setLoading(true)
    await dispatch(getListEventAndDelivery(_gid))
    setLoading(false)
  }

  useEffect(() => {
    if (groupId !== undefined) {
      initialDelivery(groupId)
    }
  }, [groupId, timeReRender])

  useEffect(() => {
    let price = 0
    let count = 0
    if (deliveryBookings && !!deliveryBookings.length) {
      deliveryBookings.forEach((item) => {
        if (
          item.status !== DELIVERY_HOST_CANCELLED &&
          item.bookerStatus !== DELIVERY_BUYER_CANCELLED
        ) {
          price += item.dzPrice
          count += 1
        }
      })
    }
    setTotalPurchase(price)
    setDeliveryBookingCount(count)
  }, [deliveryBookings])

  const onChangeSearch = (e) => {
    setSearchString(e.target.value)
  }

  const handleClearFilter = () => {
    setFilter({
      ...filter,
      rangeDate: [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ],
      paymentStatus: [],
    })
    setSearchString('')
  }

  const handleChangeRangeDate = (data) => {
    const formatStartDate = new Date(data[0].startDate.setHours(0, 0, 0, 0))
    const formatEndDate = new Date(data[0].endDate.setHours(23, 59, 59, 999))

    setFilter({
      ...filter,
      rangeDate: [
        {
          startDate: formatStartDate,
          endDate: formatEndDate,
          key: 'selection',
        },
      ],
    })
  }

  const handleChangePaymentStatus = (options) => {
    const optionSelected = options.selectedOption.map((option) => option)
    setFilter({
      ...filter,
      paymentStatus: [...optionSelected],
    })
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setPage({
        ...page,
        currentPage: currentPage - 1,
      })
    }
  }

  const handleNext = () => {
    if (currentPage < pageNumbers.length) {
      setPage({
        ...page,
        currentPage: currentPage + 1,
      })
    }
  }

  const listDeliveryFiltered = useMemo(() => {
    const { startDate, endDate } = rangeDate[0]

    if (paymentStatus.length > 0) {
      if (startDate && endDate) {
        return listDelivery?.filter(
          (item) =>
            moment(item.createdAt * 1000).isBetween(startDate, endDate) &&
            paymentStatusValue.includes(item.bookerStatus)
        )
      }
      return listDelivery?.filter((item) => paymentStatusValue.includes(item.bookerStatus))
    }

    if (startDate && endDate) {
      if (paymentStatus.length > 0) {
        return listDelivery?.filter((item) => paymentStatusValue.includes(item.bookerStatus))
      }
      return listDelivery?.filter((item) =>
        moment(item.createdAt * 1000).isBetween(startDate, endDate)
      )
    }

    if (startDate === null && endDate === null) {
      return listDelivery
    }
  }, [rangeDate, listDelivery, paymentStatus])

  const listDeliveries = useMemo(() => {
    if ((listDelivery && listDelivery.length > 0) || listDeliveryFiltered !== undefined) {
      let listDeliverySort = [...listDeliveryFiltered]
      if (searchString) {
        listDeliverySort = listDeliverySort.filter(
          (item) =>
            item.uPhone.toString().includes(searchString) ||
            item.uName.toLowerCase().includes(searchString.toLowerCase())
        )
      }
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          listDeliverySort = listDeliverySort.sort(
            (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
          )
        } else {
          listDeliverySort = listDeliverySort.sort(
            (a, b) => reversed * (a[sorting.field] - b[sorting.field])
          )
        }
      }
      return listDeliverySort
    }
  }, [sorting, searchString, listDeliveryFiltered, listDelivery])

  const currentDeliveries = listDeliveries?.slice(indexOfFirstDelivery, indexOfLastDelivery)

  const showNote = (rangeDates) => (
    <div className='d-flex align-items-center justify-content-between'>
      <div className='list-event-note'>
        Click on <strong>Names</strong> for Delivery Details and on <i className='icon-link'></i>
        for Payment Details.
      </div>
      {rangeDates[0].startDate && rangeDates[0].endDate && (
        <p className='mb-0'>
          List delivery from{' '}
          <span className='text-orange fw-bold'>
            {moment(rangeDates[0].startDate).format('DD/MM/YYYY')}
          </span>{' '}
          to{' '}
          <span className='text-orange fw-bold'>
            {moment(rangeDates[0].endDate).format('DD/MM/YYYY')}
          </span>
        </p>
      )}
    </div>
  )

  const handleExport = (arrayDeliveryInfo) => {
    const listSheetName = []
    const wb = XLSX.utils.book_new()
    for (let n = 0; n < arrayDeliveryInfo?.length; n++) {
      const rowsOfData = new Array(arrayDeliveryInfo[n].listOrderDelivery.length) || []
      for (let index = 0; index < arrayDeliveryInfo[n].listOrderDelivery.length; index++) {
        const delivery = arrayDeliveryInfo[n].listOrderDelivery[index]

        let statusString = ''
        let bookerStatusString = ''

        const {
          dzName,
          dzPrice,
          address,
          // orderReference,
          uName,
          uPhone,
          uEmail,
          status,
          comment,
          bookerStatus,
        } = delivery

        switch (status) {
          case DELIVERY_HOST_CANCELLED:
            statusString = 'Cancelled'
            break
          case DELIVERY_HOST_UNPAID:
            statusString = 'Not paid'
            break
          case DELIVERY_HOST_PAID:
            statusString = 'Paid'
            break
          default:
            break
        }

        switch (bookerStatus) {
          case DELIVERY_BUYER_CANCELLED:
            bookerStatusString = 'Cancelled'
            break
          case DELIVERY_BUYER_REFUND:
            bookerStatusString = 'Refund'
            break
          case DELIVERY_BUYER_UNPAID:
            bookerStatusString = 'Not paid'
            break
          case DELIVERY_BUYER_PAID:
            bookerStatusString = 'Paid'
            break
          default:
            break
        }

        rowsOfData[index] = [
          dzName,
          `$${dzPrice}`,
          address,
          // orderReference,
          uName,
          uPhone,
          uEmail,
          statusString,
          bookerStatusString,
          comment,
        ]
      }

      const rows = [
        [
          'Zone name',
          'Zone price',
          'Address',
          // 'Order ref',
          'Buyer name',
          'Buyer phone',
          'Buyer email',
          'Status',
          'Booker Status',
          'Comment',
        ],
        ...rowsOfData,
      ]
      const fileName = normalizeName(
        `Delivery on ${convertTimeStringWithDuration(
          arrayDeliveryInfo[n].deliveryTime,
          arrayDeliveryInfo[n].deliveryDuration
        )}`
      )
      const formatSheetName = fileName.slice(0, 28).trim()
      const CheckSheetNameIsExit = listSheetName.includes(formatSheetName)
      let sheetAfterFormat
      if (CheckSheetNameIsExit) {
        sheetAfterFormat = `(1)${formatSheetName}`
        const checkSheetAfterFormatIsExit = listSheetName.includes(sheetAfterFormat)
        if (checkSheetAfterFormatIsExit) {
          const countItemIsDuplicate = listSheetName.filter(
            (item) => item === sheetAfterFormat
          ).length
          sheetAfterFormat = `(${countItemIsDuplicate + 1})${formatSheetName}`
        }
      } else {
        sheetAfterFormat = formatSheetName
      }
      const data = rows
      const ws = XLSX.utils.aoa_to_sheet(data)
      XLSX.utils.book_append_sheet(wb, ws, sheetAfterFormat)
      listSheetName.push(sheetAfterFormat)
    }
    XLSX.writeFile(wb, 'Total order.xlsx')
  }

  const exportOrder = () => {
    const uniqueId = []
    const arrayDeliveryInfo = []
    const unique = listDeliveryFiltered.filter((element) => {
      const isDuplicate = uniqueId.includes(element.did)

      if (!isDuplicate) {
        uniqueId.push(element.did)
        return true
      }
      return false
    })
    for (let i = 0; i < unique.length; i++) {
      arrayDeliveryInfo.push({
        id: unique[i].did,
        deliveryDuration: unique[i].dDeliveryDuration,
        deliveryTime: unique[i].dDeliveryTime,
        listOrderDelivery: listDeliveryFiltered.filter((item) => item.did === unique[i].did),
      })
    }
    handleExport(arrayDeliveryInfo)
  }

  return (
    <div className='container-fluid px-3 manage-delivery-booking'>
      {currentDeliveries && errorDeliveryAndDeliveryBooking ? <div>No Delivery Found</div> : null}
      {currentDeliveries && !errorDeliveryAndDeliveryBooking ? (
        <>
          <Row className='my-3 align-items-center'>
            <Col>
              <span className='ps-3 fw-bold text-orange fs-2'>Manage Deliveries</span>
            </Col>
            <Col className='d-flex align-items-center text-uppercase justify-content-end'>
              <Button
                role='button'
                className='btn-export rounded-0 ms-3 shadow-none'
                outline
                onClick={exportOrder}
              >
                Export List
              </Button>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col>
              <SearchDelivery
                searchString={searchString}
                onChange={onChangeSearch}
                styles={{ marginTop: 0 }}
              />
            </Col>
            <Col>
              <SelectFilter
                handleChangeRangeDate={handleChangeRangeDate}
                handleChangePaymentStatus={handleChangePaymentStatus}
                filter={filter}
              />
            </Col>
            <Col>
              <Button
                role='button'
                outline
                className='btn btn-date rounded-0 ms-3 shadow-none'
                onClick={handleClearFilter}
              >
                Clear Filter
              </Button>
            </Col>
            <Col>
              <Pagination
                filter={filter}
                onHandlePrev={handlePrev}
                onHandleNext={handleNext}
                currentDeliveries={currentDeliveries}
              />
            </Col>
          </Row>
          <div className='row-top'>
            {showNote(rangeDate)}

            {loading ? (
              <div className='text-center mt-5'>
                <Spinner />
              </div>
            ) : currentDeliveries && !!currentDeliveries.length > 0 ? (
              <DeliveryList
                listDelivery={currentDeliveries}
                setTimeReRender={setTimeReRender}
                deliveryTime={deliveryTime}
                setSorting={setSorting}
              />
            ) : null}

            {!loading && currentDeliveries.length === 0 && (
              <div className='text-center fw-bold fs-6 my-3'>Have No Found Deliveries</div>
            )}
          </div>
        </>
      ) : null}

      {currentDeliveries === undefined && (
        <div
          className='d-flex flex-column align-items-center justify-content-center'
          style={{ height: 500 }}
        >
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default DeliveryBooking
