import { createContext, useEffect, useState } from 'react'
import { getUserInfo, getGroupIdSelected, setGroupIdSelected } from '_utils/localData'
import { GROUP_IS_HOST, GROUP_IS_ADMIN } from '_utils/constant'

export const SelectIdGroupContext = createContext()

function SelectGroupContext({ children }) {
  const userInfo = getUserInfo()
  const [infoGroupSelected, setInfoGroupSelected] = useState({})
  const [eventQuantity, setEventQuantity] = useState()
  const [dataWhenSync, setDataWhenSync] = useState()

  useEffect(() => {
    if (getGroupIdSelected()) {
      const myGroups = userInfo?.groups
      const groupSelected = myGroups?.filter((group) => group.gid === getGroupIdSelected())[0]
      setInfoGroupSelected({
        groupId: groupSelected.gid,
        groupName: groupSelected.name,
        totalMembers: groupSelected.totalMembers,
      })
    } else {
      const myFirstGroup = userInfo?.groups.filter(
        (item) => item.isHost === GROUP_IS_HOST || item.isAdmin === GROUP_IS_ADMIN
      )[0]
      setGroupIdSelected(myFirstGroup?.gid)
      setInfoGroupSelected({
        groupId: myFirstGroup.gid,
        groupName: myFirstGroup.name,
        totalMembers: myFirstGroup.totalMembers,
      })
    }
  }, [])

  return (
    <SelectIdGroupContext.Provider
      value={{
        setInfoGroupSelected,
        infoGroupSelected,
        eventQuantity,
        setEventQuantity,
        dataWhenSync,
        setDataWhenSync,
      }}
    >
      {children}
    </SelectIdGroupContext.Provider>
  )
}

export default SelectGroupContext
