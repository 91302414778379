import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { DOMAIN, EVENT_STATUS_OPEN } from '_utils/constant'
import { convertToCurrentGMT, isDisableEvent } from '_utils/function'
import { getUserInfo } from '_utils/localData'

import Logo01 from '_images/copyright.svg'
import Logo02 from '_images/logo_02.svg'
import './style.scss'

const Header = ({ location }) => {
  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const { allEvents } = useSelector((state) => state.event)
  const { myOrders } = useSelector((state) => state.order)

  const userInfo = getUserInfo()

  const [totalMyPurchases, setTotalMyPurchases] = useState(0)

  const { domainFeatures = [], groups } = userInfo

  const currDomainFeature = domainFeatures.find((item) => item.domainName === DOMAIN) || {}

  const group = groups?.length && groups?.find((g) => currDomainFeature.groupId === g.gid)

  const [totalEvents, setTotalEvents] = useState(0)

  useEffect(() => {
    setTotalEvents(allEvents?.filter((item) => item.status === EVENT_STATUS_OPEN)?.length)
  }, [allEvents])

  useEffect(() => {
    const listOrderUnClosed = myOrders.filter((item) => {
      const isDisable = isDisableEvent(
        item?.status,
        item?.buyerStatus,
        convertToCurrentGMT(item?.ePickupTime),
        item?.ePickupDuration
      )
      if (!isDisable) {
        return item
      }
      return null
    })
    setTotalMyPurchases(listOrderUnClosed.length)
  }, [myOrders])

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])

  return (
    <div className='header align-self-start'>
      <div className='container'>
        {userInfo?.groups?.length === 1 && !group ? (
          <div className='main-logo text-left'>
            <h4 className='ttl-grp'>{userInfo?.groups?.[0]?.name}</h4>
            <p className='ttl-company'>
              <img src={Logo01} alt='' />
            </p>
          </div>
        ) : group ? (
          <div className='main-logo'>
            <Link to='/'>
              <img src={Logo02} />
              <span>{group?.name}</span>
            </Link>
          </div>
        ) : (
          <div className='main-logo '>
            <Link to='/'>
              <img src={Logo02} />
              <span>Good things must share!</span>
            </Link>
          </div>
        )}

        <Link to='/user/profile' className='avatar-top'>
          <div className='avatar-top__email'>
            <span id='totalNotififications'></span> <i className='icon-email'></i>
          </div>
          <div className='avatar-top__img'>
            <img
              id='userAvatar'
              src={
                userInfo?.photoUrl
                  ? userInfo.photoUrl
                  : process.env.REACT_APP_AVATAR_URL + userInfo?.name
              }
              alt=''
            />
          </div>
        </Link>
      </div>
      <div className='nav-menu'>
        <div className='container'>
          <div className='row'>
            <div className='col-4'>
              <Link to='/' className={!pathname[1] ? 'active' : ''}>
                All {!!allEvents?.length && <span id='totalEvents'>({totalEvents})</span>}
              </Link>
            </div>
            <div className='col-4'>
              <Link
                to='/event/list-my-purchases'
                className={
                  pathname[1] === 'event' && pathname[2] === 'list-my-purchases' ? 'active' : ''
                }
              >
                My Purchase{' '}
                {!!totalMyPurchases && <span id='totalMyPurchases'>({totalMyPurchases})</span>}
              </Link>
            </div>
            <div className='col-4'>
              {Object.keys(currDomainFeature).length ? (
                <Link
                  to={`/group/list-events?groupId=${currDomainFeature?.groupId}`}
                  className={pathname[1] === 'group' ? 'active' : ''}
                >
                  My Group
                </Link>
              ) : (
                <Link
                  to='/group/list-my-groups'
                  className={pathname[1] === 'group' ? 'active' : ''}
                >
                  My Group
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header)
