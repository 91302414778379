import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import eventApi from '_api/event'
import { EVENT_CLOSE } from '_utils/constant'
import { convertToCurrentGMT, getDateRemainFromNow } from '_utils/function'
import { setCreateEventData } from '_utils/localData'

const now = moment().unix()
const MyEventItem = ({ event, openModal, setShareLink, isByAdmin = false }) => {
  const [shareCode, setShareCode] = useState(null)
  const { addToast } = useToasts()

  const [greyOut, setGreyOut] = useState(false)

  const history = useHistory()

  const onShare = () => {
    setShareLink(
      `${process.env.REACT_APP_APP_URL}/event/detail-shared?eid=${event.id}&shareCode=${shareCode}`
    )
    openModal()
  }
  /*
    Need to call API here, because the data of some
    fields will not be correct when getting data from redux
  */
  const getEvent = async (eid, edit = false) => {
    if (!eid) return
    let eventDetail = {}
    try {
      const res = await eventApi.getEventDetail({
        eid,
        isGetProducts: true,
        isGetDeliveryZones: true,
      })
      eventDetail = res.msgResp
      if (eventDetail && eventDetail.id) {
        const { closingTime, pickupTime } = eventDetail

        const closingTimeCurrentGMT = convertToCurrentGMT(closingTime)

        const pickupTimeCurrentGMT = convertToCurrentGMT(pickupTime)
        eventDetail = {
          ...eventDetail,
          closingTime: closingTimeCurrentGMT,
          pickupTime: pickupTimeCurrentGMT,
        }
        if (!edit) {
          delete eventDetail.id
          delete eventDetail.deliveryStatus
          eventDetail.deliveryCost = 0
          eventDetail.discount = 0
          setCreateEventData(eventDetail)
          history.push('/event/create')
        } else {
          for (let i = 0; i < eventDetail.products.length; i++) {
            eventDetail.products[i].pid = eventDetail.products[i].id
          }
          setCreateEventData(eventDetail)
          history.push(`/event/update?id=${eventDetail?.id}`)
        }
      }
    } catch (error) {
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleRehostEvent = () => {
    if (!event.id) return
    getEvent(event.id)
  }
  const handleEditEvent = () => {
    if (!event.id) return
    getEvent(event.id, true)
  }
  useEffect(() => {
    setGreyOut(false)
    if (event?.pickupTimestamp < now || event?.status === EVENT_CLOSE) {
      setGreyOut(true)
    }
  }, [event?.status, event?.pickupTimestamp])

  useEffect(() => {
    const getShareCode = async () => {
      const { msgCode, msgResp } = await eventApi.getShareEventCode(event.id)
      if (msgCode % 100 === 0) {
        setShareCode(msgResp.shareCode)
      }
    }

    if (event?.shareCode) {
      setShareCode(event.shareCode)
    } else if (!isByAdmin) {
      getShareCode()
    }
  }, [event, isByAdmin])

  const onLinkToListOrder = () => {
    if (isByAdmin) {
      history.push(`/event/manage-orders?eventId=${event.id}&isByAdmin=true`)
    } else {
      history.push(`/event/manage-orders?eventId=${event.id}`)
    }
  }

  const disabledStyleActions = {
    background: 'rgb(241, 241, 241)',
    pointerEvents: 'none',
  }

  const colorGray = {
    color: 'gray',
  }

  return (
    <div
      className='event-item group-list group-list--event group-list--event01'
      style={greyOut ? { backgroundColor: '#dee2e6' } : {}}
    >
      <div className='group-list__item' style={greyOut ? { backgroundColor: '#dee2e6' } : {}}>
        <div className='group-list__info'>
          <h4
            onClick={onLinkToListOrder}
            className='cursor-pointer'
            style={greyOut ? colorGray : {}}
          >
            {event.title}
          </h4>

          <p className='paragraph-pre-wrap' style={greyOut ? colorGray : {}}>
            {event.description}
          </p>
        </div>
        <div className='group-list__btn text-right'>
          <span
            className='txt-date mb-1'
            style={greyOut ? colorGray : {}}
          >{`closing in ${getDateRemainFromNow(convertToCurrentGMT(event?.closingTime))}`}</span>
          <span
            className='txt-date mb-2'
            style={greyOut ? colorGray : {}}
          >{`Delivery in ${getDateRemainFromNow(convertToCurrentGMT(event?.pickupTime))}`}</span>
          <span className='txt-number' style={greyOut ? { backgroundColor: 'gray' } : {}}>
            {event.totalOrderCount} BUY
          </span>
        </div>
      </div>
      <ul className='nav-tab'>
        <li style={isByAdmin ? disabledStyleActions : {}} onClick={handleEditEvent}>
          Edit Post
        </li>
        <li style={isByAdmin ? disabledStyleActions : {}} onClick={handleRehostEvent}>
          Re-host
        </li>
        <li style={isByAdmin ? disabledStyleActions : {}} onClick={onShare}>
          Share
        </li>
      </ul>
    </div>
  )
}

MyEventItem.propTypes = {
  event: PropTypes.object,
  openModal: PropTypes.func,
  setShareLink: PropTypes.func,
}

export default MyEventItem
