/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import TableHeaderWithSort from '_components/TableHeaderWithSort'
import useQuery from '_hooks/useQuery'
import { approveJoinGroup, getGroupByIdWithMembers, removeMember } from '_redux/modules/group'
import { GROUP_IS_ADMIN, PAYMENT_STATUS_PAID, STATUS_PENDING } from '_utils/constant'
import { getUserInfo } from '_utils/localData'

import GroupDetail from '../components/GroupDetail'
import GroupMember from '../components/GroupMember'
import ModalApprove from '../components/ModalApprove'
import './style.scss'

const ListMembers = () => {
  const params = useQuery()
  const dispatch = useDispatch()
  const idGroupInParam = params.get('groupId')
  const { myGroups } = useSelector((state) => state.group)

  const headers = [
    { name: 'Join date', field: 'createdAt', type: 'number', className: 'col-3', sortable: true },
    { name: 'Name', field: 'uName', className: 'col-3', type: 'string', sortable: true },
    { name: 'Events', field: 'totalEvents', type: 'number', className: 'col-2', sortable: true },
    { name: 'Purchase', field: 'orderCount', type: 'number', className: 'col-2', sortable: true },
    { name: 'Permission', field: 'role', type: 'string', className: 'col-2', sortable: true },
  ]

  const [sorting, setSorting] = useState({ field: 'createdAt', order: 'desc', type: 'number' })
  const [members, setMembers] = useState([])

  const [groupInfo, setGroupInfo] = useState(
    myGroups?.filter((group) => group.gid === idGroupInParam || group.id === idGroupInParam)[0]
  )

  const userInfo = getUserInfo()
  const isAdminOfGroup = groupInfo?.members?.filter((x) => x.uid === userInfo.id)[0]?.isAdmin
  const [memberSelected, setMemberSelected] = useState(null)

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const { addToast } = useToasts()

  const [domain, setDomain] = useState({})

  const [searchString, setSearchString] = useState('')
  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  const onClickPending = (member) => {
    toggle()
    setMemberSelected(member)
  }

  const onReject = async () => {
    try {
      const postData = {
        groupId: memberSelected.gid,
        userId: memberSelected.uid,
      }
      dispatch(removeMember(postData))
      addToast(`${memberSelected?.name} was rejected `, {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error, { appearance: 'error', autoDismiss: true })
    }
    toggle()
  }

  const onAccept = async () => {
    try {
      const postData = {
        groupId: memberSelected.gid,
        userId: memberSelected.uid,
      }
      dispatch(approveJoinGroup(postData))
      addToast(`${memberSelected?.name} was accepted `, {
        appearance: 'info',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error, { appearance: 'error', autoDismiss: true })
    }
    toggle()
  }

  useEffect(() => {
    dispatch(getGroupByIdWithMembers({ id: idGroupInParam }))
  }, [])

  useEffect(() => {
    setGroupInfo(
      myGroups?.filter((group) => group.gid === idGroupInParam || group.id === idGroupInParam)[0]
    )
  }, [myGroups])

  useEffect(() => {
    if (userInfo) {
      const { domainFeatures = [] } = userInfo
      if (domainFeatures.length) {
        const domainFeaturePaid = domainFeatures.filter(
          (item) =>
            item.domainPaymentStatus >= PAYMENT_STATUS_PAID &&
            item.domainStatus >= STATUS_PENDING &&
            item.groupId === idGroupInParam
        )
        if (domainFeaturePaid.length) {
          setDomain(domainFeaturePaid[0])
        } else {
          setDomain({})
        }
      } else {
        setDomain({})
      }
    }
  }, [])

  const listMembers = useMemo(() => {
    if (groupInfo && groupInfo.members) {
      let sortListMembers = groupInfo.members

      sortListMembers = sortListMembers.map((item) => {
        if (item.approval === 1) {
          return { ...item, role: 'PENDING' }
        }
        return { ...item, role: item?.isAdmin ? 'ADMIN' : item?.isHost ? 'HOST' : 'MEMBER' }
      })

      sortListMembers = sortListMembers.map((item) => ({
        ...item,
        totalEvents: item.totalEvents ? item.totalEvents : 0,
      }))

      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          sortListMembers = sortListMembers.sort(
            (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
          )
        } else {
          sortListMembers = sortListMembers.sort(
            (a, b) => reversed * (a[sorting.field] - b[sorting.field])
          )
        }
      }

      if (searchString) {
        sortListMembers = sortListMembers.filter((val) => {
          if (val.uName.toLowerCase().includes(searchString.toLowerCase())) {
            return val
          }
        })
      }
      sortListMembers = sortListMembers.map((item, index = 0) => ({ ...item, no: index + 1 }))
      return sortListMembers
    }
  })

  return (
    <div className='row-top list-members-page'>
      <GroupDetail
        idGroupInParam={idGroupInParam}
        groupById={groupInfo}
        domain={domain}
        setDomain={setDomain}
      />

      <div className='list-user list-user--havelink'>
        <h3 className='c-txt-14 text-center c-ttl-blue text-underline'>Members</h3>
        {groupInfo?.isAdmin === 0 &&
        groupInfo?.isHost === 0 &&
        groupInfo?.accessMemberList === 0 ? (
          <p className='text-center'>You can not see member list of this group</p>
        ) : (
          <>
            <div className='search'>
              <input
                className='search-input'
                type='text'
                placeholder='Search by name'
                value={searchString}
                onChange={onChangeSearch}
              />
            </div>
            <div>
              <div className='list-user__item'>
                <TableHeaderWithSort
                  headers={headers}
                  onSorting={(field, order, type) => setSorting({ field, order, type })}
                  defaultSortingField='createdAt'
                  defaultSortingOrder='desc'
                  className='row mt-2 font-weight-bold'
                />
                {listMembers?.map((item, index) => {
                  if (item.visible === 0 && !isAdminOfGroup) return null
                  return (
                    <GroupMember
                      userInfo={item}
                      key={index}
                      role={isAdminOfGroup === GROUP_IS_ADMIN ? 'admin' : 'member'}
                      onClickPending={() => onClickPending(item)}
                    />
                  )
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <ModalApprove
        modal={modal}
        toggle={toggle}
        memberSelected={memberSelected}
        onReject={onReject}
        onAccept={onAccept}
      />
    </div>
  )
}

export default ListMembers
