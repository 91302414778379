/* eslint-disable function-paren-newline */
import eventApi from '_api/event'
import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'

export const useEvents = () => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [allEvents, setAllEvents] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEvents, setTotalEvents] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const PageSize = 10

  const getAllEventsOpening = async () => {
    setLoading(true)
    try {
      const events = []
      const { msgResp } = await eventApi.getListEventWithoutAuth(PageSize, currentPage)

      if (msgResp?.length === 0) {
        setAllEvents([])
        setTotalEvents(0)
        setTotalPages(0)
        return
      }

      if (msgResp.totalPages >= 2) {
        await Promise.all(
          Array.from({ length: msgResp.totalPages - 1 }, async (_, i) => {
            const response = await eventApi.getListEventWithoutAuth(PageSize, i + 2)
            events.push(...response.msgResp.events)
            return response.msgResp.events
          })
        )
      }

      setAllEvents([...msgResp.events, ...events])
      setTotalEvents(msgResp.totalEvents)
      setTotalPages(msgResp.totalPages)
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false)
    }
  }

  return {
    getAllEventsOpening,
    allEvents,
    totalEvents,
    loading,
    currentPage,
    setCurrentPage,
    PageSize,
    totalPages,
  }
}
