import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { CollapseContext } from '../context/CollapseMenuContext'
import Dashboard from './Dashboard'
import DeliveryBooking from './DeliveryBooking'
import Event from './Event'
import ManageOrder from './ManageOrder'
import Members from './Members'
import Settings from './Settings'
import UserProfile from './UserProfile'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import SwitchMobileModel from './components/SwitchMobileModel'
import AllPage from './pages/AllPage'
import ListMyGroupsPage from './pages/ListMyGroups'
import ListMyPurchasesPage from './pages/ListMyPurchases'
import './style.scss'

function MainDesktop() {
  const { isCollapse } = useContext(CollapseContext)

  return (
    <React.Fragment>
      <div className='container-fluid main-desktop h-100' style={{ minWidth: '1200px' }}>
        <div className='row h-100'>
          <div className={`${isCollapse ? 'col-1' : 'col-2'} position-relative border-right`}>
            <Sidebar />
          </div>
          <div
            className={`${isCollapse ? 'col-11' : 'col-10'} d-flex flex-column h-100 px-0`}
            style={{
              overflow: 'hidden scroll',
            }}
          >
            <Switch>
              <Route path='/' exact component={Dashboard} />
              <Route path='/settings' component={Settings} />
              <Route path='/user' component={UserProfile} />
              <Route path='/event' component={Event} />
              <Route path='/members' component={Members} />
              <Route path='/manage-orders' component={ManageOrder} />
              <Route path='/all-page' component={AllPage} />
              <Route path='/list-my-purchases' component={ListMyPurchasesPage} />
              <Route path='/list-my-groups' component={ListMyGroupsPage} />
              {/* <Route path='/all-page' component={AllPage} /> */}
              <Route path='/group' component={() => <SwitchMobileModel />} />
              <Route path='/delivery-booking' component={DeliveryBooking} />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MainDesktop
