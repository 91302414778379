import React from 'react'
import EventList from '_pages/Event/EventList'

function AllPage() {
  return (
    <div className='w-50 mx-auto'>
      <EventList />
    </div>
  )
}

export default AllPage
