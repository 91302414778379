/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { SUBDOMAIN_NOT_DEPLOY, SUBDOMAIN_DEPLOYED } from '_utils/constant'
import groupIcon from '_images/group-icon.png'
import { getUserInfo } from '_utils/localData'

const GroupItem = ({
  groupInfo,
  isJoin = false,
  requested,
  setGroupSelected,
  toggleModal,
  disabled = false,
}) => {
  const { totalMembers, totalEvents, photoUrl, id, name, gid } = groupInfo

  const userInfo = getUserInfo()

  const { domainFeatures = [] } = userInfo

  const domainIncluded = domainFeatures.find((g) => g.groupId === gid) || {}

  const handleJoinGroup = () => {
    const typeGroup = groupInfo.autoApproval
    if (typeGroup === 0) {
      setGroupSelected(groupInfo)
      toggleModal()
    } else if (typeGroup === 1) {
      setGroupSelected(groupInfo)
      toggleModal()
    }
  }
  return (
    <div className='group-list__item'>
      <div className={`group-list__img ${disabled && 'disabled'}`}>
        <div className='c-image'>
          <Link to={`/group/list-events?groupId=${id}`}>
            <img src={photoUrl?.url || photoUrl || groupIcon} />
          </Link>
        </div>
      </div>
      <div className={`group-list__info ${disabled && 'disabled'}`}>
        <Link to={`/group/list-events?groupId=${id}`}>
          <h3>{name} </h3>
        </Link>
        <p>
          <span className='t-member'>{`${totalMembers} Members`}</span>
          <span className='t-deal'>
            {isJoin ? `${totalEvents} Organized Deals` : `${totalEvents} Organized Events`}
          </span>
        </p>

        {domainIncluded
          && domainIncluded.domainStatus
          && domainIncluded.domainStatus === SUBDOMAIN_NOT_DEPLOY ?
          <p>
            <span style={{ color: '#e0a800' }}>{domainIncluded.domainName}
            </span>
          </p>
          : null}

        {domainIncluded
          && domainIncluded.domainStatus
          && domainIncluded.domainStatus === SUBDOMAIN_DEPLOYED ?
          <p>
            <a href={`https://${domainIncluded.domainName}`}
              target={'_blank'}
              style={{ color: '#51aaaa' }}
              rel="noreferrer"
            >
              {domainIncluded.domainName}
            </a>
          </p> : null}
      </div>
      {isJoin && (
        <div className='group-list__btn'>
          <button
            className={`btn btn--solid ${requested && 'btn--pending'}`}
            disabled={requested}
            onClick={handleJoinGroup}
          >
            {requested ? 'REQUESTED' : 'JOIN'}
          </button>
        </div>
      )}
    </div>
  )
}

GroupItem.propTypes = {
  groupInfo: PropTypes.object.isRequired,
  isJoin: PropTypes.bool,
  setGroupSelected: PropTypes.func,
}

export default GroupItem
