/* eslint-disable multiline-ternary */
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import CreateGroupPage from './Create'
import UpdateGroupPage from './UpdateGroup'
import SearchGroup from './SearchGroup'
import ListEvents from './ListEvents'
import ListMembers from './ListMembers'
import MemberDetail from './MemberDetail'
import GroupList from './GroupList'
import JoinForm from './JoinForm'

import './style.scss'

const Group = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/list-my-groups`} component={GroupList} />
      <Route path={`${path}/create`} component={CreateGroupPage} />
      <Route path={`${path}/update`} component={UpdateGroupPage} />
      <Route path={`${path}/search`} component={SearchGroup} />
      <Route path={`${path}/list-events`} component={ListEvents} />
      <Route path={`${path}/list-members`} component={ListMembers} />
      <Route path={`${path}/detail-member`} component={MemberDetail} />
      <Route path={`${path}/join/:gid`} component={JoinForm} />
    </Switch>
  )
}

export default Group
