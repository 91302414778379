import React from 'react'
import useLogout from '_hooks/useLogout'
import NotificationList from './components/NotificationList'
import FeedBack from './components/FeedBack'

const Notifications = () => (
  <div className='profile-page'>
    <NotificationList />
    <div className='row row--bot align-self-end'>
      <FeedBack />
      <div className='col-12 logout-btn' onClick={useLogout}>
        <ul className='action-link'>
          <li>Logout</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Notifications
