import React from 'react'
import moment from 'moment'
import { getDateRemainFromNow } from '_utils/function'

export default function HeaderEventInfo({
  createdUserPhotoUrl,
  createdUserName,
  eClosingTime,
  eTotalOrderCount,
  groupName,
  collectionTimeStart = null,
  collectionTimeEnd = null,
  ePickupTime = null,
  isDisable,
}) {
  return (
    <>
      <div className='group-list__img'>
        <div className='c-image'>
          <a href='' onClick={(e) => e.preventDefault()}>
            {' '}
            <img
              src={createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName}
              style={isDisable ? { filter: 'grayscale(100%)' } : {}}
              alt=''
            />
          </a>
        </div>
      </div>
      <div className='group-list__info'>
        <h3>
          <a href='' onClick={(e) => e.preventDefault()}>
            {createdUserName}
          </a>
        </h3>
        <p>{groupName}</p>
        <p>
          <a href='#'></a>
        </p>
      </div>
      <div className='group-list__btn text-right'>
        <span className='txt-date mb-2' style={{ lineHeight: '1.3' }}>
          {ePickupTime && collectionTimeStart && collectionTimeEnd ? (
            <div>
              <span>{moment(ePickupTime, 'DD-MM-YYYY').format('DD-MM-YYYY')}</span>
              <span
                style={{ display: 'block' }}
              >{`${collectionTimeStart} - ${collectionTimeEnd}`}</span>
            </div>
          ) : (
            <span>{`closing in ${getDateRemainFromNow(eClosingTime)}`}</span>
          )}
        </span>
        <span
          className='txt-number'
          style={isDisable ? { backgroundColor: 'gray' } : {}}
        >{`${eTotalOrderCount} BUY`}</span>
      </div>
    </>
  )
}
