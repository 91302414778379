import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import moment from 'moment'
import {
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_UNPAID,
  DELIVERY_HOST_PAID,
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_BUYER_PAID,
  DELIVERY_CLOSED,
  DELIVERY_OPENED,
  DEFAULT_DELIVERY_OPTION,
  LIST_TIME
} from '_utils/constant'
import {
  exportToCsv,
  normalizeName,
  convertToGMT0,
  convertTimeStringWithDuration,
  convertToCurrentGMT
} from '_utils/function'
import deliveryApi from '_api/delivery'
import { updateDeliveryInfo } from '_redux/modules/delivery'
import { getUserInfo } from '_utils/localData'
import DeliveryOptionsModal from '../components/DeliveryOptionsModal'

export default function NavBar({
  handelBroadcast,
  setTimeReRender,
  deliveryInfo
}) {
  const {
    deliveryBookings: listDelivery,
    deliveryTime,
    deliveryDuration,
    deliveryStatus,
    id, } = deliveryInfo

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const userInfo = getUserInfo()
  const { id: userId } = userInfo

  const [modal, setModal] = useState(false)
  const [message, setMessage] = useState('')
  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])

  const [deliveryHourStart, setDeliveryHourStart] = useState('')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('')
  const [deliveryDate, setDeliveryDate] = useState(
    new Date()
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const toggle = () => setModal(!modal)

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onCreateNotification = () => {
    handelBroadcast(message)
    toggle()
  }

  const onExportOrder = () => {
    const rowsOfData = new Array(listDelivery.length) || []
    for (let index = 0; index < listDelivery.length; index++) {
      const delivery = listDelivery[index]

      let statusString = ''
      let bookerStatusString = ''

      const {
        dzName,
        dzPrice,
        address,
        // orderReference,
        uName,
        uPhone,
        uEmail,
        status,
        comment,
        bookerStatus,
      } = delivery

      switch (status) {
        case DELIVERY_HOST_CANCELLED:
          statusString = 'Cancelled'
          break
        case DELIVERY_HOST_UNPAID:
          statusString = 'Not paid'
          break
        case DELIVERY_HOST_PAID:
          statusString = 'Paid'
          break
        default:
          break
      }

      switch (bookerStatus) {
        case DELIVERY_BUYER_CANCELLED:
          bookerStatusString = 'Cancelled'
          break
        case DELIVERY_BUYER_REFUND:
          bookerStatusString = 'Refund'
          break
        case DELIVERY_BUYER_UNPAID:
          bookerStatusString = 'Not paid'
          break
        case DELIVERY_BUYER_PAID:
          bookerStatusString = 'Paid'
          break
        default:
          break
      }

      rowsOfData[index] = [
        dzName,
        `$${dzPrice}`,
        address,
        // orderReference,
        uName,
        uPhone,
        uEmail,
        statusString,
        bookerStatusString,
        comment,
      ]
    }

    const rows = [
      [
        'Zone name',
        'Zone price',
        'Address',
        // 'Order ref',
        'Buyer name',
        'Buyer phone',
        'Buyer email',
        'Status',
        'Booker Status',
        'Comment',
      ],
      ...rowsOfData,
    ]

    const fileName = normalizeName(
      `Delivery on ${convertTimeStringWithDuration(deliveryTime, deliveryDuration)}`,
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const onUpdateStatusDelivery = async (close = false) => {
    try {
      const r = confirm('Are you sure?')
      if (r === true) {
        let data = {}
        if (close) {
          data = { deliveryStatus: DELIVERY_CLOSED }
        } else {
          data = { deliveryStatus: DELIVERY_OPENED }
        }
        await deliveryApi.updateDeliveryStatus(id, data)
        dispatch(updateDeliveryInfo(id, data))
        setTimeReRender((prevState) => prevState + 1)
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnClickOk = async () => {
    setIsSubmitting(true)
    const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
    const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
    const timeStart = LIST_TIME[deliveryStartIndex].value
    const timeEnd = LIST_TIME[deliveryEndIndex].value
    const deliveryDur = (timeEnd - timeStart) * 3600
    if (deliveryDur <= 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    const deliveryTimeLocal = `${moment(deliveryDate).format('DD-MM-YYYY')} ${timeStart}:00`
    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    try {
      await deliveryApi.updateDeliveryByHost(id, {
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration: deliveryDur,
      })

      await deliveryApi.updateRememberedDeliveryZones(userId, {
        rememberedDeliveryZones,
      })

      dispatch(updateDeliveryInfo(id, {
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration: deliveryDur,
      }))

      addToast('Update success', { appearance: 'success', autoDismiss: true })

      setTimeReRender((prevState) => prevState + 1)
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      return
    }
    setIsSubmitting(false)
    toggleModalAddDelivery()
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryDate(e)
  }

  const onChangeZoneDelivery = (index, type, value) => {
    deliveryZones[index][type] = value
    setDeliveryZones([...deliveryZones])
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const onEditDelivery = async () => {
    const { msgResp } = await deliveryApi.getRememberedDeliveryZones()

    if (msgResp.length > 0) {
      setDeliveryZones(msgResp)
    }
    const deliveryTimeCurrentGMT = convertToCurrentGMT(deliveryTime)
    const timeStart = Number(deliveryTimeCurrentGMT.split(' ')[1].split(':')[0])
    const timeEnd = timeStart + deliveryDuration / 3600
    const deliveryTimeSplit = deliveryTimeCurrentGMT.split(' ')[0].split('-').reverse()
    setDeliveryDate(new Date(deliveryTimeSplit[0], deliveryTimeSplit[1] - 1, deliveryTimeSplit[2]))
    const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
    const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd)
    setDeliveryHourStart(LIST_TIME[indexStart]?.text)
    setDeliveryHourEnd(LIST_TIME[indexEnd]?.text)
    toggleModalAddDelivery()
  }

  return (
    <>
      <ul className='nav-tab'>
        <li onClick={onEditDelivery}>Edit</li>
        <li onClick={toggle}>Broadcast</li>
        {deliveryStatus === DELIVERY_CLOSED ? (
          <li onClick={() => onUpdateStatusDelivery(false)}>Re-open Delivery</li>
        ) : (
          <li onClick={() => onUpdateStatusDelivery(true)}>Close Delivery</li>
        )}
        <li onClick={onExportOrder}>Export Delivery</li>
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalHeader className='modal-header-created' style={{ margin: '0 auto' }}>
            <span className='c-txt-gra'>Message to Broadcast?</span>
          </ModalHeader>
          <ModalBody>
            <div className='c-form'>
              <div className='form-group'>
                <input
                  type='text'
                  value={message}
                  className='form-control form-control--radius'
                  onChange={onChangeMessage}
                ></input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
              <button className='btn btn--solid btn--solid04' onClick={toggle}>
                CANCEL
              </button>
            </div>
            <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
              <button className='btn btn--org btn--org02' onClick={onCreateNotification}>
                OK
              </button>
            </div>
          </ModalFooter>
        </Modal>

        {openModalAddDelivery && <DeliveryOptionsModal
          modal={openModalAddDelivery}
          toggle={toggleModalAddDelivery}
          handleOnClickOk={handleOnClickOk}
          deliveryZones={deliveryZones}
          deliveryTime={deliveryDate}
          onChangeZoneDelivery={onChangeZoneDelivery}
          onChangeTimeDelivery={onChangeTimeDelivery}
          handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
          deliveryHourStart={deliveryHourStart}
          setDeliveryHourStart={setDeliveryHourStart}
          deliveryHourEnd={deliveryHourEnd}
          setDeliveryHourEnd={setDeliveryHourEnd}
          isSubmitting={isSubmitting}
        />}
      </ul>
    </>
  )
}
