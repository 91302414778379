import React from 'react'
import PropTypes from 'prop-types'

const EventSearch = ({ searchString, onChange }) => (
  <div className='c-form c-form--search event-search'>
    <div className='form-group'>
      <i className='icon-search'></i>
      <input
        className='form-control'
        placeholder='Search'
        type='text'
        value={searchString}
        onChange={onChange}
      />
    </div>
  </div>
)

EventSearch.propTypes = {
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
}

export default EventSearch
