import React from 'react'
import PropTypes from 'prop-types'

import InputField from '_components/Input'

const GroupSetting = ({
  groupType,
  defaultRole,
  autoApproval,
  onChangeRadio,
  register,
  accessMemberList,
}) => (
  <React.Fragment>
    <p className='c-txt-13 c-txt-blue'>
      <strong>Group settings</strong>
    </p>
    <div className='box-setting'>
      <div className='c-form'>
        <div className='form-group'>
          <label className='c-txt-gray'>Group type</label>
          <div className='row'>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupVisibility'
                  id='groupVisibilityPrivate'
                  value={groupType}
                  onChange={() => onChangeRadio('groupType', 0)}
                  checked={groupType === 0}
                />
                <label className='custom-control-label' htmlFor='groupVisibilityPrivate'>
                  PRIVATE
                </label>
              </div>
            </div>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupVisibility'
                  id='groupVisibilityPublic'
                  value={groupType}
                  onChange={() => onChangeRadio('groupType', 1)}
                  checked={groupType === 1}
                />
                <label className='custom-control-label' htmlFor='groupVisibilityPublic'>
                  PUBLIC
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <label className='c-txt-gray'>Default user permission</label>
          <div className='row'>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupDefaultUserPermission'
                  id='groupDefaultUserPermissionHost'
                  value={defaultRole}
                  onChange={() => onChangeRadio('defaultRole', 2)}
                  checked={defaultRole === 2}
                />
                <label className='custom-control-label' htmlFor='groupDefaultUserPermissionHost'>
                  HOST
                </label>
              </div>
            </div>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupDefaultUserPermission'
                  id='groupDefaultUserPermissionMember'
                  value={defaultRole}
                  onChange={() => onChangeRadio('defaultRole', 1)}
                  checked={defaultRole === 1}
                />
                <label className='custom-control-label' htmlFor='groupDefaultUserPermissionMember'>
                  MEMBER
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <label className='c-txt-gray'>Group join verification</label>
          <div className='row'>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupJoinVerification'
                  id='groupJoinVerificationAdminApproval'
                  value={autoApproval}
                  onChange={() => onChangeRadio('autoApproval', 0)}
                  checked={autoApproval === 0}
                />
                <label
                  className='custom-control-label'
                  htmlFor='groupJoinVerificationAdminApproval'
                >
                  ADMIN APPROVAL
                </label>
              </div>
            </div>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupJoinVerification'
                  id='groupJoinVerificationAutoApproval'
                  value={autoApproval}
                  onChange={() => onChangeRadio('autoApproval', 1)}
                  checked={autoApproval === 1}
                />
                <label className='custom-control-label' htmlFor='groupJoinVerificationAutoApproval'>
                  AUTO APPROVAL
                </label>
              </div>
            </div>

            {autoApproval === 1 && (
              <div id='passcodeHtmlBlock' className='c-form '>
                <p className='c-txt-12 mb-10'>
                  Passcode (Leave this blank if you dont need verification)
                </p>
                <div className='form-group'>
                  <InputField
                    className='form-control'
                    id='passCode'
                    name='passCode'
                    type='text'
                    register={register}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-group'>
          <label className='c-txt-gray'>A member can see other members</label>
          <div className='row'>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupDefaultAccessMemberList'
                  id='groupDefaultAccessMemberListVi'
                  value={accessMemberList}
                  onChange={() => onChangeRadio('accessMemberList', 1)}
                  checked={accessMemberList === 1}
                />
                <label className='custom-control-label' htmlFor='groupDefaultAccessMemberListVi'>
                  Yes
                </label>
              </div>
            </div>
            <div className='col-6'>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='groupDefaultAccessMemberList'
                  id='groupDefaultAccessMemberListIn'
                  value={accessMemberList}
                  onChange={() => onChangeRadio('accessMemberList', 0)}
                  checked={accessMemberList === 0}
                />
                <label className='custom-control-label' htmlFor='groupDefaultAccessMemberListIn'>
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)

GroupSetting.propTypes = {
  autoApproval: PropTypes.number,
  defaultRole: PropTypes.number,
  groupType: PropTypes.number,
  onChangeRadio: PropTypes.func,
  register: PropTypes.object,
}

export default GroupSetting
