import React, { useEffect, useRef, useState, useContext } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { useHistory } from 'react-router'

import { useSelector, useDispatch } from 'react-redux'
import {
  getEventsCollectionPerMonthByHost,
  getEventsClosePerMonthByHost,
} from '_redux/modules/event'
import moment from 'moment'

import './style.scss'
import ListEventClose from './components/ListEventClose'
import ListEventCollection from './components/ListEventCollection'
import { SelectIdGroupContext } from '../../../context/SelectGroupContext'

function Calendar() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { infoGroupSelected } = useContext(SelectIdGroupContext)

  const [dateData, setDateData] = useState()
  const prevCount = useRef(dateData)
  const [listEvent, setListEvent] = useState([])
  const [totalEvent, setTotalEvent] = useState({})
  const { listEventsCollectionPerMonthByHost, listEventsClosePerMonthByHost } = useSelector(
    (state) => state.event
  )

  const handleChangeMonth = (date) => {
    if (date) {
      let year = new Date().getFullYear()
      let month = new Date().getMonth()
      year = new Date(date.end).getFullYear()
      month = new Date(date.end).getMonth()
      if (month === 0) {
        if (prevCount.current === 1 || prevCount.current === 11) {
          const data = {
            month: 12,
            year: year - 1,
          }
          setDateData(data)
        }
      } else {
        const data = {
          month,
          year,
        }
        setDateData(data)
      }
    }
  }

  const handleDateClick = (arg) => {
    history.push({ pathname: '/event/list-hosted-by-group', search: `?date=${arg.dateStr}` })
  }

  useEffect(() => {
    prevCount.current = dateData?.month
    dispatch(getEventsCollectionPerMonthByHost(dateData, infoGroupSelected?.groupId))
    dispatch(getEventsClosePerMonthByHost(dateData, infoGroupSelected?.groupId))
  }, [dateData, infoGroupSelected])

  useEffect(() => {
    let getEventsClose = []
    if (totalEvent?.listEventsClosePerMonthByHost?.length > 0) {
      getEventsClose = totalEvent?.listEventsClosePerMonthByHost?.map((item) => {
        const isCheckDurationEvent = moment.unix(item?.pickupTimestamp).format('YYYY-MM-DD')
        const currentDate = moment().format('YYYY-MM-DD')
        if (moment(currentDate).isAfter(isCheckDurationEvent)) {
          return {
            date: moment.unix(item?.closingTimestamp).format('YYYY-MM-DD'),
            color: '#81858a',
          }
        }
          return {
            date: moment.unix(item?.closingTimestamp).format('YYYY-MM-DD'),
            color: '#FF6903',
          }
      })
    }
    let getEventsCollection = []
    if (totalEvent?.listEventsCollectionPerMonthByHost?.length > 0) {
      getEventsCollection = totalEvent?.listEventsCollectionPerMonthByHost?.map((item) => {
        const isCheckDurationEvent = moment.unix(item?.pickupTimestamp).format('YYYY-MM-DD')
        const currentDate = moment().format('YYYY-MM-DD')
        if (moment(currentDate).isAfter(isCheckDurationEvent)) {
          return {
            date: moment.unix(item?.pickupTimestamp).format('YYYY-MM-DD'),
            color: '#81858a',
          }
        }
          return {
            date: moment.unix(item?.pickupTimestamp).format('YYYY-MM-DD'),
            color: '#68A4C4',
          }
      })
    }

    const uniqueIDateEventClose = []
    const uniqueEventClose = getEventsClose.filter((element) => {
      const isDuplicate = uniqueIDateEventClose.includes(element.date)
      if (!isDuplicate) {
        uniqueIDateEventClose.push(element.date)
        return true
      }
      return false
    })

    const uniqueIDateEventCollection = []
    const uniqueEventCollection = getEventsCollection.filter((element) => {
      const isDuplicate = uniqueIDateEventCollection.includes(element.date)
      if (!isDuplicate) {
        uniqueIDateEventCollection.push(element.date)
        return true
      }
      return false
    })
    const evenClosetInCalendar = uniqueEventClose?.concat(uniqueEventCollection)

    setListEvent(evenClosetInCalendar)
  }, [totalEvent])

  useEffect(() => {
    const events = {
      listEventsClosePerMonthByHost,
      listEventsCollectionPerMonthByHost,
    }
    setTotalEvent(events)
  }, [listEventsClosePerMonthByHost, listEventsCollectionPerMonthByHost])

  return (
    <>
      <div className='resize-calendar border d-flex flex-column'>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          themeSystem='standard'
          headerToolbar={{
            start: 'prev',
            center: 'title',
            end: 'next',
          }}
          datesSet={(dateInfo) => handleChangeMonth(dateInfo)}
          initialView='dayGridMonth'
          events={listEvent}
          dateClick={handleDateClick}
        />
        <div className='calendar__desc-point ml-auto w-fit'>
          <div style={{ color: '#FF6903' }}>
            <span className='calendar__desc-point-close'></span> Events Close
          </div>
          <div style={{ color: '#68A4C4' }}>
            <span className='calendar__desc-point-collection'></span> Collection
          </div>
        </div>
      </div>
      <div className='container-fluid recommend-event-in-feature '>
        <ListEventClose listEventClose={totalEvent?.listEventsClosePerMonthByHost} />
        <ListEventCollection listEventCollection={totalEvent?.listEventsCollectionPerMonthByHost} />
      </div>
    </>
  )
}

export default Calendar
