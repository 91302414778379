/* eslint-disable multiline-ternary */
import authApi from '_api/auth'
import SocialButton from '_components/SocialButton'
import { FACEBOOK, GOOGLE } from '_utils/constant'
import { setUserToken } from '_utils/localData'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FormGroup, Label, Spinner } from 'reactstrap'

import imgTop from '_images/img_top.svg'
import logoLeft from '_images/logo_left.svg'

import './style.scss'

const LoginError = () => (
  <div className='main-content'>
    <div className='container'>
      <h2 className='c-ttl'>Response</h2>
      {`${''}Can't authorize, please`} <Link to='/sign-up'>sign up!</Link>
    </div>
  </div>
)

const LoginPage = () => {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState('')
  const [isAuthError, setIsAuthError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)

  const validateForm = () => {
    if (!email) {
      setErrorMessage('Invalid email')
      return false
    }
    if (!password) {
      setErrorMessage('Invalid password')
      return false
    }
    return true
  }

  const onSubmit = () => {
    setIsProcessing(true)
    if (!validateForm()) {
      setIsProcessing(false)
      return
    }
    const data = { email: email.toLowerCase().trim(), password: password.toLowerCase().trim() }
    authApi
      .login(data)
      .then((res) => {
        setUserToken(res.msgResp.token)
        setIsProcessing(false)
        history.push('/welcome')
      })
      .catch((err) => {
        setIsProcessing(false)
        if (Object.keys(err?.msgResp)?.length === 1) {
          setErrorMessage(`Left retries ${err.msgResp.leftRetries}`)
        } else {
          setErrorMessage(err.msgResp)
        }
      })
  }

  const onChangeEmail = (e) => {
    if (errorMessage) setErrorMessage('')
    setEmail(e.target.value.trim())
  }

  const onChangePassword = (e) => {
    if (errorMessage) setErrorMessage('')
    setPassword(e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <div className='p-login min-vh-100'>
      <div className='text-info-app'>
        <small>
          <span>GroupBuy:</span> <span className='info-number'>174</span>
        </small>
        <small>
          <span>Users:</span> <span className='info-number'>7208</span>
        </small>
      </div>
      <div className='page-login page-login--screen'>
        <div className='main-top align-self-start'>
          <div className='container'>
            <div className='main-logo text-left'>
              <img src={logoLeft} />
            </div>
            <div className='main-img text-center'>
              <img src={imgTop} />
              <div className='main-link'>
                <a
                  href='https://sites.google.com/co-bee.com/home/home'
                  target='_blank'
                  rel='noreferrer'
                >
                  To find out more about Co-bee, <b>click here!</b>
                </a>
              </div>
            </div>
          </div>
        </div>
        {isAuthError ? (
          <LoginError />
        ) : (
          <div className='main-content'>
            <div className='container'>
              <h2 className='c-ttl'>
                Connect Me <br />
                To The Community!
              </h2>
              <div className='c-form c-form--gray c-form--login'>
                <div className='form-group'>
                  <FormGroup>
                    <Label for='email'>Email address</Label>
                    <input
                      className='form-control form-control--nobg'
                      id='email'
                      type='text'
                      value={email}
                      onChange={onChangeEmail}
                      onKeyDown={onKeyDown}
                    />
                  </FormGroup>
                </div>
                <div className='form-group'>
                  <FormGroup>
                    <Label for='password'>PIN</Label>
                    <input
                      className='form-control form-control--nobg'
                      id='password'
                      type='password'
                      value={password}
                      onChange={onChangePassword}
                      onKeyDown={onKeyDown}
                    />
                  </FormGroup>
                  <Link className='c-link-text' to='/reset-password'>
                    Forgot?
                  </Link>
                </div>
              </div>
              {errorMessage && <small className='errorMessage'>{errorMessage}</small>}
              <button className='btn btn--main' onClick={onSubmit} disabled={isProcessing}>
                {isProcessing ? <Spinner color='light' size='sm' /> : 'LOGIN'}
              </button>

              <div className='line-text'>
                <span>OR</span>
              </div>
            </div>
            <div className='main-login-bot'>
              <div className='main-button container'>
                <div className='text-center'>Connect with</div>
                <div className='container'>
                  <div className='row'>
                    <div className='col-6'>
                      <SocialButton setIsAuthError={setIsAuthError} isLogin social={FACEBOOK} />
                    </div>
                    <div className='col-6'>
                      <SocialButton setIsAuthError={setIsAuthError} isLogin social={GOOGLE} />
                    </div>
                  </div>
                  <div className='c-ttl-blue text-center'>
                    Not a member? <Link to='/sign-up'>Sign up Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginPage
