import userApi from '_api/user'
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'
import deliveryApi from '_api/delivery'

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
const GET_ADDRESS_DELIVERY_BOOKER = 'GET_ADDRESS_DELIVERY_BOOKER'

const initialState = {
  [DEFAULT_KEY]: null,
  notifications: [],
  addressDeliveryBooking: null,
  user: {},
}

export const getNotifications = async (dispatch, getState) => {
  // const isCacheValid = checkCacheValid(getState, 'notification')
  // if (isCacheValid) {
  //   return null
  // }
  const { user } = getState()
  const { notifications } = user
  if (notifications && notifications.length > 0) return
  try {
    const { msgResp: listNotification } = await userApi.getNotifications()
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: { notifications: listNotification },
    })
  } catch (error) { }
}

export const getRememberedDeliveryAddress = () => async (dispatch, getState) => {
  const { user } = getState()
  const { addressDeliveryBooking } = user
  if (addressDeliveryBooking) return
  try {
    const { msgResp } = await deliveryApi.getRememberedDeliveryAddress()
    dispatch({
      type: GET_ADDRESS_DELIVERY_BOOKER,
      payload: { addressDeliveryBooking: msgResp },
    })
  } catch (error) { }
}

export const updateRememberedDeliveryAddress = (address) => async (dispatch) => {
  dispatch({
    type: GET_ADDRESS_DELIVERY_BOOKER,
    payload: { addressDeliveryBooking: address },
  })
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload,
      }
    }
    case GET_ADDRESS_DELIVERY_BOOKER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default user
