import { combineReducers } from 'redux'
import dashBoardReducer from './modules/dashboard'
import deliveryReducer from './modules/delivery'
import eventReducer from './modules/event'
import groupReducer from './modules/group'
import orderReducer from './modules/order'
import userReducer from './modules/user'

const reducers = combineReducers({
  group: groupReducer,
  event: eventReducer,
  order: orderReducer,
  user: userReducer,
  delivery: deliveryReducer,
  dashBoard: dashBoardReducer,
})

export default (state, action) => reducers(state, action)
