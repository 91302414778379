/* eslint-disable array-callback-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import { getAllEventsOpening } from '_redux/modules/event'
import { EVENT_STATUS_OPEN } from '_utils/constant'
import { sortListEvents } from '_utils/function'
import EventItem from '../components/EventItem'
import EventSearch from '../components/EventSearch'

import './style.scss'
// import Welcome from '../components/Welcome'

const EventList = () => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const [searchString, setSearchString] = useState('')
  const { allEvents, loadingAllEventsOpening } = useSelector((state) => state.event)
  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  useEffect(() => {
    dispatch(getAllEventsOpening)
  }, [])

  return (
    <div className='row-top event-page'>
      <EventSearch searchString={searchString} onChange={onChangeSearch} />
      {!loadingAllEventsOpening && !allEvents?.length ? (
        <div className='text-center mt-3'>
          <p>Not Found Events Are Opening</p>
        </div>
      ) : (
        <div className='group-list group-list--event group-list--event02'>
          {loadingAllEventsOpening && <div>Loading...</div>}
          {sortListEvents(allEvents)
            ?.filter((item) => item.closingTimestamp > moment().unix())
            ?.filter((item) => item.status === EVENT_STATUS_OPEN)
            ?.filter((val) => {
              if (searchString === '') {
                return val
              }
              if (val.title.toLowerCase().includes(searchString.toLowerCase())) {
                return val
              }
            })
            .map((event, index) => (
              <EventItem event={event} key={index} />
            ))}
        </div>
      )}
    </div>
  )
}

export default EventList
